import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const ProgressBar = ({events}:any) => {
    return ( <div className="w-full h-full flex justify-center items-center ">
           <Box sx={{ width: '100%' }}>
           <BorderLinearProgress sx={{height:'13px'}} variant="determinate"

            value={events>100?events=100:events}
            />
    </Box>
    </div> );
}
 
export default ProgressBar;