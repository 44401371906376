import * as React from "react";
import Modal from "@mui/material/Modal";
import { BallTriangle } from "react-loader-spinner";

export default function ScreenLoader() {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex h-screen w-full margin-auto items-center justify-center">
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="white"
            ariaLabel="ball-triangle-loading"
            visible={true}
          />
        </div>
      </Modal>
    </div>
  );
}
