import { useEffect, useState } from "react";
import MainLayout from "../../components/Layout/MainLayout";
import NoDataImage from "../../components/Layout/content/NoData";
// import Search from "../../components/search/search";
import moment from "moment";
import { Category } from "../../types/categoryTypes";
import Api from "../../services/api";
import CategoryCard from "../../components/category/CategoryCard";
import AddCategoryModal from "../../components/category/AddCategoryModal";
import { useAdCategory } from "../../hooks/useAdCategory";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import ActionButton from "../../components/button/ActionButton";
import * as colors from "../.././constants/colors/colors";

interface CategoriesResponse {
  status: string;
  data: {
    categories: Category[];
  };
}

const AddCategory = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenModal = (category: Category | null) => {
    setSelectedCategory(category);
    setOpenModal(true);
  };

  const mutation = useAdCategory();
  // const deleteCategoryById = useDeleteCategory();
  const queryClient = useQueryClient();

  const deleteCategory = async (categoryId: string) => {
    try {
      await Api.delete(`/categories/${categoryId}`);
      queryClient.invalidateQueries(["categories"]);
      enqueueSnackbar("Category deleted successfully", { variant: "error" });
      fetchCategories();
    } catch (error) {
      enqueueSnackbar("Failed to delete category", { variant: "error" });
    }
  };

  const editCategory = async (category: Category) => {
    try {
      await Api.patch(`/categories/${category?._id}`, category);
      queryClient.invalidateQueries(["categories"]);
      enqueueSnackbar("Category edited successfully", { variant: "success" });
      fetchCategories();
      setOpenModal(false);
    } catch (error) {
      enqueueSnackbar("Failed to edit category", { variant: "error" });
    }
  };

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
    deleteCategory(category._id);
  };

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModalForAdd = () => {
    setSelectedCategory(null);
    setOpenModal(true);
  };

  const fetchCategories = async () => {
    const response = await Api.get<CategoriesResponse>("/categories");
    setCategories(response?.data?.data?.categories);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddCategory = (name: string): Promise<boolean> => {
    return new Promise((resolve) => {
      if (selectedCategory) {
        editCategory({ ...selectedCategory, name });
      } else {
        mutation.mutate(name, {
          onSuccess: () => {
            queryClient.invalidateQueries(["categories"]);
            enqueueSnackbar("Category added successfully", {
              variant: "success",
            });
            fetchCategories();
          },
          onError: () => {
            enqueueSnackbar("Failed to add category", { variant: "error" });
          },
        });
      }
    });
  };

  useEffect(() => {
    if (mutation.isSuccess) {
      queryClient.invalidateQueries(["categories"]);
      enqueueSnackbar("Category added successfully", { variant: "success" });
      fetchCategories();
      handleCloseModal();
    }
  }, [mutation.isSuccess]);

  const Title = {
    Name: "Name",
    createdAt: "Date Created",
    isActive: "Status",
  };

  return (
    <MainLayout>
      <div className="w-full h-[92vh] parent">
        <div className="w-full  mt-4 h-[4rem] flex justify-between">
          {/* <Search /> */}
          <div></div>
          <ActionButton
            label={"Add Category"}
            backgroundColor={colors.searchButtonBlue}
            onClick={handleOpenModalForAdd}
          />

          <AddCategoryModal
            open={openModal}
            category={selectedCategory}
            onAddCategory={handleAddCategory}
            onClose={handleCloseModal}
            onOpen={handleOpenModal}
          />
        </div>

        <div className="w-[100%] h-[85vh]  pt-4 parent ">
          <div className="w-full h-[55px] text-[#FFFFFF] bg-[#3E7BFA] flex justify-between pl-6">
            <div className=" w-[21%] h-full flex overflow-hidden text-ellipsis  items-center">
              {Title.Name}
            </div>
            <div className="w-[25%]  h-full  flex pl-3 items-center">
              {Title.createdAt}
            </div>
            <div className=" w-[27%] h-full  flex pl-3 items-center">
              {Title.isActive}
            </div>
          </div>

          {categories.length > 0 ? (
            categories.map((category) => (
              <div
                key={category?._id}
                className="w-full flex h-[60px]  hover:bg-[#E5F0FF] text-[#555770] bg-[#FFFFFF] mt-[0.125rem] px-4  pl-3 gap-2">
                <div className="w-[35%] h-full flex pl-3 items-center">
                  {category?.name}
                </div>
                <div className=" w-[35%] h-full  flex pl-3 items-center">
                  {moment(category?.createdAt).format("LL")}
                </div>

                <div className="w-[20%] h-full flex pl-4 justify-between items-center">
                  <p
                    className={`w-[5.5rem] h-[2rem] flex items-center justify-center font-['Inter'] rounded-full text-[#555770] text-[15px] font-normal ${
                      category.isActive
                        ? "bg-[#E3FFF1] border border-[#06C270]"
                        : "bg-[#FFE5E5] border-[#FF3B3B]"
                    }`}>
                    {category?.isActive ? "  Active" : "InActive"}
                  </p>
                </div>
                <div className="h-full  mr-2 flex items-center ">
                  <CategoryCard
                    Edit="Edit"
                    Delete="Delete"
                    categoryId={category?._id}
                    onClickEdit={() => handleOpenModal(category)}
                    onClickDelete={(categoryId: string) =>
                      deleteCategory(categoryId)
                    }
                  />
                </div>
              </div>
            ))
          ) : (
            <NoDataImage
              message={
                "No categories found. Click the button to add a category."
              }
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default AddCategory;
