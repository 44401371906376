import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Category } from "../../types/categoryTypes";

interface AddCategoryModalProps {
  open: boolean;
  category: Category | null;
  onAddCategory: (name: string) => Promise<boolean>;
  onClose: () => void;
  onOpen: (category: Category | null) => void;
}



const AddCategoryModal = ({
  open,
  category,
  onAddCategory,
  onClose,
  onOpen,
}: AddCategoryModalProps) => {
  const [name, setName] = useState(category ? category.name : "");

  const handleAdd = async () => {
    const isSuccess = await onAddCategory(name);
    if (isSuccess) {
      setName("");
      onClose();
    }
  };

  useEffect(() => {
    setName(category ? category.name : "");
  }, [open, category]);
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <div className="flex items-center justify-between pr-2">
          <DialogTitle sx={{ width: "400px" }}>Add Category</DialogTitle>
          <CloseIcon onClick={onClose} className="cursor-pointer" />
        </div>
        <DialogContent sx={{ width: "400px" }}>
          <div className="flex flex-col">
            <label htmlFor="category-name" className="text-[#555770]">
              Name
            </label>
            <TextField
              className="login-input"
              margin="normal"
              required
              fullWidth
              id="category-name"
              name="category-name"
              autoFocus
              type="text"
              placeholder="Category name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                border: "1px solid #C7C9D9",
                borderRadius: "8px",
                color: "white",
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            sx={{
              backgroundColor: "white",
              border: "1px solid #C7C9D9",
              color: "#555770",
              borderRadius: "8px",
              textTransform: "none",
              ":hover": {
                backgroundColor: "white",
                color: "#555770",
              },
            }}>
            Cancel
          </Button>
          <Button
            disabled={!name}
            onClick={handleAdd}
            sx={{
              backgroundColor: "#3E7BFA",
              color: "#fff",
              borderRadius: "8px",
              textTransform: "none",
              ":hover": {
                backgroundColor: "#3E7BFA",
                // color: "#",
              },
            }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddCategoryModal;
