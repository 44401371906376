import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ALL_EVENT_ORGANIZERS,
  CREATE_EVENT_ORGANIZERS,
  DELETE_EVENT_ORGANIZERS,
  UPDATE_EVENT_ORGANIZERS,
  DELETE_ORGANIZERS,
  SEARCH_ADMIN_ORGANIZERS
} from "./actions";
import {
  fetchCreateUpdateDeleteGetEvent,
  allOrganizers,
  getAllMember,
  searchAdminOrganizers
} from "../../endpoints";
import Api from "../../api";
import * as auth from "../../auth";

export const fetchAllEEventOrganizers: any = createAsyncThunk(
  GET_ALL_EVENT_ORGANIZERS,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url:
          auth.getUserRole() === "admin"
            ? allOrganizers + "/"
            : getAllMember,
      });

      
      return response?.data?.data?.organizers;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const createEventOrganizers: any = createAsyncThunk(
  CREATE_EVENT_ORGANIZERS,
  async (data) => {
    try {
      const response = await Api({
        data,
        method: "POST",
        url: fetchCreateUpdateDeleteGetEvent + "/",
      });
      await fetchAllEEventOrganizers();
      return response?.status;
    } catch (error) {
      console.dir(error);
    }
  }
);
export const updateEventOrganizers: any = createAsyncThunk(
  UPDATE_EVENT_ORGANIZERS,
  async (data: any) => {
    try {
      const response = await Api({
        data,
        method: "PATCH",
        url: fetchCreateUpdateDeleteGetEvent + "/" + data._id,
      });
      await fetchAllEEventOrganizers();
      return response?.status;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const deletingEventOrganizers: any = createAsyncThunk(
  DELETE_EVENT_ORGANIZERS,
  async (id) => {
    try {
      const response = await Api({
        method: "DELETE",
        url: fetchCreateUpdateDeleteGetEvent + "/" + id,
      });
      await fetchAllEEventOrganizers();
      return response?.status;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const deleteOrganizerItem: any = createAsyncThunk(
  DELETE_ORGANIZERS,
  async (id) => {

    try {
      const response = await Api({
        method: "DELETE",
        url:allOrganizers + `/${id}`,
         
        headers: {
          "Content-Type": "application/json",
        },
      })
      

      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);

// export const searchOrganizerAdmin: any = createAsyncThunk(
//   SEARCH_ADMIN_ORGANIZERS,

//   async (value) => {
//     console.log('value',value)
//     try {
//       const response = await Api({
//         method: "GET",
//         url:searchAdminOrganizers+`=${value}`,
         
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//       console.log('value',response?.data)
//       return response?.data?.data;
//     } catch (error) {
//       console.dir(error);
//     }
//   }
// );