import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as categories from "./index";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    data: [],
    loaded: false,
    status: null || "",
    message: null,
    LoginHidden: false,
    error: null,
    successDeleteStatus: 0,
  },
  reducers: {
    getAllCategory(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
  },
  extraReducers: {
    //TOOLKIT FOR FETCHING CATEGORIES
    [categories.fetchCategory.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [categories.fetchCategory.fulfilled]: (state, action) => {
      state.data = action.payload.categories;
    
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [categories.fetchCategory.rejected]: (state, action) => {
      state.data = [];
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    //TOOLKIT FOR CREATING EVENT
    [categories.createCategory.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [categories.createCategory.fulfilled]: (state, action) => {
      state.data = action.payload.events;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [categories.createCategory.rejected]: (state, action) => {
      state.data = [];
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    //TOOLKIT FOR DELETING EVENT
    [categories.deletingCategory.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [categories.deletingCategory.fulfilled]: (state, action) => {
      state.successDeleteStatus = action.payload;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [categories.deletingCategory.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },
  },
});

export const { getAllCategory } = categorySlice.actions;

export default categorySlice.reducer;
