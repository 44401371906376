import { createSlice } from "@reduxjs/toolkit";
import * as events from "./index";
import * as auth from "../../auth";
const adminVerificationSlice = createSlice({
  name: "adminVerifications",
  initialState: {
    allOrganizations: [],
    allVerifiedOrganizations: [],
    allUnVerifiedOrganizations: [],
    allOrganizationEvent: [],
    allAdminOrganizationEvent: [],
    adminSearch: [],
    adminSearchOrganization: [],
    allOrganizersEvent: [],
    loaded: false,
    status: null || "",
    message: null,
    LoginHidden: false,
    error: null,
    successDeleteStatus: 0,
    viewOrganizersList: false,
    HideOrganizersList: true,
  },
  reducers: {
    handleViewOrganizersList: (state) => {
      state.viewOrganizersList = true;
      state.HideOrganizersList = false;
    },
    handleHideOrganizersList: (state) => {
      state.viewOrganizersList = false;
      state.HideOrganizersList = true;
    },
  },
  extraReducers: {
    //TOOLKIT FOR FETCHING ALL ORGANIZATIONS
    [events.fetchAllOrganizations.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchAllOrganizations.fulfilled]: (state, action) => {
      const findAllUnverifiedOrganizations = action.payload.filter(
        (item: any) => item.verificationStatus === "unverified"
      );
      const findAllVerifiedOrganizations = action.payload.filter(
        (item: any) => item.verificationStatus === "verified"
      );
      state.allVerifiedOrganizations = findAllVerifiedOrganizations;
      state.allUnVerifiedOrganizations = findAllUnverifiedOrganizations;
      state.allOrganizations = action.payload;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.fetchAllOrganizations.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    //TOOLKIT FOR FETCHING ALL ORGANIZATION EVENT

    [events.fetchAllOrganizationEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchAllOrganizationEvent.fulfilled]: (state, action) => {
      state.allOrganizationEvent = action.payload;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.fetchAllOrganizationEvent.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    [events.fetchAllOrganizerEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchAllOrganizerEvent.fulfilled]: (state, action) => {
      state.allOrganizersEvent = action.payload.events;

      state.loaded = true;
      state.status = "Loaded successfully";
    },

    [events.fetchAllOrganizerEvent.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    //TOOLKIT FOR FETCHING ALL ADMIN ORGANIZATION EVENT
    [events.fetchAllAdminOrganizationEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchAllAdminOrganizationEvent.fulfilled]: (state, action) => {
      state.allAdminOrganizationEvent = action.payload;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.fetchAllAdminOrganizationEvent.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

  

    [events.searchOrganizationAdmin.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.searchOrganizationAdmin.fulfilled]: (state, action) => {
      // let payloaddata = action.payload.organizations;
      let payloaddata =auth.getUserRole() === "admin"?action.payload.organizations: action.payload.members;
      
      let loadedData: any = [];
      for (const key in payloaddata) {
        if (auth.getUserRole() === "admin") {
          loadedData.push({
            id: payloaddata[key]._id,
            email: payloaddata[key].email,
            organization: payloaddata[key].name,
            location: payloaddata[key].location,
            name: payloaddata[key].contactName,
            description: payloaddata[key].description,
            image: payloaddata[key].image,
            phone: payloaddata[key].phone,
            role: payloaddata[key].role,
          });

        }else{
          loadedData.push({
            id: payloaddata[key]._id,
            email: payloaddata[key].email,
            image: payloaddata[key].image,
            location: payloaddata[key].location,
            name: payloaddata[key].name,
            role: payloaddata[key].role,
          });
        }
        
      }
      state.adminSearchOrganization = loadedData;
     
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.searchOrganizationAdmin.rejected]: (state, action) => {
      state.adminSearchOrganization = [];
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },


    // [events.SearchAllOrganizerEvent.pending]: (state) => {
    //   state.status = "Loading";
    //   state.loaded = false;
    // },
    // [events.SearchAllOrganizerEvent.fulfilled]: (state, action) => {
    //   state.adminSearch = action.payload;
    //   state.loaded = true;
    //   state.status = "Loaded successfully";
    // },
    // [events.SearchAllOrganizerEvent.rejected]: (state, action) => {
    //   state.loaded = false;
    //   state.status = "Failed";
    //   state.error = action;
    // },
  },
});
export const { handleViewOrganizersList, handleHideOrganizersList } =
  adminVerificationSlice.actions;
export default adminVerificationSlice.reducer;
