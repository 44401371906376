import { IconLabelProps } from "../../types/components/eventCard";

function IconLabel(props: IconLabelProps) {
  return (
    <div className="flex items-center">
      <div className="text-searchButtonBlue">{props.icon}</div>
      <div className="text-DarkGray1 mx-2 font-medium">{props.label}</div>
    </div>
  );
}

export default IconLabel;
