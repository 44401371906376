import { useMutation } from "@tanstack/react-query";
import Api from "../services/api";

interface ForgotPasswordData {
  email: string;
}

export const useForgotPassword = () => {
  return useMutation((email: ForgotPasswordData) =>
    Api.post("/organizers/forgot-password", email)
  );
};
