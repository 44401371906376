import * as endpoint from "../../../services/endpoints";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Api from "../../../services/api";
import { MoonLoader } from "react-spinners";
import IconButton from "@mui/material/IconButton";
import UpdateImage from "../../images/UpdateImage";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { OrganizersAction } from "../../../services/redux/Organizer/organizerSlice";
import { useDispatch } from "react-redux";
import { fetchOrganizer } from "../../../services/redux/Organizer/index";
import { fetchOrganizer1 } from "../../../services/redux/Organizer/index";
import { fetchAllEEventOrganizers } from "../../../services/redux/eventOrganizers/index";
import * as auth from "../../../services/auth";
const EditMembersProfile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const { id } = useSelector(
    (state: any) => state.eventOrganizers
  );
  const data = useSelector((state: any) => state.organizer.data);
  const updateItem = data.find((item:any)=>item.id === id)
  const [loading, setLoading] = useState(false);
 
  
  const onCloseHandler = () => {
    dispatch(OrganizersAction.handleClose());
 {   auth.getUserRole()==='admin'?
    dispatch(fetchAllEEventOrganizers()):dispatch(fetchOrganizer());}
  };

  useEffect(() => {
    {auth.getUserRole()==='admin'?
    dispatch(fetchAllEEventOrganizers()):dispatch(fetchOrganizer());}
    // dispatch(fetchOrganizer());
  }, []);

  const Avatar = "/image/user.png";
  const formik = useFormik({
    initialValues: {
      name: updateItem.name,
      email: updateItem.email,
      location: updateItem.location,
      image: updateItem.image,
      id: updateItem.id,
    },

    onSubmit: async (values, actions) => {
      setLoading(true);

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("location", values.location);
      formData.append("image", values.image==''?Avatar:values.image);
      try {
        const res = await Api({
          method: "PATCH",
          url: endpoint.addMember + `/${values.id}`,
          headers: {
            "content-type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
          data: formData,
        });
        {auth.getUserRole()==='admin'?
        dispatch(fetchAllEEventOrganizers()):dispatch(fetchOrganizer());}
        if (res.data) {
          onCloseHandler();
          setLoading(false);
          enqueueSnackbar(`${updateItem.name} Updated successfully`, { variant: "success" });
          dispatch(fetchOrganizer());
          setTimeout(() => {
           dispatch(fetchOrganizer());
            window.location.reload()
          }, 2000);
          formik.resetForm();
        } else {
        }
      } catch (error) {
        setLoading(false);
        console.dir(error);
      }
    },
  });
  return (
 
    <form
      className="w-full flex flex-col p-5 justify-center items-center  md:h-full"
      onSubmit={formik.handleSubmit}
    >
      <div className="w-full flex justify-between h-[8%] font-bold text-[19px] ">
        Edit Organizer
        <CloseIcon onClick={onCloseHandler} />
      </div>
      <div className="w-full h-[95%] ">
        <div className="w-full flex flex-col justify-around items-center h-[50%] ">
          <div className="w-[10rem] h-[10rem]  object-cover object-top">
            <UpdateImage
              File={formik?.values.image}
              Image={updateItem.image}
              className="h-[100%] w-full rounded-full object-top  object-cover"
              MainStyle='w-full h-full'
            />
          </div>
          <IconButton
            sx={{
              color: "grey",
              display: "flex",
              justifyContent: "center",
            }}
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              name="image"
              accept="image/*"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!e.target.files) return;
                formik.setFieldValue("image", e.target?.files[0]);
              }}
            />
            <div
              className="w-[10rem] h-[2.5rem] rounded-md
               text-[19px] flex justify-center
               items-center border border-darkGrey3 text-black"
            >
              Change photo
            </div>
          </IconButton>
        </div>
        <div className="w-full flex flex-col justify-around items-center h-[40%] ">
          <div className="w-[80%] h-[50%] flex flex-col justify-center ">
            <label htmlFor="Name">Contact Name</label>

            <input
              type="text"
              required
              placeholder="Contact Name"
              id="name"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue("name", e.target?.value);
              }}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              className={`${`w-[95%] p-3 rounded-lg outline-none border border-darkGrey3 md:h-[55%] `}`}
            ></input>
          </div>
          <div className="w-[80%] h-[50%] flex flex-col  justify-center ">
            <label htmlFor="email">Email</label>

            <input
              type="email"
              required
              placeholder="Contact email"
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue("email", e.target?.value);
              }}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              className={`${`w-[95%] p-3 rounded-lg outline-none border border-darkGrey3  md:h-[55%] `}`}
            ></input>
          </div>
        </div>
        <div className="w-[85%] h-[10%] flex justify-end items-end ">
          <button
            onClick={onCloseHandler}
            className="w-[25%] rounded-lg h-[74%] border mr-4 border-orange font-bold"
          >
            Cancel
          </button>
          {loading ? (
            <MoonLoader color="blue" size={25} />
          ) : (
            <>
              <button
                type="submit"
                className="w-[25%] rounded-lg h-[74%] text-white hover:bg-primary bg-blue font-bold"
              >
                Save
              </button>
            </>
          )}
        </div>
      </div>
    </form>

 
  );
};

export default EditMembersProfile;
