import React from "react"
import { createContext } from "react";
import Cookies from "js-cookie";
import SignUp from "./pages/authentication/SignUp";
import Event from "./pages/event/Event";

import EventDetails from "./pages/event/EventDetails";
import EventDetailsPage from "./pages/event/eventDetailsPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as routes from "./constants/routes/routes";
import { isLoggedIn } from "./services/auth";
import Dashboard from "./pages/admin/Dashboard";
import Organizers from "./pages/Organizers/MainOrganizationPage";
import OrganizationList from "./pages/Organizers/OrganizersList";
import UnverifiedOrganizationList from "./pages/Organizers/UnverifiedOrganizationList";
import OrganizersForm from "./components/organization/OrganizersForm/OrganizerForm";
import OrganizationDetail from "./pages/Organizers/OrganizationDetail";
// import OrganizersDetail from "./pages/Organizers/OrganizersDetail";
import OrganizersDetail from "./pages/Organizers/OrganizersDetail";
import Login from "./pages/authentication/Login";
import ChangePassword from "./pages/authentication/ChangePassword";
import EditOrganizersProfile from "./components/organization/OrganizersForm/EditOrganizationProfile";
import LandingPage from "./pages/landingpage/LandingPage";
import AwaitApproval from "./pages/awaitapproval/AwaitApproval";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import ResetPassword from "./pages/authentication/ResetPassword";
import ContactUs from "./pages/contact/ContactUs";
import TermsOfUse from "./pages/TermsOfUse/TermOfUse";
import Profile from "./pages/profile/Profile";
import AddCategory from "./pages/admin/AddCategory";
function App() {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <Routes>
          <Route
            path={routes.signUp}
            element={
              <CheckLogin>
                <SignUp />
              </CheckLogin>
            }></Route>
          <Route
            path={routes.signIn}
            element={
              <CheckLogin>
                <Login />
              </CheckLogin>
            }></Route>

          <Route
            path={routes.forgotPassword}
            element={
              <CheckLogin>
                <ForgotPassword />
              </CheckLogin>
            }></Route>

          <Route
            path={routes.resetPassword}
            element={
              <CheckLogin>
                <ResetPassword />
              </CheckLogin>
            }></Route>
          <Route
            path={routes.changePassword}
            element={<ChangePassword />}></Route>
          <Route
            path={routes.home}
            element={
              <PrivateRoute>
                <CheckAdminLogin>
                  <Dashboard />
                </CheckAdminLogin>
              </PrivateRoute>
            }></Route>

          <Route
            path={routes.addCategory}
            element={
              <PrivateRoute>
                <CheckAdminLogin>
                  <AddCategory />
                </CheckAdminLogin>
              </PrivateRoute>
            }></Route>
          <Route
            path={routes.events}
            element={
              <PrivateRoute>
                <Event />
              </PrivateRoute>
            }></Route>
          <Route
            path={routes.eventsDetails}
            element={
              <PrivateRoute>
                <EventDetails />
              </PrivateRoute>
            }></Route>
          <Route
            path={routes.organization}
            element={
              <PrivateRoute>
                <Organizers />
              </PrivateRoute>
            }></Route>
          <Route
            path={routes.organizers}
            element={
              <PrivateRoute>
                <OrganizationList />
              </PrivateRoute>
            }></Route>
          <Route
            path={routes.profile}
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }></Route>

          <Route
            path={routes.unverifiedOrganizations}
            element={
              <PrivateRoute>
                <UnverifiedOrganizationList />
              </PrivateRoute>
            }></Route>

          <Route
            path={routes.organizersForm}
            element={
              <PrivateRoute>
                <OrganizersForm />
              </PrivateRoute>
            }></Route>
          <Route
            path={routes.organizersDetail}
            element={
              <PrivateRoute>
                <OrganizationDetail />
              </PrivateRoute>
            }></Route>
          <Route
            path={routes.editOrganizersProfile}
            element={
              <PrivateRoute>
                <EditOrganizersProfile />
              </PrivateRoute>
            }></Route>
          <Route
            path={routes.organizationDetail}
            element={
              <PrivateRoute>
                <OrganizersDetail />
              </PrivateRoute>
            }></Route>
          <Route path={routes.landingPage} element={<LandingPage />}></Route>
          <Route path={routes.contactUs} element={<ContactUs />}></Route>
          <Route path={routes.terms} element={<TermsOfUse />}></Route>

          <Route path={routes.awaiting} element={<AwaitApproval />}></Route>
        </Routes>
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default App;

const authenticatedUser = Cookies.get("currentEventUser");
const userRole = Cookies.get("eventUerRole");
const auth: boolean = isLoggedIn();
const authContext = createContext(Cookies.get("currentEventUser"));

function PrivateRoute({ children }: { children: any }) {
  return authenticatedUser ? children : <Navigate to={routes.signIn} />;
}

function CheckLogin({ children }: { children: any }) {
  const defaultData = Cookies.get("currentEventUser");
  return !defaultData ? children : <Navigate to={routes.home} />;
}

function CheckAdminLogin({ children }: { children: any }) {
  return userRole === "admin" ? children : <Navigate to={routes.events} />;
}

function ProvideAuth({ children }: { children: any }) {
  return (
    <authContext.Provider value={auth.toString()}>
      {children}
    </authContext.Provider>
  );
}
