import { useMutation } from "@tanstack/react-query";
import Api from "../services/api";

interface ResetPasswordData {
  password: string;
  confirmPassword: string;
}

export const useResetPassword = () => {
  return useMutation((data: ResetPasswordData & { token: string }) =>
    Api.patch(`/organizers/reset-password/${data.token}`, {
      password: data.password,
    })
  );
};
