import { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import * as auth from "../../services/auth";

const UpdateImage = ({File,Image,className,MainStyle}:any) => {
  
    const [preview, setPreview] = useState<any>({});
useEffect(()=>{
  if(File === Image ){
   return setPreview(Image)
  }
  if (File) {
      setPreview(URL.createObjectURL(File))
    }else{
      setPreview(Image)
    }
},[File])
let showData = File ?  <img className={className} src={preview} alt="" />:''
return (
  <div className={MainStyle}>
    {showData}
  </div>
)

}
 
export default UpdateImage;
