export const SignUpUser = "/auth/registration/";

export const ConfirmCode = "/auth/keyinput/";
/**
 * endpoint for getting catlist
 */
export const ResendVerification =
  "https://community.amalitech-dev.net/api/auth/create-key/";
/**
 * endpoint for getting catlist
 */

/* endpoint for adding product to cartorganizations/events
 */

/* endpoint for creating, fetching and deleting event
 */
export const fetchCreateDeleteCategories = "/categories";

/* endpoint for fetching organizer events
 */
export const fetchOrganizerEVent = "/events/organizers/me";

export const fetchOrganizationEvent = "/organizations/me/events";


/* endpoint for creating, fetching and deleting event
 */
export const fetchCreateUpdateDeleteGetEvent = "/events/";


/* endpoint for creating, fetching and deleting event
 */
export const fetchAllOrganizationsEvent = "/organizations/events";

/**
 * endpoint for signing up organizers
 */
export const signUpOrganizer = "/organizers/signup/email";

/*
  endpoint for organization sign up 
 */
// export const signUpOrganization = "/organizations/signup";
export const organizationSignUp = "/organizations/create";

// endpoint for organization change password
export const changePassword = "/organizations/change-password";

/*
 endpoint for signing in admins
 */
export const loginAdmin = "/em-admin/login";

/*
 endpoint for signing as  an organizer
 */
export const loginOrgnizationOrganizer = "/organizers/login/email";

/**
 * endpoint for signing in organizers
 */
export const loginOrganizer = "/organizations/login";

/**
 * endpoint for creating and getting all categories
 */
export const createGetAllCategories = "/categories";

/**
 * endpoint for creating and getting all categories
 */
export const createGetAllOrganizers = "/organizations";

/**
 * endpoint for verifying organization
 */
// export const verifyOrganization = "/organizations/verified/approve/";
export const verifyOrganization = "/organizations/verified/approve";

/**
 * endpoint for fetching all unverified organizations
 */ export const unVerifyOrganization =
  "/organizations/status?vStatus=unverified";

/**
 * endpoint for fetching all unverified organizations
 */ export const verifiedOrganization =
  "/organizations/status?vStatus=verified";

/**
 * endpoint for deleting Organization
 */
export const deleteOrganizer = "/organizations";

/**
 * endpoint for deleting Organization
 */
export const updateMember = "organizations/members";

/**
 * endpoint for getting all Organizers
 */
export const allOrganizers = "/organizers";

/**
 * endpoint for changing OrgPassword
 */
export const changeOrgPassword = "/organizations/change-password";

/**
 * endpoint for creating New Members to the organization
 */
export const addMember = "/organizations/members";

/**
 * endpoint for  getting all Members
 */
export const getAllMember = "/organizations/members";

/**
 * endpoint for  getting all Members
 */
export const getAllOrganizationsEvent = "/organizations/events";

/**
 * endpoint for  getting all Members
 */
// export const AdminGetOrganizationEvent = "/organizations/6419bf54a3aa11a9ecf8d3a5/events";

/**
 * endpoint for  getting all Members
 */
export const getAllAdminOrganizersEvent = "/events/organizers";
/**
 * endpoint for  getting all LandingPageEvents
 */
export const getAllLandingPageEvents = "/events/search";

// endpoint for searching for admin events

export const searchAdminEvent = "/events/eventSearch?title";

// endpoint for searching for admin Organizers

export const searchAdminOrganizers = "/organizers?name";

// endpoint for searching for admin Organization
export const searchAdminOrganization = "/organizations?name";

