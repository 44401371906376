import { useState } from "react";
import NoDataImage from "./../../assets/images/no-data.svg";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
// import  NoDataImage from "../../components/Layout/content/NoData";
import MonthSelector from "./MonthSelected";
import noEventFound from "../../assets/images/No event found.png";
import useEventStat from "../../hooks/useEventStat";
const NoEventsFoundImage = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}>
      <img
        src={NoDataImage}
        alt="No events found"
        style={{ width: "200px",height:"270px" }}
      />
      <p style={{ color: "#555", marginTop: "20px" }}>
        Sorry, no events found for the selected month.
      </p>
    </div>
  );
};

const Graph = ({eventData,setSelectedMonth}:any) => {

  const handleMonthChange = (newSelectedMonth: number) => {
    setSelectedMonth(newSelectedMonth);
  };

  return (
    <>
      <MonthSelector onChange={handleMonthChange} />
      {eventData?.length === 0 ? (
        <NoEventsFoundImage />
       
      ) : (
        
        <ResponsiveContainer width="100%" minHeight={370}>
          <BarChart
            data={eventData}
            margin={{
              top: 50,
              right: 30,
              left: -20,
              bottom: 5,
              
            }}>
            <Legend />

            <CartesianGrid horizontal={true} vertical={true} stroke="#C7C9D9" />

            <XAxis
              dataKey="name"
              tick={{ fill: "#555770", fontSize: "10px" }}
            />
            <YAxis tick={{ fill: "#555770", fontSize: "10px" }} />
            <Tooltip />
            <Bar dataKey="Events" fill="#3E7BFA"  barSize={32} />
          </BarChart>
        </ResponsiveContainer>
      )}
    </>
  );
};

export default Graph;
