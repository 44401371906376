import Api from "./api";
import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { IUser, LoginProps } from "../types/userTypes";
import * as routes from "../constants/routes/routes";

const AUTH_TOKEN_KEY: string = "authToken";
const EVENT_UER_ROLE_KEY: string = "eventUerRole";
const CURRENT_USER: string = "currentEventUser";
const SIGN_UP_USER: string = "signupUser";
const FORGOT_USER: string = "forgotUser";
export const AUTH_REFRESH: string = "authRefresh";
export const VERIFICATION_STATUS: string = "verificationStatus";
export const EVENT_APP_LOGGED_IN_USER: string = "event_app_logged_in_user";
export const EVENT_APP_LOGGED_IN_USER_ACCESS_TOKEN: string = "event_app_logged_in_user_access_token";
export const IS_ACTIVE: string = "IS_ACTIVE";

export function loginUser(userData: LoginProps) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Api.post("em-admin/login", userData);
      if (res.data) {
        const user = res.data;
        // setAuthToken(res.data.access);
        // setRefreshToken(res.data.refresh);
        setCurrentEventUser(user);
        resolve(res);
      } else {
        reject(res);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function signUpUser(userData: IUser) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Api.post("organizer/", userData);
      if (res.data) {
        setSignUpUser(res.data);
        resolve(res);
      } else {
        reject(res);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export function logoutUser() {
  clearAuthToken();
  clearCurrentEventUser();
  window.localStorage.clear();
  window.location.href = routes.landingPage;
}

export function setAuthToken(token: string) {
  Cookies.set(AUTH_TOKEN_KEY, token);
}
export function setUserRole(role: string) {
  Cookies.set(EVENT_UER_ROLE_KEY, role);
}
export function setVerificationStatus(verificationStatus: string) {
  Cookies.set(VERIFICATION_STATUS, verificationStatus);
}

export function setIsActive(isActive: string) {
  Cookies.set(IS_ACTIVE, isActive);
}

export function setEventAppLoginUser(userDetails: any) {
  Cookies.set(EVENT_APP_LOGGED_IN_USER, userDetails);
}
export function setEventAppLoginUserAccessToken(token: string) {
  Cookies.set(EVENT_APP_LOGGED_IN_USER_ACCESS_TOKEN, token);
}
export function getEventAppLoginUser() {
  return Cookies.get(EVENT_APP_LOGGED_IN_USER);
}
export function getEventAppLoginUserAccessToken() {
  return Cookies.get(EVENT_APP_LOGGED_IN_USER_ACCESS_TOKEN);
}
export function getUserRole() {
  return Cookies.get(EVENT_UER_ROLE_KEY);
}
export function getVerificationStatus() {
  return Cookies.get(VERIFICATION_STATUS);
}

export function getAuthToken() {
  return Cookies.get(AUTH_TOKEN_KEY);
}

export function setRefreshToken(token: string) {
  Cookies.set(AUTH_REFRESH, token);
}

export function getFreshToken() {
  return Cookies.get(AUTH_REFRESH);
}

export function clearAuthToken() {
  axios.defaults.headers.common.Authorization = "";
  return Cookies.remove(AUTH_TOKEN_KEY);
}

export function isLoggedIn() {
  const authToken = getAuthToken();
  return !!(authToken && isTokenActive(authToken));
}
export function setCurrentEventUser(data: IUser) {
  Cookies.set(CURRENT_USER, JSON.stringify(data));
}

export function setSignUpUser(data: IUser) {
  Cookies.set(SIGN_UP_USER, JSON.stringify(data));
}

export function getCurrentEventUser() {
  const user = Cookies.get(CURRENT_USER);
  if (!user) {
    // clearAuthToken();
    // clearCurrentEventUser();
    window.location.href = routes.home;
  } else {
    const currentEventUser: string = Cookies.get(CURRENT_USER) || " ";
    return JSON.parse(currentEventUser);
  }
}

export function getSignUpUser() {
  const signUpUser: string = Cookies.get(SIGN_UP_USER) || " ";

  return JSON.parse(signUpUser);
}

export function getForgotUser() {
  const forgotUser: string = Cookies.get(FORGOT_USER) || " ";

  return JSON.parse(forgotUser);
}

export function clearCurrentEventUser() {
  return Cookies.remove(CURRENT_USER);
}

export function isSuperUser() {
  return isLoggedIn();
}
export function getUserType() {
  if (isLoggedIn()) {
    return getCurrentEventUser().user;
  } else {
    return [];
  }
}

function getTokenExpirationDate(encodedToken: any) {
  const token: any = jwt_decode(encodedToken);
  if (!token?.exp) {
    return null;
  }
  return token.exp;
}
export function isTokenActive(token: any) {
  const expirationDate = getTokenExpirationDate(token);
  const now = Math.floor(Date.now() / 1000);
  return expirationDate > now;
}
