import * as React from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import * as colors from "./../../constants/colors/colors";
import AccountMenu from "./MenuItems";
import { Settings } from "@mui/icons-material";
import logo from "../../assets/images/logo.png";
import {
  getCurrentTabId,
  getDrawerStatus,
} from "../../services/redux/global/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { GlobalDataProps } from "../../types/store/global";
import { useNavigate } from "react-router-dom";
import * as router from "../../constants/routes/routes";
import { CategoryDataPropsTypes } from "../../types/store/category";
import Cookies from "js-cookie";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "8px",
  backgroundColor: colors.searchBarBlue,
  //   "&:hover": {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  height: "48px",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: colors.grey,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function SearchAppBar() {
  const { allUnVerifiedOrganizations } = useSelector(
    (state: any) => state.adminVerifications
  );
  const verificationStatus = Cookies.get("eventUerRole");


  const { userData } = useSelector(
    (state: CategoryDataPropsTypes) => state.user
  );

  const navigate = useNavigate();
  const { openDrawer } = useSelector(
    (state: GlobalDataProps) => state.appGlobal
  );
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
            {verificationStatus === "admin" && (
                <IconButton
                  onClick={() => {
                    dispatch(getCurrentTabId(6));
                    navigate(router.organization);
                  }}
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge
                    badgeContent={allUnVerifiedOrganizations?.length ?? 0}
                    color="error"
                  >
                    <NotificationsIcon className="text-black" />
                  </Badge>
                </IconButton>
              )}
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        {/* {verificationStatus === "admin" && (
          <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Settings className="text-black" />
          </IconButton>
        )} */}
        {/* <p>Notifications</p> */}
      </MenuItem>
      <MenuItem>
        <div className="ml-2">
          <AccountMenu />
        </div>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={1}>
        <Toolbar className="bg-white  flex items-center justify-between">
          <div className="flex items-center">
            <IconButton
              onClick={() => {
                dispatch(getDrawerStatus(!openDrawer));
              }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              {openDrawer ? (
                <MenuOpenIcon className="text-black" />
              ) : (
                <MenuIcon className="text-black" />
              )}
            </IconButton>
            <Typography
              className="text-black"
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <img src={logo} alt="logo" className="w-17 h-12" />
            </Typography>
          </div>
        
          <div>
            <Box sx={{ flexGrow: 1 , background:'red' }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              
            {verificationStatus === "admin" && (
                <IconButton
                  onClick={() => {
                    dispatch(getCurrentTabId(6));
                    navigate(router.organization);
                    dispatch(OrganizersAction.handleUnVerifiedOpen())
                  }}
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge
                    badgeContent={allUnVerifiedOrganizations?.length ?? 0}
                    color="error"
                  >
                    <NotificationsIcon className="text-black" />
                  </Badge>
                </IconButton>
              )}

              <MenuItem>
                <div className="">
                  <AccountMenu />
                </div>
              </MenuItem>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon className="text-grey" />
              </IconButton>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </Box>
  );
}
