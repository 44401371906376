import Modal from "@mui/material/Modal";
import ActionButton from "../button/ActionButton";
import * as colors from "../../constants/colors/colors";
import * as auth from "../../services/auth";

import { Logout } from "@mui/icons-material";

export default function UserAlert() {
  return (
    <div>
      <Modal
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="flex h-screen w-full margin-auto items-center justify-center">
          <div className="flex flex-col items-center justify-start text-center h-52 p-8 bg-white rounded-xl">
            <p className="font-bold text-3xl">
              Your Account is set, <br /> and awaiting approval.
            </p>
            <div className="mt-8">
              <ActionButton
                icon={<Logout fontSize="small" />}
                label="Log out"
                onClick={auth.logoutUser}
                backgroundColor={colors.searchButtonBlue}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
