import { createSlice } from "@reduxjs/toolkit";
import * as organizers from "./index";
import * as auth from "../../auth";
import { current } from "@reduxjs/toolkit";
interface initialStateType {
  data: {}[];
  updatedItem: any;
  Showform: boolean;
  ShowEditform: boolean;
  openModal: boolean;
  openDialoge: boolean;
  VerifiedOrganization: boolean;
  UnVerifiedOrganization: boolean;
  id: any;
}
const initialState: initialStateType = {
  data: [],
  updatedItem: {},
  Showform: false,
  ShowEditform: false,
  openModal: false,
  openDialoge: false,
  VerifiedOrganization: true,
  UnVerifiedOrganization: false,
  id: null,
};

const organizerSlice = createSlice({
  name: "organizer",
  initialState: initialState,
  reducers: {
    EditOrganizer: (state, action) => {
      
      state.updatedItem = state.data.find(
        (item: any) => item.id === action.payload
      );
      state.id = action.payload;
  
      
    },
  
    handleOpen: (state) => {
      state.ShowEditform = false;
      state.Showform = true;
      state.openModal = true;
     
    },
    handleDialogeOpen: (state) => {
      state.openDialoge = true;
    },
    handleDialogeClose: (state) => {
      state.openDialoge = false;
    },
    handEditForm: (state) => {
      state.ShowEditform = true;
      state.Showform = false;
      state.openModal = true;
      
    },
    handleClose: (state) => {
      state.Showform = false;
      state.ShowEditform = false;
      state.openModal = false;
    },
    handleUnVerifiedOpen: (state) => {
      state.UnVerifiedOrganization=true;
      state.VerifiedOrganization=false;



    },
    handleVerifiedOpen: (state) => {
      state.UnVerifiedOrganization=false;
      state.VerifiedOrganization=true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      organizers.fetchOrganizer.fulfilled,
      (state, { payload }) => {
        let loadedData: any[] = [];
        for (const key in payload) {
          if (auth.getUserRole() === "admin") {
            payload[key].map((item: any) => {
              loadedData.push({
                id: item._id,
                email: item.email,
                organization: item.name,
                location: item.location,
                name: item.contactName,
                description: item.description,
                image: item.image,
                phone: item.phone,
                role: item.role,
              });
            });
          } else {
            payload[key].map((item: any) => {
              loadedData.push({
                id: item._id,
                email: item.email,
                image: item.image,
                location: item.location,
                name: item.name,
                role: item.role,
              });
            });
          }
        }
        state.data = loadedData;
      }
    );
  },
});

export const OrganizersAction = organizerSlice.actions;
export default organizerSlice.reducer;
