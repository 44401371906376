import { useFormik } from "formik";
import { useState } from "react";
import { OrganizersSignUpSchema } from "../../../services/validationSchema";
import Api from "../../../services/api";
import * as endpoints from "../../../services/endpoints";
import { MoonLoader } from "react-spinners";
import PreviewImage from "../../images/PreviewImage";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { OrganizersAction } from "../../../services/redux/Organizer/organizerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { fetchOrganizer } from "../../../services/redux/Organizer/index";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  getLocationDetails,
  getLocationLogLtd,
} from "../../../services/redux/global/globalSlice";
import { CategoryDataPropsTypes } from "../../../types/store/category";

const OrganizationForm = ({}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const onCloseHandler = () => {
    dispatch(OrganizersAction.handleClose());
  };
  const { locationsDetails } = useSelector(
    (state: CategoryDataPropsTypes) => state.appGlobal
  );
  const [loading, setLoading] = useState(false);
  const [locationResult, setLocationResult] = useState<any>("");

  useEffect(() => {
    geocodeByAddress(locationResult?.label ?? "Takoradi, Ghana")
      .then((results) => {
        dispatch(
          getLocationDetails({
            placeID: results[0]?.place_id,
            placeName: results[0]?.formatted_address,
            city: results[0]?.address_components[0].long_name,
          })
        );
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        dispatch(
          getLocationLogLtd({
            lat,
            lng,
          })
        );
      });
  }, [locationResult]);

  const Avatar = "/image/user.png";
  const formik = useFormik({
    initialValues: {
      contactName: "",
      name: "",
      description: "",
      contactEmail: "",
      contactPhone: "",
      image: "",
    },
    validationSchema: OrganizersSignUpSchema,
    onSubmit: async (values, actions) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("contactName", values.contactName);
      formData.append("name", values.name);
      formData.append("email", values.contactEmail);
      formData.append("phone", values.contactPhone);
      formData.append("image", values.image == "" ? Avatar : values.image);
      formData.append("description", values.description);
      formData.append("location", JSON.stringify(locationsDetails));
      try {
        const res = await Api({
          method: "POST",
          url: endpoints.createGetAllOrganizers,
          headers: {
            "content-type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
          data: formData,
        });

        if (res.data && res.statusText === "Created") {
          enqueueSnackbar(`Created Organizer Profile successfully`, {
            variant: "success",
          });
          onCloseHandler();
          setLoading(false);
          setTimeout(() => {
            dispatch(fetchOrganizer());
            window.location.reload();
          }, 1000);

          formik.resetForm();
        } else {
          setLoading(false);
          actions.resetForm();
        }
      } catch (error: any) {
        setLoading(false);
        enqueueSnackbar(`Kindly check Organization name,Phone Number or your Email try again`,{ variant: "error",});
      }

      formik.resetForm();
    },
  });
  return (
    <form
      onSubmit={formik.handleSubmit}
      className="w-full flex flex-col h-full"
    >
      <div className="w-full flex justify-between h-[5%] font-bold text-[16px] ">
        Create Organization
        <CloseIcon onClick={onCloseHandler} />
      </div>
      <div className="w-full flex justify-between parent h-[85%]">
        <div className="w-[48%] flex flex-col h-full">
          <div className="flex w-full h-[12%] justify-between items-center">
            <label className=" mt-4 pl-3 mb-2 text-[14px]">Organization name</label>
            <p className="text-red mt-4 pl-3 mb-2 text-[14px] text-orange">{formik.errors.name}</p>
          </div>
          <input
            type="text"
            required
            placeholder="Type organization's name"
            id="name"
            name="name"
            autoComplete="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            onBlur={formik.handleBlur}
            className="w-full min-h-[12.5%] text-[14px] rounded-md text-DarkGray5 p-2 border border-darkGrey3 outline-none "
          ></input>

          <div className="flex w-full h-[12%] justify-between items-center">
            <label className=" mt-4  pl-3 mb-2 text-[14px]">Contact email</label>

            <p className="text-red mt-4 pl-3 mb-2 text-[14px] text-orange">{formik.errors.contactEmail}</p>
          </div>
          <input
            type="text"
            required
            placeholder="Type contact email"
            id="contactEmail"
            name="contactEmail"
            autoComplete="contactEmail"
            onChange={formik.handleChange}
            value={formik.values.contactEmail}
            onBlur={formik.handleBlur}
            className="w-full min-h-[10.5%] text-[14px] rounded-md text-DarkGray5 p-3  border border-darkGrey3 outline-none "
          ></input>
          <div className="flex w-full h-[12%] justify-between items-center">
            <label className="mt-4 pl-3 mb-2 text-[14px]">Contact phone</label>

            <p className="text-red mt-4 pl-3 mb-2 text-[14px] text-orange">{formik.errors.contactPhone}</p>
          </div>
          <input
            type="tel"
            required
            placeholder="Type contact phone"
            id="contactPhone"
            name="contactPhone"
            autoComplete="contactPhone"
            onChange={formik.handleChange}
            value={formik.values.contactPhone}
            onBlur={formik.handleBlur}
            className="w-full min-h-[10.5%] text-[14px] rounded-md text-DarkGray5 p-3  border border-darkGrey3 outline-none "
          ></input>
          <label htmlFor="Location" className="mt-4 pl-3 mb-2 text-[14px]">
            Location
          </label>
          <GooglePlacesAutocomplete
            apiOptions={{ language: "en", region: "gh" }}
            selectProps={{
              locationResult,
              onChange: setLocationResult,
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: 'gh',
              },
            }}
          />
        </div>

        <div className="w-[48%] h-full">
          <div className="flex w-full h-[12%] justify-between items-center">
            <label className="mt-4 pl-3 mb-2 text-[14px]">Contact name</label>
            <p className="text-red mt-4 pl-3 mb-2 text-[14px] text-orange">{formik.errors.contactName}</p>
          </div>
          <input
            type="text"
            required
            placeholder="Type contact name"
            id="contactName"
            name="contactName"
            autoComplete="contactName"
            onChange={formik.handleChange}
            value={formik.values.contactName}
            onBlur={formik.handleBlur}
            className="w-full min-h-[10.5%] text-[14px] rounded-md text-DarkGray5 p-3 border border-darkGrey3 outline-none "
          ></input>

          <div className="flex w-full h-[12%] justify-between items-center">
            <label className="mt-4 pl-3 mb-2 text-[14px]">Organization logo </label>
          </div>

          <div className="w-full min-h-[25%]  relative border border-darkGrey3 flex justify-center  items-center rounded-md text-DarkGray5 ">
            <PreviewImage
              File={formik.values?.image}
              Image={""}
              Style="h-[100%] rounded-md w-full  object-top object-cover"
              FormikImage="h-full bg-orange hidden w-full object-cover"
            ></PreviewImage>

            <IconButton
              sx={{
                color: "grey",
                display: "flex",
                justifyContent: "center",
                position: "absolute",
              }}
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input
                hidden
                name="image"
                accept="image/*"
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (!e.target.files) return;
                  formik.setFieldValue("image", e.target?.files[0]);
                }}
              />
              <PhotoCamera />
            </IconButton>
          </div>
          <div className="flex w-full h-[12%] justify-between items-center">
            <label className=" mt-4 pl-3 mb-2 text-[14px]">Description</label>

            <p className="text-red mt-4 pl-3 mb-2 text-[14px] text-orange">{formik.errors.description}</p>
          </div>
          <textarea
            ref={textareaRef}
            required
            placeholder="Type description"
            id="description"
            name="description"
            autoComplete="description"
            onChange={formik.handleChange}
            value={formik.values.description}
            onBlur={formik.handleBlur}
            className="w-full min-h-[25.5%] text-[14px] rounded-md text-DarkGray5 p-3  border border-darkGrey3 outline-none "
          ></textarea>
        </div>
      </div>
      <div className="w-full h-[10%] flex justify-end items-end ">
        <button
          onClick={onCloseHandler}
          className="w-[14%] rounded-lg h-[60%] border-darkGrey3 border mr-4 bg-[#F2F2F5] "
        >
          Cancel
        </button>
        {loading ? (
          <MoonLoader color="blue" size={25} />
        ) : (
          <>
            <button
              type="submit"
              className="w-[14%] rounded-lg h-[60%] text-white hover:bg-primary bg-blue "
            >
              Save
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default OrganizationForm;
