const GET_ALL_ORGANIZATION = "get_all_organization";
const GET_ALL_ORGANIZATION_EVENT = "get_all_organization_event";
const GET_ALL_ORGANIZER_EVENT = "get_all_organizer_event";
const VERIFY_ORGANIZATION = "verify_organization";
const ALL_VERIFY_ORGANIZATION = "all_verify_organization";
const ALL_UN_VERIFY_ORGANIZATION = "all_un_verify_organization";
const GET_ALL_EVENT_ORGANIZATION = "all_un_verify_organization";
const SEARCH_ORGANIZERS = "delete_event_organizers";
const SEARCH_ADMIN_ORGANIZTION = "delete_event_organizers";
export {
  VERIFY_ORGANIZATION,
  ALL_VERIFY_ORGANIZATION,
  ALL_UN_VERIFY_ORGANIZATION,
  GET_ALL_ORGANIZATION,
  GET_ALL_ORGANIZATION_EVENT,
  GET_ALL_ORGANIZER_EVENT,GET_ALL_EVENT_ORGANIZATION,
  SEARCH_ORGANIZERS,
  SEARCH_ADMIN_ORGANIZTION
};
