import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import MainLayout from "../../components/Layout/MainLayout";
import { EVentDataProps } from "../../types/store/event";
import { EventDetailsType, OrganizersTypes } from "../../types/eventTypes";
import { deletingEvent } from "../../services/redux/event/index";
import * as routes from "../../constants/routes/routes";
import moment from "moment";
import * as auth from "../../services/auth";
import * as colors from "../.././constants/colors/colors";

function Event() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    data: eventData,
    status,
    loaded,
  } = useSelector((state: EVentDataProps) => state.event);

  const { allOrganizationEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );
  //Hooks and functions for controlling Dialog
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitAction = async () => {
    dispatch(deletingEvent(id)).then(() => {
      if (loaded) {
        handleClose();
        enqueueSnackbar(`${viewedEvent?.title} deleted successfully`);
        setTimeout(() => {
          navigate(routes.events);
        }, 3000);
      }
    });
  };

  const [viewedEvent, setViewedEvent] = useState<any>();
  const displayOrganizerDetails = (organizer: OrganizersTypes) => {
    return (
      <div className="">
        <p>
          <span className="text-bold">Name:</span> {organizer.name}
        </p>
        <p>
          <span className="text-bold">Email:</span> {organizer.email}
        </p>
        <p>
          <span className="text-bold">Organization:</span>{" "}
          {organizer.organization}
        </p>
      </div>
    );
  };
  useEffect(() => {
    const getEvent =
      auth?.getUserRole() === "admin"
        ? eventData.find((event: any) => event._id === id)
        : allOrganizationEvent.find((event: any) => event._id === id);
    setViewedEvent(getEvent);
  }, []);

  const startTime = moment(viewedEvent?.startTime, "HH:mm");
  const endTime = moment(startTime).add(viewedEvent?.duration, "hours");
  const endTimeString = endTime.format("HH:mm");

  return (
    <MainLayout>
      <div className="w-full h-[95vh] parent overflow-y-scroll details bg-white">
        <div className=" gridStyleChild1 ">
          <div
            style={{ backgroundImage: `url(${viewedEvent?.image})` }}
            className={`relative w-full  h-[23rem] `}
          >
            <div className="absolute  inset-0 flex justify-center items-center backdrop-filter backdrop-blur-lg">
              <div className="relative  z-10 w-[780px] h-full  bg-white">
                <img
                  src={viewedEvent?.image}
                  alt="banner"
                  className="w-full object-cover object-top h-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-tranparent  gridStyleChild2 mb-10">
          <div className="w-full h-full gridDetails">
            <div className="gridDetails2">
              <div className="w-full h-full flex  justify-start">
                <div className="w-[50%] flex h-[95%] pl-5">
                  <div className="w-[40%] h-full">
                    <div className="w-full  text-grey h-[15%] ">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Event Title
                      </p>
                      <p className="text-[14px] w-[25rem]">
                        {viewedEvent?.title}
                      </p>
                    </div>

                    <div className="w-full  text-grey h-[10%] mb-5">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Date
                      </p>
                      <p className="text-[14px]">
                        {moment(viewedEvent?.date).format("MMMM Do, YYYY")}
                      </p>
                    </div>

                    <div className="w-full  text-grey h-[15%]">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Start Time
                      </p>
                      <p className="text-[14px]">
                        {`${viewedEvent?.startTime} - ${endTimeString}`}
                      </p>
                    </div>
                    <div className="w-full  text-grey] ">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Duration
                      </p>
                      <p className="text-[14px]">{viewedEvent?.duration} hrs</p>
                    </div>
                    <div className="w-full h-[10%] ">
                      <input type="checkbox" checked={viewedEvent?.featured} />
                      <label className="w-[61px] h-14 px-1">Featured?</label>
                    </div>
                    <div className="w-full  text-grey">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Description
                      </p>
                      <p className="text-[14px] w-[30rem]">
                        {viewedEvent?.description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-[40%] flex h-[95%] pr-0">
                  <div className="w-[40%] h-full ">
                    <div className="w-full  text-grey h-[15%] ">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Organizer
                      </p>
                      <p className="text-[14px]">
                        {viewedEvent?.organizer?.name}
                      </p>
                    </div>
                    <div className="w-full  text-grey h-[15%] ">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Category
                      </p>
                      <p className="text-[14px]">
                        {viewedEvent?.category?.[0]?.name}
                      </p>
                    </div>
                    <div className="w-full  text-grey h-[11%] ">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Location
                      </p>
                      <p className="text-[14px]">
                        {viewedEvent?.location?.placeName}
                      </p>
                    </div>

                    <div className="parent w-80 h-40 top-36 left-44 rounded-lg mt-5">
                      <img
                        className="object-cover object-top w-full h-full"
                        src={viewedEvent?.image}
                        alt="Pic of events"
                      />
                    </div>
                    {/* <div className="w-full  text-grey h-[10%] mt-5">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Summary
                      </p>
                      <p className="text-[14px]">{viewedEvent?.summary}</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-end px-10 mb-10 relative top-[-20px]">
            <button
              style={{ backgroundColor: colors.searchButtonBlue }}
              className="h-11 text-white mt-8  md:w-[30px] lg:w-[100px] w-[30%] 
                           rounded-xl px-4 flex items-center justify-center shadow relative top-[-20px]"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Event;
