import * as React from "react";
import * as auth from "../../services/auth";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Logout from "@mui/icons-material/Logout";
import { useSelector } from "react-redux";
import * as colors from "./../../constants/colors/colors";
import { UserDataProps } from "../../types/store/user";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { IconButton } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import * as routes from "../../constants/routes/routes";
import { useNavigate } from "react-router-dom";
// import { BiCategory } from "react-icons/bi";

export default function AccountMenu() {
  const { userData } = useSelector((state: UserDataProps) => state.user);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleChangePassword = () => {
    navigate(routes.changePassword);
  };

  const handleProfile = () => {
    navigate(routes.profile);
  };

  //   const handleAddCategory = () => {
  //   navigate(routes.addCategory);
  // };

 
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <div className="flex items-center">
          {userData?.image ? (
            <Avatar alt="User" src={userData?.image} />
          ) : (
            <Avatar onClick={handleClick} sx={{ width: 32, height: 32 }}>
              <h1>
                {userData && userData.name ? userData.name.charAt(0) : ""}
              </h1>
            </Avatar>
          )}{" "}
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}>
            <KeyboardArrowDownIcon
              sx={{ color: colors.black, cursor: "pointer" }}
            />
          </IconButton>
        </div>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
        <MenuItem>
          <div className="w-full h-12  ">
            <p className="text-[16px] font-bold">{userData.name}</p>
            <p className="text-[14px] mt-1 text-[#555770]">{userData.email}</p>
          </div>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleProfile}>
          <Avatar src={userData?.image} /> Profile
        </MenuItem>
        {/* <MenuItem onClick={handleAddCategory}>
          <ListItemIcon>
            <BiCategory fontSize="large" />
          </ListItemIcon>
          Add Category
        </MenuItem> */}
        <MenuItem onClick={handleChangePassword}>
          <ListItemIcon>
            <KeyIcon fontSize="small" />
          </ListItemIcon>
          Change Password
        </MenuItem>

        <MenuItem onClick={auth.logoutUser}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
