import { useQuery, UseQueryResult } from "@tanstack/react-query";
import Api from "../services/api";

export interface EventStats {
  status: string;
  data: {
    stats: { _id: string; count: number }[];
  };
}


const useEventStat = (month: number): UseQueryResult<EventStats, unknown> => {
  return useQuery(["eventStat", month], async () => {
    const { data } = await Api.get<EventStats>(`/events/stats?month=${month}`);
    return data;
  });
};

export default useEventStat;
