import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { HiPencilSquare, HiTrash } from "react-icons/hi2";

interface CardActionProps {
  onClickEdit?: () => void;
  onClickDelete?: (categoryId: string) => void;
  Delete?: string;
  Edit?: string;
  categoryId: string; // Add categoryId prop
}

const ITEM_HEIGHT = 48;

const CategoryCard = ({
  onClickEdit,
  onClickDelete,
  Edit,
  Delete,
  categoryId, // Receive categoryId prop
}: CardActionProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = () => {
    if (onClickDelete) {
      onClickDelete(categoryId); // Pass the categoryId as an argument
    }
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        disableEnforceFocus
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}>
        <MenuItem onClick={onClickEdit}>
          <HiPencilSquare className="mr-2" />
          {Edit}
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          <HiTrash className="mr-2" />
          {Delete}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CategoryCard;
