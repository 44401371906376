import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_ALL_CATEGORY, CREATE_CATEGORY, DELETE_CATEGORY } from "./actions";
import { fetchCreateDeleteCategories } from "../../endpoints";
import Api from "../../api";

export const fetchCategory: any = createAsyncThunk(GET_ALL_CATEGORY, async () => {
  try {
    const response = await Api({
      method: "GET",
      url: fetchCreateDeleteCategories,
    });

    return response?.data?.data;
    
  } catch (error) {
    console.dir(error);
  }
});

export const createCategory: any = createAsyncThunk(
  CREATE_CATEGORY,
  async (data) => {
    try {
      const response = await Api({
        data,
        method: "POST",
        url: fetchCreateDeleteCategories + "/",
      });
      await fetchCategory();
      return response?.status;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const deletingCategory: any = createAsyncThunk(
  DELETE_CATEGORY,
  async (id) => {
    try {
      const response = await Api({
        method: "DELETE",
        url: fetchCreateDeleteCategories + "/" + id,
      });
      await fetchCategory();
      return response?.status;
    } catch (error) {
      console.dir(error);
    }
  }
);
