import { ItemDetailsProps } from "../../types/components/itemsDetails";

function ItemDetails({
  title,
  value,
  icon,
  Tags,
}: // analyticIcon,
// analyticDesc,
ItemDetailsProps) {
  return (
    <div className="flex items-center justify-between bg-white shadow rounded-xl h-[117px] w-full my-2 md:my-0 md:w-[300px] p-4 relative">
      <div className="">
        <p>{title}</p>
        <p className=" flex items-end mt-4">
          <p className="font-bold text-3xl  items-end text-black">
            {value}
          </p>
          <p className="text-[11px] ml-1 mb-1 text-[#8F90A6]">{Tags}</p>
        </p>
      </div>
      <div className="flex mb-12 ">
        <div className="relative">
          <div className="items-center justify-center rounded-full">{icon}</div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetails;
