import { useFormik } from "formik";
import Api from "../../../services/api";
import * as endpoints from "../../../services/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import { OrganizersAction } from "../../../services/redux/Organizer/organizerSlice";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import PreviewImage from "../../images/PreviewImage";
import { fetchOrganizer } from "../../../services/redux/Organizer/index";
import { MoonLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
const AddMemberForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const onCloseHandler = () => {
    dispatch(OrganizersAction.handleClose());
    dispatch(fetchOrganizer())
  };
  useEffect(()=>{
    dispatch(fetchOrganizer())
  },[dispatch])
  const [loading, setLoading] = useState(false);
  const Avater = "/image/user.png";
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      location: "",
      image: "",
    },
    
    onSubmit: async (values, actions) => {
      setLoading(true)
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("location", values.location);
      formData.append("image", values.image==''?Avater:values.image);
      try {
        const res = await Api({
          method: "POST",
          url: endpoints.addMember,
          headers: {
            "content-type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
          data: formData,
        });
       
        if (res.data && res.statusText === "Created") {
          onCloseHandler()
          setLoading(false)
          enqueueSnackbar(`${values.name} Created successfully`);
          setTimeout(() => {
            dispatch(fetchOrganizer());
            window.location.reload()      
          }, 1000);
          formik.resetForm();
        } else {
          actions.resetForm();
        }
      } catch (error) {
        setLoading(false)
      }

      formik.resetForm();
    },
  });
  return (
    <form
      className="w-full flex flex-col p-5 justify-center h-full items-center"
      onSubmit={formik.handleSubmit}
    >
      <div className="w-full flex justify-between h-[8%] font-bold text-[19px] ">
        Add Organizer
        <CloseIcon onClick={onCloseHandler} />
      </div>
      <div className="w-full h-[92%] ">
        <div className="w-full flex flex-col justify-around items-center h-[50%] ">
          <div className="w-[10rem] h-[10rem] rounded-full object-cover object-top">
            <PreviewImage
              File={formik.values?.image}
              Image={Avater}
              Style="h-[100%] rounded-full w-full object-cover"
            />
          </div>
          <IconButton
            sx={{
              color: "grey",
              display: "flex",
              justifyContent: "center",
            }}
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              hidden
              name="image"
              accept="image/*"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!e.target.files) return;
                formik.setFieldValue("image", e.target?.files[0]);
              }}
            />
            <div
              className="w-[10rem] h-[2.5rem] rounded-md
             text-[19px] flex justify-center
             items-center border border-darkGrey3 text-black"
            >
              Choose photo
            </div>
          </IconButton>
        </div>
        <div className="w-full flex flex-col justify-around items-center h-[40%] ">
          <div className="w-[80%] h-[40%]  flex flex-col justify-center ">
            <label htmlFor="Name">Contact Name</label>

            <input
              type="text"
              required
              placeholder="Contact Name"
              id="name"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
              className={`${`w-[95%] p-3 rounded-lg outline-none border border-darkGrey3 md:h-[70%] `}`}
            ></input>
          </div>
          <div className="w-[80%] h-[40%] flex flex-col  justify-center ">
            <label htmlFor="email">Email</label>

            <input
              type="email"
              required
              placeholder="Contact email"
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={formik.handleChange}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              className={`${`w-[95%] p-3 rounded-lg outline-none border border-darkGrey3  md:h-[65%] `}`}
            ></input>
          </div>
     
        </div>
        <div className="w-[85%] h-[10%] flex justify-end items-end ">
          <button
            onClick={onCloseHandler}
            className="w-[25%] rounded-lg h-[74%] border mr-4 border-orange font-bold"
          >
            Cancel
          </button>
          {loading ? (
          <MoonLoader color='blue' size={25} />
        ) : (
          <>
            <button
          
              type="submit"
              className="w-[25%] rounded-lg h-[74%] text-white hover:bg-primary bg-blue font-bold"
            >
              Save
            </button>
          </>
        )}
        </div>
      </div>
    </form>
  );
};

export default AddMemberForm;
