import { useParams } from "react-router-dom";
import MainLayout from "../../components/Layout/MainLayout";
import { useSelector } from "react-redux";
import { CategoryDataPropsTypes } from "../../types/store/category";
import * as auth from "../../services/auth";
import { EVentDataProps } from "../../types/store/event";
import { useNavigate } from "react-router-dom";
import * as colors from "../.././constants/colors/colors";
import EventCard from "../../components/card/EventCard";
import DetailsCard from "../../components/card/DetailsCard";
import ActionButton from "../../components/button/ActionButton";
const OrganizersDetail = () => {
  const navigate = useNavigate();
  const { organizerEvent } = useSelector((state: any) => state.event);


  const { allOrganizersData } = useSelector(
    (state: CategoryDataPropsTypes) => state.eventOrganizers
  );
  const { allOrganizersEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );
  const { allOrganizationEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );

  const OrganizersList = useSelector((state: any) => state.organizer.data);
  const data =
    auth?.getUserRole() === "admin" ? allOrganizersData : OrganizersList;
  const { id } = useParams();
  const detailItem = data?.find((item: any) => {
    const itemId = auth?.getUserRole() === "admin" ? item._id : item.id;
    return itemId === id;
    
  });

  return (
    <MainLayout>
      <div className="w-full h-[87vh] parent overflow-y-scroll gridStyle bg-white ">
      <div className=" gridStyleChild1 bg-primary ">
          <img
            src="/Rectangle 18.png"
            alt="banner"
            className="w-full object-cover h-full"
          />
        </div>
        <div className="bg-tranparent  gridStyleChild2">
          <div className="w-full h-full gridDetails">
            <div className="gridDetails1">
              <div className="w-full h-full  flex items-center ">
                <div className="w-[13.5rem] ml-[80px] bg-light-grey  rounded-full h-full ">
                  <img
                    src={detailItem?.image}
                    alt=""
                    className=" w-full object-cover object-top h-full rounded-full"
                  />
                </div>
                <div className="w-[60%]  h-full flex-col  flex justify-center">
                  <p className="ml-6 mt-[93px] text-[25px] font-bold">
                    {detailItem?.name}
                  </p>
                  <p className="ml-6 text-[16px] text-DarkGray1">
                    {detailItem?.email}
                  </p>
                </div>
              </div>
            </div>
            <div className="gridDetails2">
              <div className=" w-full  px-8 flex flex-col justify-center  h-[90%]">
                <p className=" ml-1 font-semibold ">Current Events</p>
                <div className="w-full items-center  flex h-[78%]  parent ">
                  {organizerEvent.length === 0 ? (
                    <div className="w-full flex flex-col justify-center items-center h-full">
                      <img
                        src="/image/no event 1.png "
                        className="w-[50%] object-cover h-[80%]"
                      ></img>
                      <p>No events Found</p>
                    </div>
                  ) : (
                    <div className="w-full flex h-full">
                      {organizerEvent?.map((item: any) => {
                        return (
                          <DetailsCard
                            date={item.date}
                            totalLikes={item.totalLikes}
                            duration={item.duration}
                            title={item?.title}
                            location={item?.location?.placeName}
                            time={item?.startTime ? item?.startTime : ""}
                            image={item?.image}
                            _id={item?._id}
                            city={item?.location?.city}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
                <div className="w-full  h-[10%] mt-5 flex justify-end items-center">
                  <button
                    style={{ backgroundColor: colors.searchButtonBlue }}
                    className="h-11 text-white   md:w-[30px] lg:w-[100px] w-[30%] 
               rounded-xl px-4 flex items-center justify-center shadow"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </MainLayout>
  );
};

export default OrganizersDetail;
