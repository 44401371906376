import React, { useState } from "react";
import WaitForApprovalModal from "../../components/notification/WaitForApprovalModal";

const AwaitApproval: React.FC = () => {
  const [isApproved, setIsApproved] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(!isApproved);

  return (
    <div>
      <WaitForApprovalModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default AwaitApproval;
