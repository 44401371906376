// import { BsUnion } from "react-icons/ai";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Link } from "react-router-dom";
import * as React from "react";
const LandingPageMainFeatures = React.forwardRef(({ref}:any) => {
  const MainFeatures = [
    {
      Title: "Register for Event",
      description:
        "Our app allows you to easily register for events you are interested in attending. You can do this directly through the app",
      icons: <AssignmentIcon sx={{ width: "60px", height: "60px" }} />,
    },
    {
      Title: "Calendar",
      description:
        "The app's calendar feature lets you keep track of your events, so you never miss out on an important occasion. You can view all your events in one place",
      icons: <CalendarMonthIcon sx={{ width: "60px", height: "60px" }} />,
    },
    {
      Title: "Location",
      description:
        "Finding the event location is made easy with our app's location feature. You can get directions to the event from your current location or enter a different address. The map view provides a visual representation of the event's location and nearby amenities",
      icons: <LocationOnIcon sx={{ width: "60px", height: "60px" }} />,
    },
  ];

  return (
    <div className="w-full   h-full ">
      <div className="w-full h-[40%] ">
        <div className="w-full h-[40%] flex justify-center items-center flex-col">
          <p className="w-[50%]  text-[36px] flex justify-center font-family">Our Features</p>
          <p className="w-[66%] text-center font-family text-[#555770] ">
            Register for events, keep track of your schedule, and find your way
            to your next event - all in one app
          </p>
        </div>
        <div className=" flex justify-between w-full h-[60%]">
          {MainFeatures.map((item: any) => {
            return (
              <div className="w-[24%] h-full ">
                <div className="w-full h-[50%] flex justify-center items-center ">
                  <p className="text-[#3E7BFA] flex justify-center  items-center w-[7rem] rounded-full h-[6.9rem] bg-[#E5F0FF]">
                    {item.icons}
                  </p>
                </div>
                <div className="  flex items-center mt-1 flex-col  w-full ">
                  <p className="font-semibold font-family">{item.Title}</p>
                  <div className=" line-clamp-3 font-family hover:line-clamp-none transition text-center text-[14px] mt-2 w-[100%] font-light justify-center ">
                    {item.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="w-full h-[50%] flex  mt-6 px-[8rem]" >
          <div className=" w-[50%] h-full flex items-end">
            <img
              src="/Vectary texture.png"
              alt=""
              className=" contrast-100 brightness-100 h-[27rem]"
            />
          </div>
          <div className="w-[50%] flex flex-col justify-center ">
            <p className="text-[24px] font-family w-[50%] leading-[30px] ">
              Available for your Smartphones
            </p>
            <p className="mt-2 font-family text-[13px] text-[#555770] ">
              Don't miss out on the exciting events happening in your area! Our
              local event app is available for download on both Google Play
              Store and Apple App Store. Download now and stay updated on the
              latest events, wherever you are!
            </p>

            <div className="h-[4rem] mt-2 flex items-center justify-between   w-[25rem] ">
              
               <Link to='https://play.google.com/store/games?pli=1' className="w-[44%]  flex h-[82%] bg-black  rounded-md ">
               <button className="w-full h-full">
                <div className="w-full h-full flex p-[1px] ">
                <div className="w-[30%] h-full flex  justify-center items-center ">
                  <img src="/🦆 icon _Google Play_.png" alt="" className="" />
                </div>
                <div className="w-[70%] text-white h-full flex  justify-center flex-col items-start ">
                  <p className="text-[12px] text-grey">GET IT ON</p>
                  <p className="text-[20px] leading-[20px] font-semibold">Google Play</p>
                </div>
                </div>
            
               </button>
               </Link>

               <Link to='https://www.apple.com/app-store/' className="w-[44%] h-[82%] rounded-md mr-6 bg-black">
               <button className="w-full h-full p-[1px]">
               <div className="w-full h-full flex">
               <div className="w-[30%] h-full flex justify-center items-center ">
                  <img src="/🦆 icon _Apple_.png" alt="" className="" />
                </div>
                <div className="w-[80%] text-white h-full flex justify-center flex-col items-start ">
                  <p className="text-[12px] text-grey">Download on the</p>
                  <p className="text-[20px] leading-[20px] font-semibold">App store</p>
                </div>
               </div>
               </button>
               </Link>
            </div>
          </div>
        </div>
    </div>
  );
});

export default LandingPageMainFeatures;
