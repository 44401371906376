import { useNavigate } from "react-router-dom";
import { fetchOrganizersEvent } from "../../services/redux/event/index";
import CardAction from "../menu/card/CardAction";
import { useSelector, useDispatch } from "react-redux";
import ProgressBar from "../progressbar/progressBar";
const DashBoradCard = (props: any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const Events = props.createdEvents > 100?props.createdEvents=100 : props.createdEvents
  
  return (
    <div className="">
      <div
        className="w-full flex h-[55px]  mt-[0.125rem] p-2 bg-[#FFFFFF]  hover:bg-[#E5F0FF]  "
        onClick={() => {
          navigate(`/organization-detail/${props.id}`);
          dispatch(fetchOrganizersEvent(props.id));
        }}
      >
        <div className="w-[36.5%] h-full   flex pl-3 items-center">
          {" "}
          <img
            src={props.image}
            alt=""
            className="w-[2.5rem] h-[2.5rem]  rounded-full object-cover object-top"
          />
          <p className="ml-2 text-[#555770] text-[15px] ">{props.name}</p>
        </div>
        <div
          className="w-[25%] h-full  flex justify-start text-[#555770] text-[15px] pl-3 items-center"
        >
          {props.organization}
        </div>
        <div className="w-[25%] h-full truncate flex  justify-end text-[15px] text-[#555770] pl-3 items-center">
        <div className="w-[80%] items-center justify-start flex h-full">
        {props.city}
        </div>
        </div>
        <div className="w-[25%] h-full flex justify-between text-[15px] text-[#555770] pl-3 items-center">
          <div className="w-[20%] flex  justify-center  items-center h-full">
            {" "}
            {props.createdEvents}
          </div>
          <div className="w-[70%] flex mr-4 text-[#555770] text-[15px] justify-center items-center  h-full">
            <ProgressBar events={Events} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashBoradCard;
