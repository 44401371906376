import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import EventCard from "../card/EventCard";
import { useDispatch } from "react-redux";
import LandingEventCard from "../card/LadingPageEvent";
import { EVentDataProps } from "../../types/store/event";
import NoDataImage from "../Layout/content/NoData";
const LandingPageEvents = ({eventData}:any) => {
  const dispatch = useDispatch()
  const [All, setAll] = useState("text-[16px]");
  const [today, setToday] = useState("text-[16px]");
  const allHandler = () => {
    setAll(
      "rounded-full w-[42%] h-[60%] grid text-[14px] place-items-center bg-[#E5F0FF]"
    );
    setToday("text-[16px]");
  };
  const todayHandler = () => {
    setToday(
      "rounded-full w-[62%] h-[70%] grid text-[16px] place-items-center bg-[#E5F0FF]"
    );
    setAll("text-[16px]");
  };
  // useEffect(()=>{
  //   dispatch(fetchEvent())
  // },[])

//   const {status: eventData, loaded } = useSelector(
//     (state: any) => state.event
//   );
// console.log(eventData);

  return (
    <div className="w-full h-full ">
      
      <div className="w-full h-[25%]  mt-7  flex">
        <div className="w-[47%] h-full">
          <div className="w-[90%] h-[80%] font-family">
            This is your time to connect with like-minded individuals and
            build meaningful relationships that bring communities, family, friends and lover's together.
          </div>
          
        </div>
        <div className="w-[53%] h-full ">
          <img src="/Group 1127 (1).png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default LandingPageEvents;
