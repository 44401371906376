import { useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import * as auth from "../../services/auth";
const PreviewImage = ({ File,Image,Style,FormikImage}: any) => {
  const [preview, setPreview] = useState<any>({});
  useEffect(()=>{
    if(File === Image ){
      return setPreview(Image)
     }
    if (File) {
        setPreview(URL.createObjectURL(File))
      }else{
        setPreview("/broken-image.jpg")
      }
  },[File])
 let showData = File  ?  <img className={Style} src={preview} alt="" />:
  <img className={FormikImage} src={Image} alt="" />
  return (
  <div className={auth?.getUserRole() === "admin"? 'w-full h-[7rem]':'w-full h-full'}>
    {showData}
  </div>
  )
};

export default PreviewImage;
