import { useState, useEffect } from "react";
import MainLayout from "../Layout/MainLayout";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Api from "../../services/api";
import * as endpoints from "../../services/endpoints";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { MoonLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import ActionButton from "../button/ActionButton";
import * as colors from "../../constants/colors/colors";
import * as auth from "../../services/auth";
import { Theme, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { fetchEvent } from "../../services/redux/event/index";
import { getEditedItems } from "../../services/redux/global/globalSlice";
import {
  CategoryTypes,
  CategoryDataPropsTypes,
} from "../../types/store/category";
import { useSelector, useDispatch } from "react-redux";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { searchAdminEvents } from "../../services/redux/event/index";
import { UserDataProps } from "../../types/store/user";
import { OrganizerTypes } from "../../types/organizersTypes";
import { FormControl, OutlinedInput } from "@mui/material";
import { fetchCategory } from "../../services/redux/category";
import {
  getLocationDetails,
  getLocationLogLtd,
} from "../../services/redux/global/globalSlice";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import { EVentDataProps } from "../../types/store/event";
import { useNavigate } from "react-router-dom";
import { getItemId } from "../../services/redux/global/globalSlice";
import { fetchAllOrganizationEvent } from "../../services/redux/admin/index";
import { log } from "console";

interface CatTypes {
  _id: string;
  name: string;
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const EditEventForm = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { userData } = useSelector((state: UserDataProps) => state.user);
  const { data: categoryData } = useSelector(
    (state: CategoryDataPropsTypes) => state.category
  );
  const { itemId } = useSelector((state: any) => state.appGlobal);
  const { allOrganizersData } = useSelector(
    (state: CategoryDataPropsTypes) => state.eventOrganizers
  );
  const {value} = useSelector((state:any) => state.event)
  
  const { allOrganizationEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );
  const { editedItem } = useSelector(
    (state: CategoryDataPropsTypes) => state.appGlobal
  );


  const { data: eventData } = useSelector(
    (state: EVentDataProps) => state.event
  );
  const { locationsDetails } = useSelector(
    (state: CategoryDataPropsTypes) => state.appGlobal
  );

  let categoryIds: any = [];
  const [eventOrganizers, setEventOrganizers] = useState<any>(
    editedItem?.organizer?._id ?? []
  );
  const HandleClose = () => {
    dispatch(OrganizersAction.handleClose());
  };
  const [locationResult, setLocationResult] = useState<any>(null);

  const [selectedImage, setSelectedImage] = useState<any>(null);

  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState<any>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createEventFormData, setCreateEventFormData] = useState({
    title: editedItem?.title ?? "",
    description: editedItem?.description ?? "",
    category: [],
    date: editedItem?.date.split("T")[0] ?? "",
    startTime: editedItem?.startTime ?? "",
    duration: editedItem?.duration ?? "",
    organizers: editedItem?.title ?? "",
    image: editedItem?.title ?? "",
    minDate: new Date().toISOString().split("T")[0],
  });


  const theme = useTheme();
  const [categoryName, setCategoryName] = useState<string[]>([]);

  useEffect(() => {
    let cat: any = [];

    editedItem?.category.map((catItem: any) => cat.push(catItem?._id));
    setCategoryName(cat);
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof categoryName>) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOrganizersChange = (
    event: SelectChangeEvent<typeof eventOrganizers>
  ) => {
    const {
      target: { value },
    } = event;
    setEventOrganizers(typeof value === "string" ? value.split(",") : value);
  };

  const handleCreateEventFormDataChange = (event: any) => {
    setCreateEventFormData({
      ...createEventFormData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    dispatch(fetchCategory());
    
  }, [dispatch]);

  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    editedItem.category.map((cat: CatTypes) => {
      categoryIds.push(cat._id);
    });
  }, []);

  useEffect(() => {
    geocodeByAddress(locationResult?.label ?? editedItem?.location?.placeName)
      .then((results) => {
        dispatch(
          getLocationDetails({
            placeID: results[0]?.place_id,
            placeName: results[0]?.formatted_address,
            city: results[0]?.address_components[0].long_name,
          })
        );
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        dispatch(
          getLocationLogLtd({
            lat,
            lng,
          })
        );
      });
  }, [locationResult]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData: any = new FormData();
    formData.append("title", createEventFormData.title);
    formData.append("description", createEventFormData.description);
    formData.append("category", categoryName);
    formData.append("date", createEventFormData.date);
    formData.append("minDate", createEventFormData.minDate);
    formData.append("location", JSON.stringify(locationsDetails));
    formData.append("startTime", createEventFormData.startTime);
    formData.append("duration", createEventFormData.duration);
    formData.append(
      "organizer",
      auth.getUserRole() === "admin" ? eventOrganizers : [userData?._id]
    );
    formData.append("image", selectedImage ?? editedItem?.image);

    if (locationsDetails.lat === 0) {
      setError(true);
    } else {
      try {
        setLoading(true);
        const res = await Api({
          method: "PATCH",
          url: endpoints.fetchCreateUpdateDeleteGetEvent + "/" + editedItem._id,
          headers: {
            "Content-Type": "multipart/form-data;",
          },
          data: formData,
        });
        await dispatch(fetchEvent()); dispatch(fetchAllOrganizationEvent())
        if (res.data) {
          setImageUrl(null);
          setLoading(false);
          enqueueSnackbar("Event Updated successful", { variant: "success" });
          HandleClose();
          
          setTimeout(() => {
           if(value === ''){
            dispatch(fetchEvent());
            dispatch(fetchAllOrganizationEvent())
           }
            else{
              dispatch(searchAdminEvents(value));
            }
            window.location.reload();
           
          }, 2000);
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  return (
    <div className="w-full flex justify-center  items-center h-full ">
      <form
        className="w-full flex flex-col p-6 items-center h-full bg-white rounded-xl"
        onSubmit={handleSubmit}
      >
        <div className="w-full h-[6%] text-[16px] font-semibold ">
          Edit Event
        </div>
        <div className="w-full flex-col parent  md:flex-row lg:flex-row flex justify-between">
          <div className="w-full  md:w-[48%] parent lg:w-[48%] my-2 md:my-0 lg:my-0  flex flex-col h-full justify-between">
            <div>
              <div className="my-3">
                <label htmlFor="Title" className="text-[14px] pl-3 ">Event</label>
                <div className="w-full rounded-lg text-[15px] outline-none h-[60%] border-darkGrey3  border  flex">
                  <input
                    autoFocus
                    required
                    placeholder="Title"
                    id="title"
                    name="title"
                    autoComplete="title"
                    value={createEventFormData.title}
                    onChange={handleCreateEventFormDataChange}
                    className="w-full rounded-lg p-[14.2px] outline-none bg-transparent h-[100%]"
                  ></input>
                </div>
              </div>
              <div className="w-full md:w-[100%] lg:w-[100%] my-3 md:my-3 lg:my-3">
                <label htmlFor="Category" className="text-[14px] pl-3 ">Category</label>
                <FormControl
                  sx={{
                    // m: 1,
                    width: "100%",
                    // backgroundColor: colors.lightGrey,
                    borderRadius: "10px",
                    padding: 0,
                  }}
                  variant="outlined"
                >
                  <Select
                    required
                    className="eventCategory-select"
                    sx={{
                      width: "100%",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "& .MuiInputBase-root": {},
                    }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    // multiple
                    value={categoryName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: {
                            xs: 150,
                          },
                          width: 250,
                        },
                      },
                    }}
                  >
                    {categoryData?.map((category: CategoryTypes) => (
                      <MenuItem
                        sx={{ height: 40 }}
                        key={category._id}
                        value={category._id}
                        style={getStyles(category?.name, categoryIds, theme)}
                      >
                        {category?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="w-full md:w-[100%] lg:w-[100%] my-2 md:my-0 lg:my-0">
                <label htmlFor="Price" className="text-[14px] pl-3">Date</label>
                <div className="w-full rounded-lg outline-none border-darkGrey3 border flex">
                  <input
                    type="date"
                    required
                    placeholder="Date"
                    id="date"
                    name="date"
                    autoComplete="date"
                    value={createEventFormData.date}
                    min={createEventFormData.minDate}
                    onChange={handleCreateEventFormDataChange}
                    className="w-full rounded-lg  mt-2 p-[13px] outline-none bg-transparent h-[100%]"
                  ></input>
                </div>
              </div>
              <div className="w-full md:w-[100%] lg:w-[100%] my-2 md:my-0 lg:my-3">
                <label htmlFor="Time" className="text-[14px] pl-3">Start Time</label>
                <div className="w-full rounded-lg outline-none border-darkGrey3 border flex">
                  <input
                    type="time"
                    required
                    placeholder="Start Time"
                    id="startTime"
                    name="startTime"
                    autoComplete="startTime"
                    value={createEventFormData.startTime}
                    onChange={handleCreateEventFormDataChange}
                    className="w-full rounded-lg mt-2 p-[13px] outline-none bg-transparent h-[100%]"
                  ></input>
                </div>
              </div>
              <div className="mt-4 ">
                <div>
                  <input
                    accept="image/*"
                    type="file"
                    id="select-image"
                    style={{ display: "none" }}
                    onChange={(e: any) => setSelectedImage(e.target.files[0])}
                  />
                  <div
                    style={{
                      backgroundImage:
                        (imageUrl && selectedImage && `url(${imageUrl})`) ||
                        `url(${editedItem.image})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                    }}
                    className="flex object-contain items-center justify-center
                        rounded-xl w-full h-[150px] border-darkGrey3 border "
                  >
                    <label htmlFor="select-image">
                      <div
                        className="flex items-center justify-center w-12
                           h-12 rounded-full bg-white scale-95 cursor-pointer
                           hover:scale-100 ease-in duration-300"
                      >
                        <CameraAltIcon fontSize="large" className="text-grey" />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:w-[48%] lg:w-[48%] my-2 md:my-0 lg:my-0 flex flex-col h-full justify-between">
            <div className="w-full h-full ">
              {auth.getUserRole() === "admin" && (
                <div className="w-full md:w-[100%] lg:w-[100%] my-2 md:my-0 lg:my-3">
                  <label htmlFor="Category" className="text-[14px] pl-3">Organizer</label>
                  <FormControl
                    sx={{
                      // m: 1,
                      width: "100%",
                      // backgroundColor: colors.lightGrey,
                      borderRadius: "10px",
                      padding: 0,
                    }}
                    variant="outlined"
                  >
                    <Select
                      required
                      className="eventCategory-select"
                      sx={{
                        width: "100%",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        "& .MuiInputBase-root": {},
                      }}
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      // multiple
                      value={eventOrganizers}
                      onChange={handleOrganizersChange}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: {
                              xs: 150,
                            },
                            width: 250,
                          },
                        },
                      }}
                    >
                      {allOrganizersData.map((organizer: OrganizerTypes) => (
                        <MenuItem key={organizer._id} value={organizer._id}>
                          {organizer?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div className="w-full md:w-[100%] lg:w-[100%] my-2 md:my-0 lg:my-3">
                <label htmlFor="Location" className="text-[14px] pl-3">Location</label>
                <GooglePlacesAutocomplete
                  apiOptions={{ language: "en", region: "gh" }}
                  selectProps={{
                    locationResult,
                    onChange: setLocationResult,
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: 'gh',
                    },
                  }}
                />
              </div>
              <div className="w-full md:w-[100%] lg:w-[100%] my-2 md:my-3 lg:my-3 ">
                <label htmlFor="Price" className="text-[14px] pl-3">Duration/hours</label>
                <div className="w-full rounded-lg outline-none border-darkGrey3 border flex">
                  <input
                    type="number"
                    required
                    placeholder="Duration eg. 7"
                    id="duration"
                    name="duration"
                    autoComplete="duration"
                    value={createEventFormData.duration}
                    onChange={handleCreateEventFormDataChange}
                    className="w-full rounded-lg p-3 outline-none bg-transparent h-[100%]"
                  ></input>
                </div>
              </div>
              <div className="my-3">
                <label htmlFor="Description" className="text-[14px] pl-3">Description</label>
                <div className="w-full rounded-lg outline-none border-darkGrey3 border flex">
                  <textarea
                    rows={4}
                    required
                    placeholder="Description"
                    id="description"
                    name="description"
                    autoComplete="description"
                    value={createEventFormData.description}
                    onChange={handleCreateEventFormDataChange}
                    className="w-full rounded-lg p-3 outline-none bg-transparent h-[100%]"
                  ></textarea>
                </div>
              </div>
              <div
                className={
                  auth.getUserRole() === "admin"
                    ? "justify-end  w-full mt-12 h-14 flex items-end"
                    : "justify-end  w-full  h-14 mt-[6.1rem] flex items-end"
                }
              >
                <button
                  onClick={() => {
                    dispatch(OrganizersAction.handleClose());
                  }}
                  className="w-[25%] rounded-md  h-[55%] border-darkGrey3 border mr-4 bg-[#F2F2F5]"
                >
                  Cancel
                </button>
                {loading ? (
                  <MoonLoader color={colors.appLightGreen} size={25} />
                ) : (
                  <>
                    <button
                      type="submit"
                      className="w-[25%] rounded-md text-white   h-[55%] bg-searchButtonBlue"
                    >
                      Save
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditEventForm;
