import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: {},
  },
  reducers: {
    getUserDetails(state, action: PayloadAction<any>) {
      state.userData = action.payload?.user;
    },
  },
});

export const { getUserDetails } = userSlice.actions;

export default userSlice.reducer;
