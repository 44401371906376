import { useNavigate,useParams } from "react-router-dom";
import IconLabel from "../labels/IconLabel";
import { EventCardProps } from "../../types/components/eventCard";
import moment from "moment";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import CardAction from "../menu/card/CardAction";
import {useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { EVentDataProps } from "../../types/store/event";
import { EventDetailsType, } from "../../types/eventTypes";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import { getEditedItems } from "../../services/redux/global/globalSlice";
import { getItemId } from "../../services/redux/global/globalSlice";
import { TbMapPin } from "react-icons/tb";
import { BiHeart } from "react-icons/bi";

const EventCard = (props: EventCardProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { itemId } = useSelector(
    (state: any) => state.appGlobal
  );
  
  
  return (
    <div
      className="flex  md:min-w-1/2 lg:w-1/4
      h-full md:h-[268px] lg:h-[268px] rounded-xl shadow  cursor-pointer
      scale-95 
      hover:scale-100 ease-in duration-300
       ">
      <div className="bg-white min-w-[100%] rounded-xl">
        <div
          style={{ backgroundImage: `url(${props.image})` }}
          onClick={() => {
            navigate(`/event/${props._id}`);
          }}
          className={`bg-cover h-[55vh] md:h-[148px] lg:h-[148px] w-full bg-whiteGrey  rounded-t-xl`}></div>
        <div className="p-3 flex  flex-col">
          <div className="text-DarkGray1 w-full h-[30px] flex items-center justify-between ">
            <div
              className="w-full h-full"
              onClick={() => {
                navigate(`/event/${props._id}`);
              }}>
              {" "}
              {moment(props.date).format("LL")}
            </div>
            <div className=" ml-2">
              <CardAction
                View="View"
                Activate="Edit"
                Remove="Remove"
                onClickView={() => {
                  navigate(`/event/${props._id}`);
                }}
                onClickActivate={() => {
                  dispatch(getItemId(props.id));
                  dispatch(getEditedItems(props.eventItem));
                  dispatch(OrganizersAction.handEditForm());
                }}
                onClickRemove={() => {
                  dispatch(getItemId(props.id));
                  dispatch(OrganizersAction.handleDialogeOpen());
                }}
              />
            </div>
          </div>
          <div
            className=""
            onClick={() => {
              navigate(`/event/${props._id}`);
            }}>
            <div className="font-bold my-2  truncate">{props.title}</div>
            <div className="flex w-full justify-between items-center">
              <div className="truncate">
                <IconLabel
                  label={props?.city}
                  icon={
                    <TbMapPin fontSize={20} className="text-searchButtonBlue" />
                  }
                />
              </div>
              <div className="relative flex items-center pl-2 pr-2 rounded font-medium">
                <div className="absolute inset-0 bg-[#E5F0FF] opacity-40 rounded" />
                <BiHeart fontSize={20} color="#0C59F9" className="font-bold" />
                <span className="ml-2 font-bold text-[#0C59F9]">
                  {props.totalLikes}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
