import MainLayout from "../../components/Layout/MainLayout";
import OrganizersList from "../../components/organization/OrganizersList";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import OrganizersForm from "../../components/organization/OrganizersForm/OrganizerForm";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import { useSelector, useDispatch } from "react-redux";
import EditOrganizersForm from "../../components/organization/OrganizersForm/EditOrganizersform";
import * as auth from "../../services/auth";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";
import ActionButton from "../../components/button/ActionButton";
import * as colors from "../.././constants/colors/colors";
import {searchOrganizationAdmin} from '../../services/redux/admin/index'
import { useEffect } from "react";
import { useFormik } from "formik";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
};

const Organizers = () => {


  const ShowEdit = useSelector((state: any) => state.organizer.ShowEditform);
  const Showform = useSelector((state: any) => state.organizer.Showform);
  const openModal = useSelector((state: any) => state.organizer.openModal);
  const { UnVerifiedOrganization } = useSelector(
    (state: any) => state.organizer
  );
  const { adminSearchOrganization } = useSelector(
    (state: any) => state.adminVerifications
  );
  const VerifiedOrganization = useSelector(
    (state: any) => state.organizer.VerifiedOrganization
  );
  const dispatch = useDispatch();

  const onhandleOpen = () => {
    dispatch(OrganizersAction.handleOpen());
  };

  const HandleClose = () => {
    dispatch(OrganizersAction.handleClose());
  };


  const unVerifiedStyle =
    UnVerifiedOrganization === true ? "font-bold border-b-2 border-b-blue" : "";
  const VerifiedStyle =
    VerifiedOrganization === true ? "font-bold border-b-2 border-b-blue" : "";
 
    const formik = useFormik({
      initialValues:{
        title: "",
      },
      onSubmit:async(values)=>{

      }

    })
    useEffect(()=>{
      dispatch(searchOrganizationAdmin(formik.values.title))
    },[])
     const organizationData = formik.values.title === '' ?'' : '' 

  return (
    <MainLayout>
      <div className="w-full h-[6rem] mt-4  flex justify-between items-start">
        <div className="w-[70%] h-full flex-col flex justify-between">
          <form
          
            className=" w-[40%] h-[5rem] items-center  flex rounded-lg px-2 bg-[#E5F0FF] mb-2">
            <div className="w-[10%] h-full flex items-center justify-center">
              <SearchIcon />
            </div>
            <input
            id="title"
            name="title"
              type="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(searchOrganizationAdmin(e.target?.value))

                formik.setFieldValue("title", e.target?.value);
              }}
              value={formik.values.title}
              className="w-[70%] h-fill outline-none bg-transparent"
              placeholder="Search"
            />
            {/* <button className="w-[20%] flex items-center justify-center h-[80%] text-white bg-blue rounded-md">
              Search
            </button> */}
          </form>
          <div className="w-[19%] cursor-pointer  h-full  justify-between items-center flex mb-4">
            {auth?.getUserRole() === "admin" ? (
              <>
                <p
                  onClick={() => {
                    dispatch(OrganizersAction.handleVerifiedOpen());
                  }}
                  className={VerifiedStyle}>
                  Verified
                </p>
                <p
                  onClick={() => {
                    dispatch(OrganizersAction.handleUnVerifiedOpen());
                  }}
                  className={unVerifiedStyle}>
                  Unverified
                </p>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <ActionButton
          label={
            auth?.getUserRole() === "admin"
              ? "Create Organization"
              : "Add Organizers"
          }
          backgroundColor={colors.searchButtonBlue}
          // icon={<AddCircleIcon />}
          onClick={() => {
            onhandleOpen();
          }}
        />
      </div>
      <Modal
        open={openModal}
        onClose={HandleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style, width: auth?.getUserRole() === "admin"?730:500}}>
          {Showform && <OrganizersForm />}

          {ShowEdit && <EditOrganizersForm />}
        </Box>
      </Modal>
      <div className="w-full   flex justify-center">
        <OrganizersList values= {formik.values.title} adminSearchOrganization={adminSearchOrganization} />
      </div>
    </MainLayout>
  );
};

export default Organizers;
