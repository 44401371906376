import VerifiedOrganizationCard from "../menu/card/OrganizationCard/VerifiedOrganizationCard";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllAdminOrganizationEvent } from "../../services/redux/admin/index";
const 

OrganizationTableCard = (props:any) => {
  const dispatch = useDispatch();
    const Title = { Image: "Image", Name: "Name", Email: "Email" };
  return (
    <div className="w-full flex h-[60px]  hover:bg-[#E5F0FF] text-[#555770] bg-[#FFFFFF] mt-[0.125rem] px-4 gap-2">
      <Link
        to={`/organizers-detail/${props.id}`}
        onClick={() => {
          dispatch(fetchAllAdminOrganizationEvent(props.id));
        }}
        className="w-[100%] flex h-full">
        <div className="w-[20%] ml-1 flex items-center h-full">
          <div className="w-[28%] flex items-center h-full">
            <img
              src={props.image}
              alt=""
              className="w-[2.5rem] h-[2.5rem]  bg-light-grey rounded-full object-cover object-top"
            />
          </div>
          <div className="w-[72%] overflow-hidden flex flex-col justify-center pl-1 h-full">
            <p className="text-[15px] truncate">{props.organization}</p>
            <p className="text-[12px] truncate text-grey">{props.email}</p>
          </div>
        </div>
        <div className="w-[20%] text-[15px] h-full flex overflow-hidden text-ellipsis pl-4 items-center ">
          {props.name}
        </div>
        <div className="w-[20%] text-[15px] flex pl-4 items-center h-full">
          {props.phone}
        </div>
        <div className="w-[20%] flex pl-4 items-center h-full text-[15px] ">
          {" "}
          organization
        </div>

        <div className="w-[20%] text-[15px] h-full flex overflow-hidden text-ellipsis pl-4 items-center ">
          {props.location}
        </div>

        <div className="w-[20%] h-full flex pl-4 justify-between items-center  ">
          <p
            className="w-[5.5rem] h-[2rem] flex items-center justify-center font-['Inter'] rounded-full text-[#555770] 
        text-[15px] font-normal bg-[#E3FFF1] border border-[#06C270]">
            Verified
          </p>
        </div>
      </Link>
      <div className="flex items-center justify-end  h-full">
        <VerifiedOrganizationCard
          View="View"
          Activate="Edit"
          Remove="Remove"
          onClickView={props.Navigatefunction}
          onClickActivate={props.EditOrganizerfunction}
          onClickRemove={props.DeleteOrganizer}
        />
      </div>
    </div>
  );
};

export default OrganizationTableCard;
