import * as endpoint from "../../../services/endpoints";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Api from "../../../services/api";
import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import UpdateImage from "../../images/UpdateImage";
import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { OrganizersAction } from "../../../services/redux/Organizer/organizerSlice";
import { useDispatch } from "react-redux";
import { fetchOrganizer } from "../../../services/redux/Organizer/index";
import { useRef } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import {
  getLocationDetails,
  getLocationLogLtd,
} from "../../../services/redux/global/globalSlice";
import { CategoryDataPropsTypes } from "../../../types/store/category";
const EditOrganizationProfile = () => {
  const navigate = useNavigate();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const data = useSelector((state: any) => state.organizer.data);
  const [loading, setLoading] = useState(false);
  const updatedItem = useSelector((state: any) => state.organizer.updatedItem);

  const [locationResult, setLocationResult] = useState<any>(null);

  const { locationsDetails } = useSelector(
    (state: CategoryDataPropsTypes) => state.appGlobal
  );

  useEffect(() => {
    dispatch(fetchOrganizer());
  }, [dispatch]);

  const onCloseHandler = () => {
    dispatch(OrganizersAction.handleClose());
    dispatch(fetchOrganizer());
  };

  useEffect(() => {
    geocodeByAddress(locationResult?.label ?? "Takoradi, Ghana")
      .then((results) => {
        dispatch(
          getLocationDetails({
            placeID: results[0]?.place_id,
            placeName: results[0]?.formatted_address,
            city: results[0]?.address_components[0].long_name,
          })
        );
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        dispatch(
          getLocationLogLtd({
            lat,
            lng,
          })
        );
      });
  }, [locationResult]);
  const Avatar = "/image/user.png";

  const formik = useFormik({
    initialValues: {
      name: updatedItem.name,
      organization: updatedItem.organization,
      email: updatedItem.email,
      description: updatedItem.description,
      image: updatedItem.image,
      id: updatedItem.id,
      contactPhone: updatedItem.phone,
    },
    onSubmit: async (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("contactName", values.name);
      formData.append("name", values.organization);
      formData.append("contactEmail", values.email);
      formData.append("contactPhone", values.contactPhone);
      formData.append("image", values.image == "" ? Avatar : values.image);
      formData.append("description", values.description);
      formData.append("location", JSON.stringify(locationsDetails));

      try {
        const res = await Api({
          method: "PATCH",
          url: endpoint.createGetAllOrganizers + `/${values.id}`,
          headers: {
            "content-type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
          data: formData,
        });

        if (res.data) {
          setLoading(false);
          onCloseHandler();
          enqueueSnackbar(`${updatedItem.name} Updated successfully`, {
            variant: "success",
          });
          setTimeout(() => {
            dispatch(fetchOrganizer());
            
          }, 1000);
          formik.resetForm();
        } else {
        }
      } catch (error) {
        console.dir(error);
      }
    },
  });

  return (
    <div className="w-full flex flex-col h-full">
      <div className="w-full md:h-[100%] flex items-center outline-none justify-center ">
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col h-full"
        >
          <div className="w-full flex justify-between h-[5%] font-bold text-[16px] ">
            Edit Organization
            <CloseIcon onClick={onCloseHandler} />
          </div>
          <div className="w-full flex justify-between parent h-[85%]">
            <div className="w-[48%] flex flex-col h-full">
              <label className=" text-[14px] mt-6 pl-3 mb-2">
                Organization name
              </label>
              <input
                type="text"
                required
                placeholder="Type organization's name"
                id="organization"
                name="organization"
                autoComplete="organization"
                autoFocus
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("organization", e.target.value);
                }}
                value={formik.values.organization}
                onBlur={formik.handleBlur}
                className="w-full min-h-[10.5%] text-[15px] rounded-md text-DarkGray5 p-2 border border-darkGrey3 outline-none "
              ></input>
              <label className="text-[14px]  mt-6 pl-3 mb-2 ">
                Contact email
              </label>
              <input
                type="text"
                required
                placeholder="Type contact email"
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("email", e.target.value);
                }}
                className="w-full min-h-[10.5%] text-[14px] rounded-md text-DarkGray5 p-3  border border-darkGrey3 outline-none "
              ></input>
              <label className="text-[14px] mt-6 pl-3 mb-2 ">
                Contact phone
              </label>
              <input
                type="tel"
                required
                placeholder="Type contact phone"
                id="contactPhone"
                name="contactPhone"
                autoComplete="contactPhone"
                autoFocus
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("contactPhone", e.target.value);
                }}
                value={formik.values.contactPhone}
                onBlur={formik.handleBlur}
                className="w-full min-h-[10.5%] text-[14px] rounded-md text-DarkGray5 p-3  border border-darkGrey3 outline-none "
              ></input>
              {/* <div className="w-full md:w-[100%]  lg:w-[100%] h-[10.5%] my-2 md:my-0 lg:my-0 "> */}
              <label htmlFor="Location" className="mt-6 pl-3 mb-2 text-[14px]">
                Location
              </label>{" "}
              <GooglePlacesAutocomplete
                selectProps={{
                  locationResult,
                  onChange: setLocationResult,
                }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: 'gh',
                  },
                }}
              />
            </div>
            {/* </div> */}
            <div className="w-[48%] flex flex-col  h-full">
              <label className="mt-6 pl-3 mb-2 text-[14px] ">
                Contact name
              </label>

              <input
                type="text"
                required
                placeholder="Type contact name"
                id="name"
                name="name"
                autoComplete="name"
                autoFocus
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("name", e.target.value);
                }}
                className="w-full min-h-[10.5%] text-[14px] rounded-md text-DarkGray5 p-3 border border-darkGrey3 outline-none "
              ></input>

              <label className=" mt-6 pl-3 mb-2 text-[14px] ">
                Organization logo{" "}
              </label>
              <div className="w-full min-h-[25%] relative border border-darkGrey3 flex justify-center  items-center rounded-md text-DarkGray5 ">
                <UpdateImage
                  File={formik?.values.image}
                  Image={updatedItem.image}
                  className="h-[100%] w-full rounded-md object-top  object-cover"
                  MainStyle='w-full h-[7rem]'
                />
                <IconButton
                  sx={{
                    color: "grey",
                    position: "absolute",
                    background: "tranparent",
                  }}
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    name="image"
                    accept="image/*"
                    type="file"
                    onBlur={formik.handleBlur}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (!e.target.files) return;
                      formik.setFieldValue("image", e.target?.files[0]);
                    }}
                  />
                  <PhotoCamera />
                </IconButton>
              </div>
              <label className="mt-4 pl-3 mb-2 text-[14px] ">Description</label>
              <textarea
                ref={textareaRef}
                required
                placeholder="Type description"
                id="description"
                name="description"
                autoComplete="description"
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  formik.setFieldValue("description", e.target.value);
                }}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                className="w-full min-h-[25%] text-[14px] rounded-md text-DarkGray5 p-3  border border-darkGrey3 outline-none "
              ></textarea>
            </div>
          </div>
          <div className="w-full h-[10%] flex justify-end items-end ">
            <button
              onClick={onCloseHandler}
              className="w-[14%] rounded-lg h-[60%] border mr-4 border-darkGrey3 bg-[#F2F2F5] "
            >
              Cancel
            </button>
            {loading ? (
              <MoonLoader color="blue" size={25} />
            ) : (
              <>
                <button
                  type="submit"
                  className="w-[14%] rounded-lg h-[60%] text-white hover:bg-primary bg-blue f"
                >
                  Save
                </button>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
    // <div className="">JISKDJOSDOJODSFJ</div>
  );
};

export default EditOrganizationProfile;

