import { useState } from "react";
import { HiOutlineFilter } from "react-icons/hi";
import { FaChevronDown } from "react-icons/fa";
import { Category } from "../../types/categoryTypes";

interface CategoryFilterProps {
  categories: Category[];
  selectedCategories: Category[];
  setSelectedCategories: (categoryId: Category[]) => void;
}

const CategoryFilter = ({
  categories,
  selectedCategories,
  setSelectedCategories,
}: CategoryFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const handleCategorySelection = (category: Category) => {
    setSelectedCategories([category]); 
    setIsOpen(false); 
  };
  const handleReset = () => {
    setSelectedCategories([]); // set selectedCategories to an empty array
    setIsOpen(!isOpen)
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-[120%] h-[] rounded-md border-[2px] border-[#C7C9D9] shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={toggleDropdown}>
          <HiOutlineFilter
            className="-ml-1 mr-2 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span>
            {selectedCategories.length > 0
              ? `${selectedCategories.map((c) => c.name).join(", ")}`
              : "Filter"}
          </span>
          <FaChevronDown
            className="ml-2 -mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56  z-10 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu">
            {/* Reset button */}
            <button
              onClick={handleReset}
              className="text-gray-700 block px-4 py-2 text-sm leading-5 w-full text-left"
              role="menuitem">
              All
            </button>

            {/* Existing categories buttons */}
            {categories.map((category) => (
              <button
                key={category._id}
                onClick={() => handleCategorySelection(category)}
                className={`${
                  selectedCategories.some((c) => c._id === category._id)
                    ? "bg-gray-100 text-gray-900"
                    : "text-gray-700"
                } block px-4 py-2 text-sm leading-5 w-full text-left`}
                role="menuitem">
                {category.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryFilter;
