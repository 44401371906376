import * as Yup from "yup";

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      "Email must be a valid one"
    )
    .required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum."),
});

// const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const OrganizersSignUpSchema = Yup.object().shape({
  contactName: Yup.string()
    .min(2, "Too Short!")
    .max(250, "Too Long!")
    .required("Required"),
    contactEmail: Yup.string()
    .email("Invalid email")
    .matches(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      "Email must be a valid one"
    )
    .required("Required"),
  // 
  description: Yup.string()
  .min(10, "Too Short!")
  .max(5000, "Too Long!")
  .required("Required"),
  contactPhone:Yup.string()
  .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
  .matches(/^0[0-9]{9}$/, 'Phone number must start with 0')
  .matches(/^0[2357][0-9]{8}$/, 'Invalid Ghana phone number')
  .required('Required'),
  name: Yup.string()
    .min(5, "Organization name is short!")
    .max(100, "Organization name is Long!")
    .required("Required"),
  
  image: Yup.mixed()

   
});

const CreateEventSchema = Yup.object().shape({
  title: Yup.string()
    .min(2, "Too Short!")
    .max(100, "Too Long!")
    .required("Required"),
  description: Yup.string()
    .min(2, "Too Short!")
    .max(500, "Too Long!")
    .required("Required"),
  // category: Yup.array()
  //   .min(2, "Too Short!")
  //   .max(50, "Too Long!"),
  // .required("Required"),
  date: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  endDate: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
    // .required("Required"),
  startTime: Yup.string()
    .min(1, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  duration: Yup.number()
    .min(1, "Value must be greater than or equal to 1")
    .max(100, "Value must be less than or equal to 100")
    .required("Required"),
  // .required("Required"),
  // organizers: Yup.string()
  //   .min(2, "Too Short!")
  //   .max(50, "Too Long!")
  //   .required("Required"),
  // image: Yup.mixed().required("Required"),
  featured: Yup.bool(),
  isRecurring: Yup.bool(),
  rrule: Yup.string(),
});

const changeOrgpassword=Yup.object().shape({
  oldPassword:Yup.string().required("Required").min(8,'Too short'),
  newPassword: Yup.string().required("Required").min(8,'Too short'),
  repeatPassword:Yup.string().required("Required").min(8,'Too short'),

})
export { LoginSchema, SignUpSchema, OrganizersSignUpSchema, CreateEventSchema,changeOrgpassword };
