import { useState, useEffect } from "react";
import * as auth from "../../../services/auth";
import EditMembersProfile from "./EditMemberProfile";
import EditOrganizationProfile from "./EditOrganizationProfile";

const EditOrganizersForm = () => {
  const [showform, setshowform] = useState(false);
  const [showMemberform, setshowMemberform] = useState(true);
  useEffect(() => {
    if (auth?.getUserRole() === "admin") {
      setshowform(true);
      setshowMemberform(false);
   
    } else {
      setshowform(false);
      setshowMemberform(true);
 
    }
  }, []);

  return (
    <div className="w-full flex p-5 justify-center items-center  md:h-[36rem] ">

      {showMemberform && <EditMembersProfile />}
     { showform && <EditOrganizationProfile />}
    </div>
  );
};

export default EditOrganizersForm;
