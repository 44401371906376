import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { fetchOrganizer } from "../../services/redux/Organizer/index";
import NoDataImage from "../../components/Layout/content/NoData";
import { deleteOrganizer } from "../../services/redux/Organizer/index";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import { useNavigate } from "react-router-dom";
import * as auth from "../../services/auth";
import { useSnackbar } from "notistack";
import OrganizersCard from "../card/OrganizationCard";
import MembersCard from "../card/MemberCard";
import AlertDialog from "../../components/notification/Dialog";
import { getItemEvent } from "../../services/redux/eventOrganizers/eventOrganizerSlice";
import { deleteOrganizerItem } from "../../services/redux/eventOrganizers/index";
import { getItemId } from "../../services/redux/global/globalSlice";
import { fetchAllAdminOrganizationEvent } from "../../services/redux/admin/index";
import UnverifiedOrganizationList from "../../pages/Organizers/UnverifiedOrganizationList";
import OrganizationTableCard from "../card/OrganizationTableCard";
// import Search from "../search/search";
const OrganizersList = (props:any) => {
  const OrganizersList = useSelector((state: any) => state.organizer.data);
   
  const { itemId } = useSelector((state: any) => state.appGlobal);


  const OrganizerData = props.values === ''? OrganizersList : props.adminSearchOrganization
   
  const OrganizersItem = OrganizersList.find((item: any) => item.id === itemId);
  const openDialoge = useSelector((state: any) => state.organizer.openDialoge);
  const { UnVerifiedOrganization } = useSelector(
    (state: any) => state.organizer
  );
  const VerifiedOrganization = useSelector(
    (state: any) => state.organizer.VerifiedOrganization
  );

  
  const dispatch = useDispatch();
  const [showAction, setShowAction] = useState(false);
  const navigate = useNavigate();
  const [OrganizersList1, setOrganizersList1]: any = useState({
    activeObject: null,
    object: OrganizersList,
  });

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("loaded");
  const { enqueueSnackbar } = useSnackbar();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(OrganizersAction.handleDialogeClose());
    setStatus("loaded");
  };

  const toggler = (index: number, id: string) => {
    setOrganizersList1({
      ...OrganizersList1,
      activeObject: OrganizersList1.object[index],
    });
    setShowAction(true);
  };
  useEffect(() => {
    dispatch(fetchOrganizer());
    dispatch(deleteOrganizer());
    dispatch(fetchAllAdminOrganizationEvent());
  }, []);

  const toggleClass = (index: number) => {
    if (OrganizersList1.object[index] === OrganizersList1.activeObject) {
      return "bg-white py-[20px] flex flex-col justify-evenly items-center w-[28%] top-[40px] rounded-lg right-[50px] shadow-2xl absolute h-[58%] active";
    } else {
      return "inActive";
    }
  };

  const toggleMemberClass = (index: number) => {
    if (OrganizersList1.object[index] === OrganizersList1.activeObject) {
      return "bg-white py-[6px] bg-orange flex flex-col justify-evenly items-center w-[28%] top-[40px] rounded-lg right-[50px] shadow-2xl absolute h-[100%] active";
    } else {
      return "inActive";
    }
  };
  
  const handleSubmitAction = async () => {
    dispatch(deleteOrganizer(OrganizersItem.id)).then(() => {
      if (status && OrganizersItem?.role === 'organization-admin' ) {
        auth.logoutUser()
        handleClose();
        enqueueSnackbar(`${OrganizersItem?.name} deleted successfully`, { variant: "error" });
      }else if(status){
        handleClose();
        enqueueSnackbar(`${OrganizersItem?.name} deleted successfully`, { variant: "error" });
        setTimeout(() => {
          dispatch(fetchOrganizer());
        }, 1000)
      }
    });
  };
  const EditOrganizerHandler = (id: any) => {
    dispatch(OrganizersAction.handEditForm());
    dispatch(OrganizersAction.EditOrganizer(id));
    dispatch(getItemEvent(id));
  };
  const DeleteOrganizerHandler = (id: any) => {
    dispatch(OrganizersAction.handleDialogeOpen());
    dispatch(getItemId(id));
  };
  const Title = {
    Image: "Image",
    Name: "Name",
    Email: "Email",
    role: "Role",
  };
  const Titles = {
    Image: "Name",
    Name: "Administrator",
    Contact: "Contact",
    role: "Role",
    location: "Location",
    status: "Status",
  };
  return (
    <div className="w-full">
      <AlertDialog
        open={openDialoge}
        status={status}
        handleClickOpen={() => {
          dispatch(OrganizersAction.handleOpen());
        }}
        handleClose={() => {
          dispatch(OrganizersAction.handleDialogeClose());
        }}
        handleSubmitAction={() => {
          handleSubmitAction();
        }}
        title="Confirm Delete"
        subTitle={
          OrganizersItem?.role === "organization-admin"
            ? "Are you sure you want to delete this Organization? After deleting, your account will be deleted"
            : "Are you sure you want to delete this Organization?"
        }
      />
      {OrganizerData.length === 0 ? (
        <NoDataImage
          message={
            auth?.getUserRole() === "admin"
              ? "No Organization Available"
              : "No organizers Available"
          }
        />
      ) : auth?.getUserRole() === "admin" ? (
        <div className="">
          {UnVerifiedOrganization && <UnverifiedOrganizationList />}

          {VerifiedOrganization && (
            <div className="w-full h-[76vh]">
              <div className="w-full h-[55px] pl-4 text-[#FFFFFF] flex bg-[#3E7BFA]">
                <div className="w-[20%] h-full flex pl-3 items-center">
                  {Titles.Image}
                </div>
                <div className="w-[20%] h-full flex pl-3 items-center">
                  {Titles.Name}
                </div>
                <div className="w-[20%] h-full flex  items-center">
                  {Titles.Contact}
                </div>
                <div className="w-[20%] h-full flex items-center">
                  {Titles.role}
                </div>
                <div className="w-[20%] h-full flex items-center">
                  {Titles.location}
                </div>
                <div className="w-[20%] h-full flex mr-4 items-center">
                  {Titles.status}
                </div>
              </div>
              <div className="w-full h-[88%] parent">
                {OrganizerData?.map((item: any, index: number) => {
                  return (
                    <OrganizationTableCard
                      image={item.image}
                      name={item.name}
                      email={item.email}
                      organization={item.organization}
                      phone={item.phone}
                      location={item.location?.placeName}
                      role={item.role}
                      id={item.id}
                      Navigatefunction={() => {
                        dispatch(fetchAllAdminOrganizationEvent(item.id));
                        navigate(`/organizers-detail/${item.id}`);
                      }}
                      EditOrganizerfunction={() => {
                        EditOrganizerHandler(item.id);
                      }}
                      DeleteOrganizer={() => {
                        DeleteOrganizerHandler(item.id);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-[100%] h-[78vh] parent ">
          <div className="w-full h-[68px] pl-5 text-[#FFFFFF] bg-[#3E7BFA] flex  ">
            <div className="w-[10%] h-full flex pl-3 items-center">
              {" "}
              {Title.Image}
            </div>
            <div className="w-[30%] h-full flex pl-3 items-center">
              {Title.Name}
            </div>
            <div className="w-[32%] h-full  flex pl-3 items-center">
              {Title.Email}
            </div>
            <div className="w-[20%] h-full flex mr-4 items-center">
              {Title.role}
            </div>
          </div>

          <div className="w-full h-[88%]">
            {OrganizerData.map((item: any, index: number) => {
              return (
                <MembersCard
                  handleSubmitAction={() => {
                    handleSubmitAction();
                  }}
                  ViewAction={() => {
                    setShowAction(false);
                  }}
                  id={item.id}
                  open={open}
                  handleClose={handleClose}
                  handleClickOpen={handleClickOpen}
                  status={status}
                  index={index}
                  image={item.image}
                  name={item.name}
                  email={item.email}
                  role={item?.role}
                  togglerfunction={() => {
                    toggler(index, item._id);
                  }}
                  showAction={showAction}
                  Navigatefunction={() => {
                    navigate(`/organization-detail/${item._id}`);
                  }}
                  EditOrganizerfunction={() => {
                    EditOrganizerHandler(item._id);
                  }}
                  DeleteOrganizer={() => {
                    DeleteOrganizerHandler(item._id);
                  }}
                  toggleClass={toggleMemberClass(index)}
                  eventItem={item}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganizersList;
