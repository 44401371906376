import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GET_ALL_EVENT,
  CREATE_EVENT,
  DELETE_EVENT,
  UPDATE_EVENT,
  GET_ALL_ORGANIZER_EVENT,
  GET_ALL_LANDINGPAGE_EVENT,
  SEARCH_EVENT,
  SEARCH_ORGANIZER_EVENT,
} from "./actions";
import {
  fetchCreateUpdateDeleteGetEvent,
  fetchOrganizerEVent,
  fetchOrganizationEvent,
  getAllAdminOrganizersEvent,
  

} from "../../endpoints";
import Api from "../../api";
import * as auth from "../../auth";
export const fetchEvent: any = createAsyncThunk(GET_ALL_EVENT, async () => {

 
  try {
    const response = await Api({
      method: "GET",
      url:
        auth.getUserRole() === "admin"
          ? fetchCreateUpdateDeleteGetEvent 
          : fetchOrganizationEvent,
    });

    
    return response?.data?.data;
  } catch (error) {
    console.dir(error);
  }
});

export const createEvent: any = createAsyncThunk(CREATE_EVENT, async (data) => {
  try {
    const response = await Api({
      data,
      method: "POST",
      url: fetchCreateUpdateDeleteGetEvent + "/",
    });
    await fetchEvent();
    return response?.status;
  } catch (error) {
    console.dir(error);
  }
});
export const updateEvent: any = createAsyncThunk(
  UPDATE_EVENT,
  async (data: any) => {
    try {
      const response = await Api({
        data,
        method: "PATCH",
        url: fetchCreateUpdateDeleteGetEvent + "/" + data._id,
      });
      await fetchEvent();
      return response?.status;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const deletingEvent: any = createAsyncThunk(DELETE_EVENT, async (id) => {
  try {
    const response = await Api({
      method: "DELETE",
      url: fetchCreateUpdateDeleteGetEvent + "/" + id,
    });
    await fetchEvent();
    return response?.status;
  } catch (error) {
    console.dir(error);
  }
});

export const fetchOrganizersEvent: any = createAsyncThunk(
  GET_ALL_ORGANIZER_EVENT,
  async (id) => {
    try {
      const response = await Api({
        method: "GET",
        url: getAllAdminOrganizersEvent + `/${id}`,
      });

      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);
export const fetchLandingPageEvent: any = createAsyncThunk(
  GET_ALL_LANDINGPAGE_EVENT,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url: "/events/search",
      });
      // console.log(response?.data);
      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const searchAdminEvents: any = createAsyncThunk(
  SEARCH_EVENT,
  async (data: any) => {
    try {
      const response = await Api({
        method: "GET",
        url:
          auth.getUserRole() === "admin"
            ? fetchCreateUpdateDeleteGetEvent +
              `?title=${data.title}&category=${data.categoryId}`
            : fetchOrganizationEvent +
              `?title=${data.title}&category=${data.categoryId}`,
      });
      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);

// Search All Organizer Events`
export const SearchAllOrganizerEvent: any = createAsyncThunk(
  SEARCH_ORGANIZER_EVENT,
  async (value) => {
    try {
      const response = await Api({
        method: "GET",
        url:
          auth.getUserRole() === "organization-admin"
            ? fetchOrganizationEvent + `?title=${value}`
            : fetchOrganizerEVent + `?title=${value}`,
      });
      return response?.data?.data?.events;
    } catch (error) {
      console.dir(error);
    }
  }
);