const GET_ALL_EVENT_ORGANIZERS = "get_all_event_organizers";
const CREATE_EVENT_ORGANIZERS = "create_event_organizers";
const UPDATE_EVENT_ORGANIZERS = "update_event_organizers";
const DELETE_EVENT_ORGANIZERS = "delete_event_organizers";
const DELETE_ORGANIZERS = "delete_event_organizers";
const SEARCH_ADMIN_ORGANIZERS = "delete_event_organizers";


export {
  GET_ALL_EVENT_ORGANIZERS,
  CREATE_EVENT_ORGANIZERS,
  DELETE_EVENT_ORGANIZERS,
  UPDATE_EVENT_ORGANIZERS,
  DELETE_ORGANIZERS,
  SEARCH_ADMIN_ORGANIZERS
};
