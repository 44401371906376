/* * const apiUrl = process.env.REACT_APP_API
 */
 const apiUrl = "https://dev-eventplanner.amalitech-dev.net/api/v1";
const frontEndURL = `https://connect-dev.amalitech-dev.net/`;
// const apiUrl = 'http://localhost:3000/api/v1'

export const _config = {
  api: apiUrl,
  frontURL: frontEndURL,
};
