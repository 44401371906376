import { useParams } from "react-router-dom";
import MainLayout from "../../components/Layout/MainLayout";
import { useSelector,useDispatch } from "react-redux";
import { EVentDataProps } from "../../types/store/event";
import DetailsCard from "../../components/card/DetailsCard";
import * as colors from "../.././constants/colors/colors";
import { useNavigate } from "react-router-dom";
import {fetchAllAdminOrganizationEvent} from '../../services/redux/admin/index'
import {useEffect} from 'react'
const OrganizationDetail = () => {
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.organizer.data);
  const { id } = useParams();
  const detailItem = data.find((item: any) => item.id === id);
  const { allAdminOrganizationEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );

  
  // useEffect(()=>{
  // dispatch(fetchAllAdminOrganizationEvent())
  // },[])

  const { allOrganizersEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );
  const { allOrganizationEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );

  const navigate = useNavigate();

  return (
    <MainLayout>
      <div className="w-full h-[90vh] parent overflow-y-scroll gridStyle bg-white ">
        <div className=" gridStyleChild1 ">
          <img
            src="/Rectangle 18.png"
            alt="banner"
            className="w-full object-cover h-full bg-light-grey"
          />
        </div>
        <div className="bg-tranparent  gridStyleChild2">
          <div className="w-full h-full gridDetails">
            <div className="gridDetails1">
              <div className="w-full h-full flex items-center ">
                <div className="w-[14rem] ml-[80px]  rounded-full h-full ">
                  <img
                    src={detailItem?.image}
                    alt=""
                    className=" w-full object-cover object-top h-full rounded-full"
                  />
                </div>
                <p className="ml-8 mt-[59px] text-[25px] font-bold">
                  {detailItem?.name}
                </p>
              </div>
            </div>
            <div className="gridDetails2">
              <div className="w-full  h-full flex  justify-center items-end">
                <div className="w-[95%] bg flex h-[90%]">
                  <div className="w-[40%] h-full ">
                    <div className="w-full  text-grey h-[17%] ">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Organization name
                      </p>
                      <p className="text-[14px]"> {detailItem?.organization}</p>
                    </div>
                    <div className="w-full  text-grey h-[17%] ">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Contact Email
                      </p>
                      <p className="text-[14px]"> {detailItem?.email}</p>
                    </div>
                    <div className="w-full  text-grey h-[17%] ">
                      <p className="text-[16px] text-DarkGray1 font-semibold">
                        Contact phoneNumber
                      </p>
                      <p className="text-[14px]"> {detailItem?.phone}</p>
                    </div>
                    <p className="text-[16px] text-DarkGray1 font-semibold">
                      Description
                    </p>
                    <div className="w-full parent text-[16px] text-grey h-[39%] ">
                      <p className="text-[14px]"> {detailItem?.description}</p>
                    </div>
                  </div>
                  <div className="w-[60%]  text-grey h-full pr-10 ">
                    <div className=" w-full flex flex-col h-full">
                      <p className=" ml-10 text-DarkGray1  font-semibold ">
                        Current Events
                      </p>
                      <div className="w-full  items-center ml-11 flex h-[95%] parent ">
                        {allAdminOrganizationEvent?.length === 0 ? (
                          <div className="w-full flex flex-col justify-center items-center h-full">
                            <img
                              src="/image/no event 1.png "
                              className="w-[60%] object-cover h-[80%]"
                            ></img>
                            <p>No events Found</p>
                          </div>
                        ) : (
                          <div className="w-full flex h-full">
                            {allAdminOrganizationEvent?.map((item: any) => {
                              return (
                                <DetailsCard
                                  date={item.date}
                                  totalLikes={item.totalLikes}
                                  duration={item.duration}
                                  title={item?.title}
                                  location={item?.location?.placeName}
                                  time={item?.startTime ? item?.startTime : ""}
                                  image={item?.image}
                                  _id={item?._id}
                                  city={item?.location?.city}
                                />
                              );
                            })}
                          </div>
                        )}
                      </div>
                      <div className="w-full flex mb-3  ml-12 justify-end">
                        <button
                          style={{ backgroundColor: colors.searchButtonBlue }}
                          className="h-11 text-white mt-3  md:w-[30px] lg:w-[100px] w-[30%] 
                           rounded-xl px-4 flex items-center ml-4 justify-center shadow"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default OrganizationDetail;
