import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EVentDataProps } from "../../types/store/event";
import { CategoryDataPropsTypes } from "../../types/store/category";
import { fetchEvent } from "../../services/redux/event/index";
import { fetchCategory } from "../../services/redux/category/index";
import { fetchOrganizer } from "../../services/redux/Organizer/index";
import MainLayout from "../../components/Layout/MainLayout";
import ItemDetails from "../../components/dashboard/ItemDetails";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CategoryIcon from "@mui/icons-material/Category";
import useEventStat from "../../hooks/useEventStat";
import NoDataImage from "../../components/Layout/content/NoData";
// import CustomDateInputs from "../../components/date/DateRangePicker";
import { fetchAllEEventOrganizers } from "../../services/redux/eventOrganizers";
import DashBoradCard from "../../components/card/DashbordCard";
import { handleViewOrganizersList } from "../../services/redux/admin/adminVerificationSlice";
import OrganizersDashbord from "../../components/dashboard/OrganizersDasbord";
import Graph from "../../components/dashboard/Graph";
import { HiTicket, HiUserGroup } from "react-icons/hi2";
// import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import { useState } from "react";
function Dashboard() {
  const dispatch = useDispatch();
  const { data: eventData } = useSelector(
    (state: EVentDataProps) => state.event
  );
  const { data: categoryData } = useSelector(
    (state: CategoryDataPropsTypes) => state.category
  );
  const { allOrganizersData } = useSelector(
    (state: CategoryDataPropsTypes) => state.eventOrganizers
  );

  const { HideOrganizersList } = useSelector(
    (state: any) => state.adminVerifications
  );
  const { viewOrganizersList } = useSelector(
    (state: any) => state.adminVerifications
  );

  const { data: organizationList } = useSelector(
    (state: CategoryDataPropsTypes) => state.organizer
  );


  const currentMonth = new Date().getMonth();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const { data } = useEventStat(selectedMonth);
   const eventData1: {
    name: string;
    Events: any;
}[] | undefined = data?.data?.stats?.map((stat: any) => ({
    name: new Date(stat._id).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    }),
    Events: stat.count,
  }));



  useEffect(() => {
    dispatch(fetchEvent());
    dispatch(fetchCategory());
    dispatch(fetchOrganizer());
    dispatch(fetchAllEEventOrganizers());
  }, [dispatch]);
  const Title = {
    Image: "Image",
    Name: "Name",
    organization: "Organization",
    city: "City",
    event: "Number of Events",
  };

  return (
    <div>
      <MainLayout>
        {viewOrganizersList && <OrganizersDashbord />}
        {HideOrganizersList && (
          <div className="w-full parent p-6 h-[90vh] ">
            <div className="flex flex-col md:flex-col lg:flex-row items-center justify-between pb-3">
              <ItemDetails
              Tags={'organization'}
                title="Total Organization"
                value={organizationList?.length ?? 0}
                percentage={`${75}%`}
                icon={
                  <HiTicket
                    className="text-appGreen top-10 p-2 bg-[#06c27133] rounded-full"
                    size={36}
                  />
                }
              />
              <div className="mx-2"></div>
              <ItemDetails
              Tags={'organizer'}
                title="Total Organizer"
                percentage={`${55}%`}
                value={allOrganizersData?.length ?? 0}
                icon={
                  <HiUserGroup
                    className="text-[#3E7BFA] p-2 bg-[#3e79fa34] rounded-full"
                    size={36}
                  />
                }
              />
              <div className="mx-2"></div>
              
              <ItemDetails
               Tags={'categories'}
                percentage={`${35}%`}
                title="Total Category"
                value={categoryData?.length ? categoryData.length : 0}
                icon={
                  <CategoryIcon
                    className="text-[#FF3B3B] p-2 bg-[#ff3b3b2a] rounded-full"
                    sx={{ width: "37px", height: "37px" }}
                  />
                }
              />
              <div className="mx-2"></div>

              <ItemDetails
              Tags={'Events'}
                title="Total Event"
                percentage={`${35}%`}
                value={eventData?.length ? eventData.length : 0}
                icon={
                  <DateRangeIcon
                    className="text-[#28293D] p-2 bg-[#28293d2d] rounded-full"
                    sx={{ width: "37px", height: "37px" }}
                  />
                }
              />
            </div>
            

            {
            
            
            (allOrganizersData?.length === 0 && eventData1?.length === 0) ? (
               <NoDataImage message={'No event and Organizers found'}/>
                ) : (
            <div className="w-full h-[80%]">
              <div className="w-full h-[24rem] flex">
                <div className="w-[100%] bg-white pt-3  h-full">
                  <Graph eventData={eventData1} setSelectedMonth={setSelectedMonth} />
                </div>
              </div>
              
              <div className="w-full h-[21rem] mt-12 ">
                {allOrganizersData.length === 0 ?( <NoDataImage message={'No Organizers found'}/>):(
                  <div className="w-full h-full">
                  <div className="w-full h-[10%] flex items-center justify-between">
                  <p className="font-semibold ">Organizers</p>
                  <p
                    onClick={() => {
                      dispatch(handleViewOrganizersList());
                    }}
                    className="text-[#555770] text-[14px] cursor-pointer"
                  >
                    See all
                  </p>
                </div>
              
                  <div className="w-full h-[90%] ">
                    <div className="w-full h-[55px] text-[#FFFFFF] flex bg-[#3E7BFA] px-5">
                      <div className="w-[25%] text-[15px]  h-full  flex   items-center">
                        {Title.Name}
                      </div>
                      <div className="w-[25%] h-full  text-[15px]  flex justify-center  items-center">
                        {Title.organization}
                      </div>
                      <div className="w-[25%] h-full text-[15px]  flex justify-center items-center">
                        {Title.city}
                      </div>
                      <div className="w-[25%] h-full  text-[15px]  flex justify-center items-center">
                        {Title.event}
                      </div>
                    </div>
                    <div className="w-full parent h-[82%] ">
                      {allOrganizersData?.slice(0,3).map((item: any) => {
                        
                        return (
                          <DashBoradCard
                            image={item.image}
                            name={item.name}
                            organization={item.organization.name}
                            city={item.organization.location.city}
                            Status={item.isActive}
                            id={item._id}
                            createdEvents={item.createdEvents}
                          />
                        );
                      })}
                    </div>
                  </div>
                  </div>
                )
                }
                
              </div>
           
            </div>
               )}
          </div>
        )}
      </MainLayout>
    </div>
  );
}

export default Dashboard;
