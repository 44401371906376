import LandingPageFooter from "../../components/landingpage/LandingPageFooter";
import { HiChevronDown } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import * as routes from "../../constants/routes/routes";

const TermsOfUse = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full bg-white">
      <div className="flex items-center justify-between p-4 shadow-lg">
        <div
          onClick={() => {
            navigate(routes.landingPage);
          }}>
          <img src="/Frame 984.png" alt="" />
        </div>
        <div className="flex items-center">
          <div className="relative">
            <button className="flex items-center">
              Help <HiChevronDown className="ml-2" />
            </button>
          </div>
          <button
            className="mx-4 py-2 px-4 rounded"
            onClick={() => {
              navigate(routes.signIn);
            }}>
            Login
          </button>
          <button
            className="mx-4 py-2 px-4 rounded"
            onClick={() => {
              navigate(routes.signUp);
            }}>
            Sign up
          </button>
        </div>
      </div>

      <div className="h-[20.5rem] bg-[#E5F0FF] flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl font-bold ">Term of Use</h1>
        </div>
      </div>

      <div className="bg-white">
        <div className="mx-auto p-6">
          <h2 className="text-xl font-bold mb-2">Abonten Terms of Use</h2>
          <p>Last update May 10, 2023.</p>

          <h3 className="text-lg font-bold mt-6 mb-2">Acceptance of Terms</h3>
          <p>
            Users must agree to the terms of use before using the website or
            app.
          </p>

          <h3 className="text-lg font-bold mt-6 mb-2">User Conduct</h3>
          <p>
            Users must agree to use the website or app in a lawful and
            appropriate manner and not engage in any behaviour that is abusive,
            harassing, or illegal.
          </p>

          <h3 className="text-lg font-bold mt-6 mb-2">Privacy</h3>
          <p>
            The website or app will collect and use user data in accordance with
            its privacy policy.
          </p>

          <h3 className="text-lg font-bold mt-6 mb-2">Intellectual Property</h3>
          <p>
            User must respect the intellectual property rights of the website or
            app, including copyrights, trademarks, and patents.
          </p>

          <h3 className="text-lg font-bold mt-6 mb-2">Liability</h3>
          <p>
            The website or app is not liable for any damages or losses resulting
            from the use of the platform.
          </p>

          <h3 className="text-lg font-bold mt-6 mb-2">Termination</h3>
          <p>
            The website or app reserves the right to terminate user accounts for
            violations of terms of use.
          </p>

          <h3 className="text-lg font-bold mt-6 mb-2">Governing Law</h3>
          <p>
            The terms of use are governed by the laws of the jurisdiction in
            which the website or app is based.
          </p>
        </div>
      </div>

      <div className="w-full h-[20rem] bg-white">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default TermsOfUse;
