import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import * as colors from "./../../constants/colors/colors";
import SearchAppBar from "../navigation/NavigationBar";
import DrawerNavigation from "../navigation/DrawerNavigation";
import { GlobalDataProps } from "../../types/store/global";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvent } from "../../services/redux/event";
import { fetchCategory } from "../../services/redux/category";
import { fetchOrganizer } from "../../services/redux/Organizer";
import { fetchAllEEventOrganizers } from "../../services/redux/eventOrganizers";
import { CategoryDataPropsTypes } from "../../types/store/category";
import UserAlert from "../Indicators/UserAlert";
import Cookies from "js-cookie";
import {
  fetchAllOrganizationEvent,
  fetchAllOrganizations,
} from "../../services/redux/admin";
import { getUserDetails } from "../../services/redux/user/userSlice";
import jwt_decode from "jwt-decode";

interface Props {
  children: any;
  window?: () => Window;
}

export default function MainLayout(props: Props) {
  const dispatch = useDispatch();

  const verificationStatus = Cookies.get("verificationStatus");
  React.useEffect(() => {
    const getEventAppLoginUserAccessToken = Cookies.get(
      "event_app_logged_in_user_access_token"
    );
    let decodedUser: any = jwt_decode(getEventAppLoginUserAccessToken ?? "");

    dispatch(getUserDetails(decodedUser));
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(fetchEvent());
    dispatch(fetchCategory());
    dispatch(fetchOrganizer());
    dispatch(fetchAllEEventOrganizers());
    dispatch(fetchAllOrganizationEvent());
    dispatch(fetchAllOrganizations());
  }, [dispatch]);

  const { openDrawer } = useSelector(
    (state: GlobalDataProps) => state.appGlobal
  );

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <CssBaseline />
      <AppBar
        className="app-bar"
        component="nav"
        sx={{ backgroundColor: colors.white, boxShadow: "none" }}
      >
        <SearchAppBar />
      </AppBar>
      <Box component="nav"></Box>
      <Box component="main" className="bg-Dark_white w-full">
        <Toolbar />
        <div className="flex w-full">
          <div className="z-10">
            <DrawerNavigation />
          </div>
          <div
            className={`${
              openDrawer ? "w-[80%]" : "w-[90%]"
            } items-center m-auto  z-0 max-w-[1200px]`}
          >
            {verificationStatus === "unverified" && <UserAlert />}
            {props.children}
          </div>
        </div>
      </Box>
    </Box>
  );
}
