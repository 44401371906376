import axios, { AxiosInstance } from "axios";
import { _config } from "../public/config";
import { getAuthToken, getFreshToken, setAuthToken } from "./auth";

const apiConfig = {
  baseURL: _config.api,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
const Api: AxiosInstance = axios.create(apiConfig);

//added profile data fetch token interceptor here
Api.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/auth/token" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const { data } = await Api.post("/auth/token/refresh/", {
            refresh: getFreshToken(),
          });
          setAuthToken(data?.access);
          return Api(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default Api;