import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
interface CardActionProps {
  onClickView?: () => void;
  onClickActivate?: () => void;
  onClickRemove?: () => void;
  onClickDeActivate?: () => void;
  Remove?: string;
  View?: string;
  Activate?: string;

}

const ITEM_HEIGHT = 48;

const VerifiedOrganizationCard = ({
  onClickView,
  onClickActivate,
  onClickRemove,
  onClickDeActivate,

  View,
  Activate,
  Remove,
}: CardActionProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return(
    <div>
    <IconButton
      aria-label="more"
      id="long-button"
      aria-controls={open ? "long-menu" : undefined}
      aria-expanded={open ? "true" : undefined}
      aria-haspopup="true"
      onClick={handleClick}
    >
      <MoreVertIcon />
    </IconButton>
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      disableEnforceFocus
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: ITEM_HEIGHT * 4.5,
          padding: 5,
        },
      }}
    >
      <MenuItem onClick={onClickView}>{View}</MenuItem>
      <MenuItem onClick={onClickActivate}>{Activate}</MenuItem>
      <MenuItem onClick={onClickRemove}>{Remove}</MenuItem>

    </Menu>
  </div>
  );
};

export default VerifiedOrganizationCard;
