// import {Rectangle} from ''
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import LandingPageEvents from "../../components/landingpage/LandingPageEvents";
import LandingPageFAQ from "../../components/landingpage/LandingPageFAQ";
import LandingPageMainFeatures from "../../components/landingpage/LandingPageMainFeatures";
import LandingPageFooter from "../../components/landingpage/LandingPageFooter";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useRef } from "react";
import * as route from '../../constants/routes/routes'
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { fetchLandingPageEvent } from "../../services/redux/event/index";

import { useNavigate } from "react-router-dom";
const LandingPage = () => {
  const scroll = useRef(null);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const scrollToSection = (element: any) => {
    window.scrollTo({
      top: element.current.offsetTop,
      behavior: "smooth",
    });
  };
  const {landingPageEvent: eventData} = useSelector(
    (state: any) => state.event
  );
   useEffect(()=>{
    dispatch(fetchLandingPageEvent())
  },[])
  return (
    <div className="w-full cursor-pointer bg-white ">
      <div className="w-full  bg-white Background p-4 h-[47rem] border-b ">
        <div className="w-full  h-[10%] px-6 flex ">
          <div className="w-[20%]  h-full ">
            <img src="/Frame 984 (1).png" alt="" className="ml-[23px]" />
          </div>
          <div className="w-[60%] h-full  "></div>
          <div className="w-[20%] flex justify-around h-full ">
            {/* <p className="text-[15px] mt-2 font-family text-[#FFFFFF]">
              Help
              <KeyboardArrowDownIcon />
            </p> */}
            <div
              className="w-[30%] border border-white text-white h-[45%] font-family rounded-md flex justify-center items-center"
              onClick={() => {
                navigate(route.signIn);
              }}>
              Login
            </div>
            <div
              className="w-[30%] bg-blue text-white h-[45%] font-family rounded-md flex justify-center items-center"
              onClick={() => {
                navigate(route.signUp);
              }}>
              Register
            </div>
          </div>
        </div>
        <div className="w-[90%] h-[90%] p-5 flex ml-6 items-end ">
          <div className="w-[60%] h-[80%]">
            <p className="text-[48px] text-white leading-[3rem] font-family h-[34%] w-[80%]">
              Discover, Connect, and Experience Local Events Like Never Before!
            </p>
            <p className=" text-white h-[46%] mt-[40px] font-family">
              {" "}
              Looking for a convenient and user-friendly way to discover
              exciting events in your area? Look no further than our event app!
              With easy event registration, a customizable calendar, and
              location services to guide you, this app offers a seamless event
              experience. Connect with like- minded individuals and make lasting
              memories at events you won't want to miss. Download now to start
              exploring your city like never before!
            </p>
            <p
              className=" w-[27%] font-family text-[ #28293D] bg-white h-[2.4rem] flex justify-center items-center rounded-md"
              onClick={() => {
                navigate(route.signUp);
              }}>
              Create your next event
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-[17rem] px-32 pt-10 bg-white">
        <LandingPageEvents eventData={eventData} />
      </div>
      <div className="w-full h-[60rem] px-32 pt-10 bg-white">
        <div className="w-full h-full">
          <LandingPageMainFeatures />
        </div>
      </div>
      <div className="w-full h-[37rem] px-32 bg-white">
        <LandingPageFAQ />
      </div>
      <div className="w-full h-[20rem] bg-white">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default LandingPage;
