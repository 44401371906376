import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface CardActionProps {
  onClickView?: () => void;
  onClickActivate?: () => void;
  onClickRemove?: () => void;
  onClickDeActivate?: () => void;
  Remove?: string;
  View?: string;
  Activate?: string;
  DeActivate?:string;
}

const ITEM_HEIGHT = 48;

export default function CardAction({
  onClickView,
  onClickActivate,
  onClickRemove,
  onClickDeActivate,
  DeActivate,
  View,
  Activate,
  Remove,
}: CardActionProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        disableEnforceFocus
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
         
          },
        }}
      >
        <MenuItem onClick={onClickView}>{View}</MenuItem>
        <MenuItem onClick={onClickActivate}>{Activate}</MenuItem>
        <MenuItem onClick={onClickRemove}>{Remove}</MenuItem>
        {/* <MenuItem onClick={onClickDeActivate}>{DeActivate}</MenuItem> */}
      </Menu>
    </div>
  );
}
