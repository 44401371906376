import MainLayout from "../../components/Layout/MainLayout";
import NoDataImage from "../../components/Layout/content/NoData";
import { CategoryDataPropsTypes } from "../../types/store/category";
import { OrganizerCardProps } from "../../types/components/organizerCard";
import MembersCard from "../../components/card/MemberCard";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getEventValue } from "../../services/redux/event/eventSlice";
import { fetchOrganizer } from "../../services/redux/Organizer/index";
import { deleteOrganizer } from "../../services/redux/Organizer/index";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import AlertDialog from "../../components/notification/Dialog";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { fetchAllEEventOrganizers } from "../../services/redux/eventOrganizers/index";
import { getItemEvent } from "../../services/redux/eventOrganizers/eventOrganizerSlice";
import EditMembersProfile from "../../components/organization/OrganizersForm/EditMemberProfile";
import { deleteOrganizerItem } from "../../services/redux/eventOrganizers/index";
import Search from "../../components/search/search";
import EditOrganizersProfile from "../../components/organization/OrganizationList";
import { useFormik } from "formik";
import { searchOrganizerAdmin } from "../../services/redux/admin/index";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3E7BFA",
      color: "white",
    },
  },
});


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
};
function OrganizationList() {
  const navigation = useNavigate();
  const openDialoge = useSelector((state: any) => state.organizer.openDialoge);
  const { allOrganizersData } = useSelector(
    (state: CategoryDataPropsTypes) => state.eventOrganizers
  );

  const { adminSearch } = useSelector((state: any) => state.appGlobal);
  const classes = useStyles();

   const [currentPage, setCurrentPage] = useState(1);
   const [itemsPerPage, setItemsPerPage] = useState(12); // set the number of items you want per page

   // The function to handle page change
   const handlePageChange = (event: any, value: any) => {
     setCurrentPage(value);
   };

   // Calculate the index of the first and last items on the current page
   const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;

   // Get the current items to display on the current page

   const currentItems = allOrganizersData
     ? allOrganizersData.slice(indexOfFirstItem, indexOfLastItem)
     : [];

 
  const { id } = useSelector((state: any) => state.eventOrganizers);
  const { itemId } = useSelector((state: any) => state.appGlobal);
  const { editedItem } = useSelector(
    (state: CategoryDataPropsTypes) => state.appGlobal
  );
  const { value } = useSelector((state: any) => state.event);


  const totalItems = allOrganizersData ? allOrganizersData.length : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const openModal = useSelector((state: any) => state.organizer.openModal);
  const ShowEditform = useSelector(
    (state: any) => state.organizer.ShowEditform
  );

  const dispatch = useDispatch();
  const [showAction, setShowAction] = useState(false);
  const navigate = useNavigate();
  const [OrganizersList1, setOrganizersList1]: any = useState({
    activeObject: null,
    object: allOrganizersData,
  });

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("loaded");
  const { enqueueSnackbar } = useSnackbar();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    
    dispatch(OrganizersAction.handleDialogeClose());
    setStatus("loaded");
  };
  const onhandleOpen = () => {
    dispatch(OrganizersAction.handleOpen());
  };

  const HandleClose = () => {
    dispatch(OrganizersAction.handleClose());
    fetchAllEEventOrganizers();
    window.location.reload();
  };
  useEffect(() => {
    dispatch(fetchAllEEventOrganizers());
  }, [dispatch]);
  const toggler = (index: number, id: any) => {
    setOrganizersList1({
      ...OrganizersList1,
      activeObject: OrganizersList1.object[index],
    });
    setShowAction(true);
  };
  useEffect(() => {
    dispatch(fetchAllEEventOrganizers());
    dispatch(deleteOrganizer());
    dispatch(searchOrganizerAdmin(value));
  }, []);

  const toggleClass = (index: number) => {
    if (OrganizersList1.object[index] === OrganizersList1.activeObject) {
      return "bg-white py-[20px] flex flex-col justify-evenly items-center w-[28%] top-[40px] rounded-lg right-[50px] shadow-2xl absolute h-[58%] active";
    } else {
      return "inActive";
    }
  };

  const toggleMemberClass = (index: number) => {
    if (OrganizersList1.object[index] === OrganizersList1.activeObject) {
      return "bg-white py-[10px] flex flex-col justify-evenly items-center w-[28%] top-[40px] rounded-lg right-[50px] shadow-2xl absolute h-[98%] active";
    } else {
      return "inActive";
    }
  };

  const handleSubmitAction = async () => {
    dispatch(deleteOrganizerItem(totalItems?._id)).then(() => {
      dispatch(fetchAllEEventOrganizers());
      if (status) {
        handleClose();
        setTimeout(() => {
          dispatch(fetchAllEEventOrganizers());
          window.location.reload();
          enqueueSnackbar(`${totalItems?.name} deleted successfully`, {
            variant: "error",
          });
          
        }, 2000);
      }
    });
  };
  const EditOrganizerHandler = (id: any) => {
    dispatch(OrganizersAction.handEditForm());
    dispatch(getItemEvent(id));
  };
  const DeleteOrganizerHandler = (id: any) => {
    handleClickOpen();
    dispatch(OrganizersAction.EditOrganizer(id));
  };
  const Title = {
    Image: "Image",
    Name: "Name",
    Email: "Email",
    Organization: "Organization",
  };

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    onSubmit: async (values) => {
      dispatch(searchOrganizerAdmin(values.title));
    },
  });
  dispatch(getEventValue(formik.values.title));

  const OrganizerData = formik.values.title === '' ? OrganizersList1?.object : adminSearch.organizers;

  return (
    <MainLayout>
      <AlertDialog
        open={openDialoge}
        status={status}
        handleClickOpen={() => {
          dispatch(OrganizersAction.handleOpen());
        }}
        handleClose={() => {
          dispatch(OrganizersAction.handleDialogeClose());
        }}
        handleSubmitAction={() => {
          handleSubmitAction();
        }}
        title="Confirm Delete"
        subTitle="Are you sure you want to delete this organizer?"
      />
      <Modal
        open={openModal}
        onClose={HandleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style, width: 500, height: 550 }}>
          {ShowEditform && <EditOrganizersProfile />}
        </Box>
      </Modal>
      <div className="w-fuu h-[92vh] parent ">
        <div className="w-full  mt-4 h-[4rem]">
          <Search
            handleSubmit={formik.handleSubmit}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(searchOrganizerAdmin(e.target.value));
              formik.setFieldValue("title", e.target?.value);
            }}
            values={formik.values.title}
          />
        </div>
        {OrganizersList1?.object.length === 0 ||
        adminSearch.organizers?.length === 0 ? (
          <NoDataImage message="No Organizers Available" />
        ) : (
          <div className="w-[100%] h-[85vh]  pt-4 parent ">
            <div className="w-full h-[55px] text-[#FFFFFF] bg-[#3E7BFA] flex  ">
              <div className="w-[10%] h-full flex pl-3 items-center">
                {" "}
                {Title.Image}
              </div>
              <div className=" w-[30%] h-full  flex pl-3 items-center">
                {Title.Name}
              </div>
              <div className=" w-[30%] h-full  flex pl-3 items-center">
                {Title.Email}
              </div>
              <div className=" w-[28%] h-full  flex pl-3 items-center">
                {Title.Organization}
              </div>
            </div>

            <div className="w-full parent flex-wrap  overflow-y-scroll h-[88%]">
              {currentItems?.map((item: OrganizerCardProps, index: number) => {
                return (
                  <MembersCard
                    handleSubmitAction={() => {
                      handleSubmitAction();
                    }}
                    ViewAction={() => {
                      setShowAction(false);
                    }}
                    id={item._id}
                    open={open}
                    handleClose={handleClose}
                    handleClickOpen={handleClickOpen}
                    status={status}
                    index={index}
                    image={item.image}
                    name={item.name}
                    email={item.email}
                    Organization={item.organization?.name}
                    togglerfunction={() => {
                      toggler(index, item._id);
                    }}
                    showAction={showAction}
                    Navigatefunction={() => {
                      navigate(`/organization-detail/${item._id}`);
                    }}
                    EditOrganizerfunction={() => {
                      EditOrganizerHandler(item._id);
                    }}
                    DeleteOrganizer={() => {
                      DeleteOrganizerHandler(item._id);
                    }}
                    toggleClass={toggleMemberClass(index)}
                    eventItem={item}
                  />
                );
              })}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className={classes.root}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                shape="rounded"
                color="primary"
              />
            </div>
            </div>
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default OrganizationList;
