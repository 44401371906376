import React from "react";
import * as routes from "./../../constants/routes/routes";
import * as auth from "../../services/auth";
import { RxDashboard } from "react-icons/rx";
import { HiCalendarDays, HiOutlineCog8Tooth } from "react-icons/hi2";
import { BsBuildingSlash, BsBuildingCheck } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTabId } from "../../services/redux/global/globalSlice";
// import { GlobalDataProps } from "../../types/store/global";
import { GlobalDataProps } from "../../types/store/global";
interface DrawerMenuItems {
  id: number;
  title: string;
  link: string;
  icon: React.ReactElement;
}



const navItems = (): DrawerMenuItems[] => {
  if (auth.getUserRole() === "admin") {
    return [
      {
        id: 1,
        title: "Dashboard",
        link: routes.home,
        icon: <RxDashboard />,
      },
      {
        id: 2,
        title: "Event",
        link: routes.events,
        icon: <HiCalendarDays />,
      },
      {
        id: 3,
        title: "Organizers",
        link: routes.organizers,
        icon: <HiOutlineUserGroup />,
      },
      {
        id: 5,
        title: "Organization",
        link: routes.organization,
        icon: <BsBuildingCheck />,
      },
      {
        id: 6,
        title: "Settings",
        link: routes.addCategory,
        icon: <HiOutlineCog8Tooth />,
      },
    ];
  } else if (auth.getUserRole() === "organizer") {
    return [
      {
        id: 4,
        title: "Event",
        link: routes.events,
        icon: <HiCalendarDays />,
      },
    ];
  } else {
    return [
      {
        id: 4,
        title: "Event",
        link: routes.events,
        icon: <HiCalendarDays />,
      },
      {
        id: 5,
        title: "Organizers",
        link: routes.organization,
        icon: <HiOutlineUserGroup />,
      },
    ];
  }
};


function DrawerNavigation() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { currentTabId: tabId, openDrawer } = useSelector(
    (state: GlobalDataProps) => state.appGlobal
  );

  return (
    <div
      className={`${
        openDrawer ? "md:w-[220px]" : "md:w-[60px]"
      }  bg-white h-[91vh] text-black 
      items-center flex flex-col pt-4`}
    >
      {navItems().map((navItem) => (
        <div
          onClick={() => {
            navigation(navItem.link);
            // dispatch(fetchOrganizer())
            dispatch(getCurrentTabId(navItem?.id));
          }}
          className={`flex items-center text-xl ${
            openDrawer ? "pl-5" : "pl-2"
          }  text-primary ${openDrawer ? "w-[200px]" : "w-[40px]"}  
          h-[48px] ${
            tabId === navItem.id && "bg-searchBarBlue"
          } rounded-lg my-2 text-drawerItemsBlue cursor-pointer`}
          key={navItem.title}
        >
          <span className="mr-4"> {navItem.icon}</span>
          {openDrawer && navItem.title}
        </div>
      ))}
      
    </div>
  );
}

export default DrawerNavigation;
