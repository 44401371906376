import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import * as routes from "../../constants/routes/routes";
import FavoriteIcon from '@mui/icons-material/Favorite'; 

const LandingPageFooter = () => {
  return (
    <div className="w-full  bg-[#3568D4]  h-full">
      <div className="w-full   h-[90%] flex px-[9rem] py-8">
        <div className="w-[33%] ">
          <img src="/Frame 984 (1).png" alt="" />
          <p className="text-[15px] mt-2 text-[#EBEBF0]">
            ABONTEN is an innovative event app designed to help people discover
            and connect with outdoor events in their local communities. The name
            "ABOTEN" is the Ghana word for "outside," and reflects the app's
            focus on outdoor events such as festivals, concerts, and sports
            games.
          </p>
        </div>
        <div className="w-[50%] flex items-end justify-around ">
          <div className="w-[30%] h-[96%] ml-12 ">
            <p className="text-[#EBEBF0] text-[18px] font-semibold">
              Use Abonten
            </p>
            <p className="text-[#EBEBF0] text-[15px] mt-2 ">Mobile app</p>
            <p className="text-[#EBEBF0] text-[15px] mt-2 ">Features</p>
          </div>

          <div className="w-[30%]  h-[96%] ml-12 ">
            <p className="text-[#EBEBF0] text-[18px] font-semibold">Company </p>
            <p className="text-[#EBEBF0] text-[15px] mt-2 ">About us</p>
            <p className="text-[#EBEBF0] text-[15px] mt-2  ">Support</p>
          </div>
        </div>
        <div className="w-[20%] flex items-end  justify-end   ">
          <div className="w-[80%]   h-[96%]">
            <p className="text-[#EBEBF0] t text-[18px] font-semibold">
              Connect with us
            </p>
            <div className="w-[100%] text-[#EBEBF0] flex  justify-around">
              <FacebookIcon sx={{ width: "35px", height: "35px" }} />
              <InstagramIcon sx={{ width: "35px", height: "35px" }} />
              <TwitterIcon sx={{ width: "35px", height: "35px" }} />
              <YouTubeIcon sx={{ width: "35px", height: "35px" }} />
            </div>
          </div>
        </div>
      </div>
      <p className="text-center text-[#EBEBF0] ">
        "Copyright © 2023 Abonten. All rights reserved. | Designed and developed
        with <FavoriteIcon sx={{ color: "red" }} /> by Abonten team | Privacy
        Policy | <a href={routes.terms}>Terms of Use </a> | 
        <a href={routes.contactUs}> Contact Us</a>"
      </p>
    </div>
  );
};

export default LandingPageFooter;
