import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import ActionButton from "../../components/button/ActionButton";
import EventCard from "../../components/card/EventCard";
import MainLayout from "../../components/Layout/MainLayout";
import { EventCardProps } from "../../types/components/eventCard";
import { EVentDataProps } from "../../types/store/event";
import * as colors from "../.././constants/colors/colors";
import * as routes from "../../constants/routes/routes";
import NoDataImage from "./../../components/Layout/content/NoData";
import { CategoryDataPropsTypes } from "../../types/store/category";
import { useDispatch } from "react-redux";
import Modal from "@material-ui/core/Modal";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import EventForm from "../../components/Event/CreateEventModal";
import AlertDialog from "../../components/notification/Dialog";
import { deletingEvent } from "../../services/redux/event/index";
import { EventDetailsType, OrganizersTypes } from "../../types/eventTypes";
import { useSnackbar } from "notistack";
import EditEventForm from "../../components/Event/EditEventForm";
import { fetchEvent } from "../../services/redux/event/index";
import { SearchAllOrganizerEvent } from "../../services/redux/event/index";
import { fetchLandingPageEvent } from "../../services/redux/event/index";
import { fetchAllOrganizationEvent } from "../../services/redux/admin/index";
import { getEventValue } from "../../services/redux/event/eventSlice";
import Search from "../../components/search/search";
import * as auth from "../../services/auth";
import { useFormik } from "formik";
import { searchAdminEvents } from "../../services/redux/event/index";
import CategoryFilter from "../../components/Event/CategoryFilter";
import { Category } from "../../types/categoryTypes";
import Api from "../../services/api";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#3E7BFA",
      color: "white",
    },
  },
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
};

interface CategoriesResponse {
  status: string;
  data: {
    categories: Category[];
  };
}

function Event() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { data: eventData, loaded } = useSelector(
    (state: EVentDataProps) => state.event
  );

  const { searchEvent } = useSelector((state: any) => state.event);
  const { searchOrganizerEvent } = useSelector(
    (state: any) => state.eventOrganizers
  );

  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12); // set the number of items you want per page

  // The function to handle page change
  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the current items to display on the current page

  const currentItems = searchEvent
    ? searchEvent.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const openModal = useSelector((state: any) => state.organizer.openModal);
  const openDialoge = useSelector((state: any) => state.organizer.openDialoge);
  const { userData } = useSelector(
    (state: CategoryDataPropsTypes) => state.user
  );
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("loaded");
  const handleClose = () => {
    setOpen(false);
    setStatus("loaded");
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const HandleClose = () => {
    dispatch(OrganizersAction.handleClose());
    dispatch(fetchAllOrganizationEvent());
  };

  const { itemId } = useSelector((state: any) => state.appGlobal);

  const { allOrganizersEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );
  const { allOrganizationEvent } = useSelector(
    (state: EVentDataProps) => state.adminVerifications
  );

  const ShowEdit = useSelector((state: any) => state.organizer.ShowEditform);
  const Showform = useSelector((state: any) => state.organizer.Showform);
  const { value } = useSelector((state: any) => state.event);
  // console.log("kmmmk", value);
  const [viewedEvent, setViewedEvent] = useState<EventDetailsType>();
  useEffect(() => {
    dispatch(searchAdminEvents(value));
    dispatch(SearchAllOrganizerEvent(value));
    const getEvent =
      auth?.getUserRole() === "admin"
        ? eventData?.find((event: any) => event._id === itemId)
        : allOrganizersEvent?.find((event: any) => event._id === itemId);

    setViewedEvent(getEvent);
    handleClickOpen();
    dispatch(fetchEvent());
    dispatch(fetchLandingPageEvent());
    dispatch(fetchAllOrganizationEvent());
  }, []);
  const handleSubmitAction = async () => {
    dispatch(deletingEvent(itemId)).then(() => {
      if (loaded) {
        dispatch(OrganizersAction.handleDialogeClose());
        enqueueSnackbar(`Event deleted successfully`, {
          variant: "error",
        });
        setTimeout(() => {
          dispatch(fetchEvent());
          dispatch(fetchAllOrganizationEvent());
          window.location.reload();
          navigation(routes.events);
        }, 3000);
      }
    });
  };

  const formik = useFormik({
    initialValues: {
      title: "",
    },

    onSubmit: async (values) => {
      dispatch(fetchEvent(values.title));
    },
  });
  dispatch(getEventValue(formik.values.title));

  const allOrganizationEvent1 =
    formik.values.title === "" ? allOrganizationEvent : searchOrganizerEvent;

  // console.log("orgEv", searchOrganizerEvent);

  const [categories, setCategories] = useState<Category[]>([]);

  const [searchItem, setSearchItem] = useState("");

  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);

  useEffect(() => {
    Api.get<CategoriesResponse>("/categories").then((response) => {
      setCategories(response?.data?.data?.categories);
    });
  }, []);

  useEffect(() => {
    let submittedValues = {
      title: searchItem,
      categoryId: selectedCategories[0]?._id ?? "",
    };
    dispatch(searchAdminEvents(submittedValues));
  }, [searchItem, selectedCategories]);

  return (
    <MainLayout>
      <div className="flex w-fill justify-between mt-4 items-end">
        <div className="flex w-3/4 justify-start">
          <Search
            value={searchItem}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchItem(e.target.value)
            }
          />
          <div className="pl-10"></div>
          <CategoryFilter
            categories={categories}
            setSelectedCategories={setSelectedCategories}
            selectedCategories={selectedCategories}
          />
        </div>
        <ActionButton
          label="Create Event"
          backgroundColor={colors.searchButtonBlue}
          onClick={() => {
            dispatch(OrganizersAction.handleOpen());
          }}
        />
      </div>
      <AlertDialog
        open={openDialoge}
        status={status}
        handleClickOpen={() => {
          dispatch(OrganizersAction.handleOpen());
        }}
        handleClose={() => {
          dispatch(OrganizersAction.handleDialogeClose());
        }}
        handleSubmitAction={() => {
          handleSubmitAction();
        }}
        title="Confirm Delete"
        subTitle="Are you sure you want to delete this Event?"
      />
      <Modal
        open={openModal}
        onClose={HandleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description">
        <Box sx={{ ...style, width: 730, height: 600 }}>
          {Showform && <EventForm />}
          {ShowEdit && <EditEventForm />}
        </Box>
      </Modal>

      {userData?.role === "admin" ? (
        <>
          {currentItems.length === 0 ? (
            <NoDataImage message="No Events Available" />
          ) : (
            <div className="flex h-[75vh]  mt-4 flex-wrap  overflow-y-scroll scrollbar-hide pb-10">
              {currentItems.map((item: EventCardProps, index: string) => {
                return (
                  <EventCard
                    key={index}
                    eventItem={item}
                    date={item.date}
                    totalLikes={item.totalLikes}
                    duration={item.duration}
                    title={item?.title}
                    location={item?.location?.placeName}
                    time={item?.startTime ? item?.startTime : ""}
                    image={item?.image}
                    _id={item?._id}
                    city={item?.location?.city}
                    id={item?._id}
                    ClickOpen={() => {
                      handleClickOpen();
                    }}
                  />
                );
              })}
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className={classes.root}>
              <Pagination
                count={
                  searchEvent ? Math.ceil(searchEvent.length / itemsPerPage) : 0
                }
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                variant="outlined"
                color="primary"
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {currentItems.length === 0 ? (
            <NoDataImage message="No Events Available" />
          ) : (
            <div className="flex h-[75vh] flex-wrap   mt-4 overflow-y-scroll scrollbar-hide pb-10">
              {currentItems.map((item: EventCardProps, _: string) => {
                return (
                  <EventCard
                    eventItem={item}
                    date={item.date}
                    totalLikes={item.totalLikes}
                    duration={item.duration}
                    title={item?.title}
                    location={item?.location?.placeName}
                    time={item?.startTime ? item?.startTime : ""}
                    image={item?.image}
                    _id={item?._id}
                    city={item?.location?.city}
                    id={item?._id}
                    ClickOpen={() => {
                      handleClickOpen();
                    }}
                  />
                );
              })}
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div className={classes.root}>
              <Pagination
                count={
                  searchEvent ? Math.ceil(searchEvent.length / itemsPerPage) : 0
                }
                page={currentPage}
                onChange={handlePageChange}
                shape="rounded"
                variant="outlined"
                color="primary"
              />
            </div>
          </div>
        </>
      )}
    </MainLayout>
  );
}

export default Event;
