import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {  useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { OrganizerCardProps } from "../../types/components/organizerCard";
import UnverifiedOrganizationCard from "../../components/card/UnverifiedOrganizationCard";
import NoDataImage from "../../components/Layout/content/NoData";
import OrganizationTableCard from "../../components/card/OrganizationTableCard";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import { deleteOrganizer } from "../../services/redux/Organizer/index";
import AlertDialog from "../../components/notification/Dialog";
import { getItemId } from "../../services/redux/global/globalSlice";
import { fetchOrganizer } from "../../services/redux/Organizer/index";
import { useSnackbar } from "notistack";
function UnverifiedOrganizationList() {
  const navigation = useNavigate();

  const dispatch = useDispatch();
  const { allUnVerifiedOrganizations } = useSelector(
    (state: any) => state.adminVerifications
  );
  const [status, setStatus] = useState("loaded");
  const openDialoge = useSelector((state: any) => state.organizer.openDialoge);
  const Titles = {
    Image: "Name",
    Name: "Administrator",
    Contact: "Contact",
    role: "Role",
    location: "Location",
    status: "Status",
  };
  const { itemId } = useSelector((state: any) => state.appGlobal);
  const OrganizersItem = allUnVerifiedOrganizations?.find((item: any) => item._id === itemId);


  const { enqueueSnackbar } = useSnackbar();
  const handleSubmitAction = async () => {
    const handleClose = () => {
      dispatch(OrganizersAction.handleDialogeClose());
      setStatus("loaded");
    };
    dispatch(deleteOrganizer(OrganizersItem._id)).then(() => {
      // dispatch(fetchOrganizer());
      if (status) {
        handleClose();
        // enqueueSnackbar(`${OrganizersItem?.name} deleted successfully`);
        setTimeout(() => {
          dispatch(fetchOrganizer());
          window.location.reload();
        }, 1000);
      }
    });
  };
  
  return (
    <>
      <AlertDialog
        open={openDialoge}
        status={status}
        handleClickOpen={() => {
          dispatch(OrganizersAction.handleOpen());
        }}
        handleClose={() => {
          dispatch(OrganizersAction.handleDialogeClose());
        }}
        handleSubmitAction={() => {
          handleSubmitAction();
        }}
        title="Confirm Delete"
        subTitle="Are you sure you want to delete this Organization?"
      />
      {allUnVerifiedOrganizations?.length === 0 ? (
        <NoDataImage message={"No Organization Available"} />
      ) : (
        <div className="w-full h-[76vh]   overflow-y-scroll rounded-sm parent  scrollbar-hide  ">
          <div className="w-full h-[55px] pl-5 text-[#FFFFFF] bg-[#3E7BFA] flex  ">
            <div className="w-[20%] h-full flex pl-3 items-center">
              {" "}
              {Titles.Image}
            </div>
            <div className="w-[20%] h-full flex pl-3 items-center">
              {Titles.Name}
            </div>
            <div className="w-[20%] h-full flex  items-center">
              {Titles.Contact}
            </div>
            <div className="w-[20%] h-full flex items-center">
              {Titles.role}
            </div>
            <div className="w-[20%] h-full flex items-center">
              {Titles.location}
            </div>
            <div className="w-[20%] h-full flex mr-4 items-center">
              {Titles.status}
            </div>
          </div>
          {allUnVerifiedOrganizations?.map(
            (item: OrganizerCardProps, _: string) => {
              return (
                <UnverifiedOrganizationCard
                  organization={item?.name}
                  placeName={item?.location?.placeName}
                  email={item?.email}
                  phone={item?.phone}
                  image={item?.image}
                  location={item.location?.placeName}
                  name={item?.contactName}
                  _id={item?._id}
                  deleteHandler={() => {
                    dispatch(OrganizersAction.handleDialogeOpen());
                    dispatch(getItemId(item?._id));
                  }}
                />
              );
            }
          )}
        </div>
      )}
    </>
  );
}

export default UnverifiedOrganizationList;
