import React from "react";
import NoDataImage from "./../../../assets/images/no-data.svg";

function NoData({ message }: { message: string }) {
  return (
    <div className="flex flex-col items-center justify-center h-[74vh] w-full">
      <img
        src={NoDataImage}
        alt="..."
        className=" rounded-xl mt-4 w-full h-[40vh] align-middle border-none"
      />
      <p className="mt-4">{message}</p>
    </div>
);
}

export default NoData;
