import React, { FC, useState } from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ScreenLoader from "../../components/Indicators/ScreenLoader";
import Api from "../../services/api";
import * as endpoints from "../../services/endpoints";
import * as routes from "../../constants/routes/routes";
import jwt_decode from "jwt-decode";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import login from "../../assets/images/Login.png";
import * as auth from "../../services/auth";
import { LoginSchema } from "../../services/validationSchema";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import logo from "../../assets/images/logo.png";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { getCurrentTabId } from "../../services/redux/global/globalSlice";

const theme = createTheme();

const Login: FC = () => {
  const [value, setValue] = useState("organizer");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);
      try {
        const res = await Api({
          method: "POST",
          url:
            values?.email === "admin@eventsapp.io"
              ? endpoints.loginAdmin
              : endpoints.loginOrgnizationOrganizer,
          headers: {
            "content-type":
              "multipart/form-data; boundary=<calculated when request is sent>",
          },
          data: formData,
        });
        if (res.data) {
          const userToken = res.data?.accessToken;
          let decoded: any = jwt_decode(userToken);
          const userRole = decoded?.user?.role;
          const isActive = decoded?.user?.isActive;
          const verificationStatus = decoded?.user?.verificationStatus;
          auth.setIsActive(isActive);
          auth.setCurrentEventUser(userToken);
          auth.setAuthToken(userToken);
          auth.setEventAppLoginUserAccessToken(userToken);
          auth.setUserRole(userRole);
          auth.setVerificationStatus(verificationStatus);
          auth.setEventAppLoginUser(decoded?.user);
          setLoading(false);
          if (userRole === "admin") {
            dispatch(getCurrentTabId(1));
            window.location.href = routes.home;
          } else if (isActive === false) {
            // auth.loginUser();
            window.location.href = routes.changePassword;
          } else {
            dispatch(getCurrentTabId(4));
            window.location.href = routes.home;
          }
        }
      } catch (error: any) {
        setLoading(false);
        if (
          error?.response?.data?.error?.name === "organization-not-approved"
        ) {
          const errorMessage = error?.response?.data?.error?.message;
          enqueueSnackbar(
            `${errorMessage} Check your email for confirmation from the admin`,
            {
              variant: "error",
            }
          );
        } else {
          const errorMessage =
            error?.response?.data?.message || "Invalid Email or Password";
          enqueueSnackbar(errorMessage, { variant: "error" });
        }
      }
    },
  });

  const [showPassword, setShowPassword] = React.useState(false);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          [theme.breakpoints.down("sm")]: {
            marginBottom: 0,
          },
        }}>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${login})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            [theme.breakpoints.down("sm")]: {
              backgroundImage: "none",
            },
          }}
        />

        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              maxWidth: "360px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              width: "100%",
              [theme.breakpoints.down("sm")]: {
                height: "100vh",
                marginTop: "2rem",
                marginBottom: 0,
              },
            }}>
            <img src={logo} alt="logo" />

            <Typography component="h1" variant="h5" className="text-[#28293D]">
              Welcome back
            </Typography>
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <label htmlFor="email" className="text-primary">
                Email
              </label>
              <TextField
                error={
                  formik.touched.password && formik.errors.email ? true : false
                }
                className="login-input"
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoFocus
                type="email"
                placeholder="Enter your email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                sx={{
                  border: "1px solid #C7C9D9",
                  borderRadius: "8px",
                  color: "white",
                }}
              />
              {formik.touched.password && formik.errors.email && (
                <p style={{ color: "red" }}>{formik.errors.email}</p>
              )}
              <label htmlFor="firstName" className="text-primary">
                Password
              </label>
              <div className="flex w-full">
                <div className="flex absolute w-[360px]">
                  <TextField
                    error={
                      formik.touched.password && formik.errors.password
                        ? true
                        : false
                    }
                    disabled={loading}
                    className="login-input"
                    margin="normal"
                    required
                    fullWidth
                    placeholder="**********"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    onBlur={formik.handleBlur}
                    sx={{
                      border: "1px solid #C7C9D9",
                      borderRadius: "8px",
                      color: "white",
                    }}
                  />
                </div>

                <div className="flex relative top-4 left-[85%]">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((show) => !show)}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </div>
              {formik.touched.password && formik.errors.password && (
                <p style={{ color: "red", marginTop: 20, marginBottom: -20 }}>
                  {formik.errors.password}
                </p>
              )}
              <Grid item xs sx={{ mt: 5 }}>
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              {loading && <ScreenLoader />}
              {!loading && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    textTransform: "none",
                    background: "#3E7BFA ",
                    borderRadius: "8px",
                  }}>
                  Sign in
                </Button>
              )}

              <div className="flex mt-3 mb-5">
                <hr
                  style={{
                    width: "40%",
                    height: "2px",
                    marginLeft: "1rem",
                    margin: "0",
                    color: "#C7C9D9",
                  }}
                />
                <span
                  style={{
                    display: "inline-block",
                    lineHeight: "2px",
                    verticalAlign: "middle",
                    margin: "0 0.5rem",
                  }}>
                  or
                </span>
                <hr
                  style={{
                    width: "40%",
                    height: "2px",
                    color: "#C7C9D9",
                    marginRight: "1rem",
                    margin: "0",
                  }}
                />
              </div>
              <Grid
                container
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                }}>
                <Grid item>
                  <span className="text-grey">Have no account?</span>
                  <span
                    className="ml-2 text-blue cursor-pointer"
                    onClick={() => {
                      window.location.href = routes.signUp;
                    }}>
                    Register
                  </span>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export default Login;
