import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MoonLoader } from "react-spinners";

interface DialogPropsTypes {
  title: string;
  subTitle: string;
  open: boolean;
  handleClickOpen: () => void;
  handleClose: () => void;
  handleSubmitAction: any;
  status?: string;
}

export default function AlertDialog(props: DialogPropsTypes) {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <p className="font-bold">{props.title}</p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.subTitle}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: "flex" }}>
          <button
            onClick={props.handleSubmitAction}
            type="submit"
            className="flex items-center justify-center w-[25%] rounded-lg h-10 text-white hover:bg-primary bg-blue font-bold"
          >
            {props.status === "Loading" ? (
              <MoonLoader color="white" size={25} />
            ) : (
              "Yes"
            )}
          </button>

          <button
            disabled={props.status === "Loading"}
            onClick={props.handleClose}
            className="w-[25%] rounded-lg h-10 border mr-4 border-orange font-bold"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
