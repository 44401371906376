import Api from "../../api";
import * as actions from "./actions";
import * as endpoints from "../../endpoints";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as auth from "../../auth";

export const fetchOrganizer: any = createAsyncThunk(
  actions.GET_ALL_ORGANIZERS,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url:
          auth.getUserRole() === "admin"
            ? endpoints.createGetAllOrganizers
            : endpoints.getAllMember,
      });
    
  
      
      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const deleteOrganizer: any = createAsyncThunk(
  actions.DELETE_ORGANIZERS,
  async (id) => {

    try {
      const response = await Api({
        method: "DELETE",
        url:
          auth.getUserRole() === "admin"
            ? endpoints.deleteOrganizer + `/${id}`
            : endpoints.getAllMember + `/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
      })
    

      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const fetchOrganizer1: any = createAsyncThunk(
  actions.GET_ALL_Members,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url: endpoints.getAllMember,
      });
   
      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);

