import { useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { useSelector } from "react-redux";
import { GlobalDataProps } from "../../types/store/global";
type MonthSelectorProps = {
  onChange: (selectedMonth: number) => void;
};

const MonthSelected = ({ onChange }: MonthSelectorProps) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const { openDrawer } = useSelector(
    (state: GlobalDataProps) => state.appGlobal
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedMonth = parseInt(event.target.value);
    setSelectedMonth(newSelectedMonth);
    onChange(newSelectedMonth);
  };

  const months = Array.from({ length: 12 }, (_, index) => {
    const monthIndex = index;
    return (
      <option
        key={monthIndex}
        value={monthIndex}
        className="border-none focus:outline-none">
        {new Date(currentYear, index).toLocaleDateString("en-US", {
          month: "long",
        })}
      </option>
    );
  });
 
  const left = openDrawer?'lg:left-[54.7rem]':'lg:left-[60rem]'

  return (
    <div className={`${
      openDrawer ? "flex justify-evenly lg:left-[59.5rem] items-center bg-[#E5F0FF] lg:w-[15%] md:w-[30%] w-full p-[10px] px-[16px] rounded-[8px] relative md:left-[30rem]" : 
      "flex justify-evenly lg:left-[59.5rem] items-center bg-[#E5F0FF] lg:w-[15%] md:w-[30%] w-full p-[10px] px-[16px] rounded-[8px] relative md:left-[30rem]"
    } `}
    
    // ""
    >
      <AiOutlineCalendar size={20} className="w-[10%]" />
      <select
        value={selectedMonth}
        onChange={handleChange}
        className="w-[70%] bg-[#E5F0FF] focus:outline-none">
        {months}
      </select>
    </div>
  );
};

export default MonthSelected;
