import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { useSelector } from "react-redux";
import { UserDataProps } from "../../types/store/user";
import MainLayout from "../../components/Layout/MainLayout";
import { MdOutlineArrowBackIosNew } from "react-icons/md";

const Profile = () => {
  const navigate = useNavigate();
  const { userData } = useSelector((state: UserDataProps) => state.user);

  return (
    <MainLayout>
      <div className="w-full h-[92vh] parent">
        <div className="flex items-center w-full mb-4">
          <button
            className="p-2 rounded-full hover:bg-gray-200"
            onClick={() => navigate(-1)}>
            <MdOutlineArrowBackIosNew size={30} />
          </button>
          <h2 className="text-lg font-semibold">Profile</h2>
        </div>
        <div className="flex flex-col justify-center items-center h-72">
          <div className="w-[80%] flex flex-col justify-center items-center h-72">
            <div className="w-[60%]">
              <div className="">
                <div className="flex flex-col justify-center items-center h-72">
                  {userData?.image ? (
                    <Avatar
                      alt="User"
                      src={userData?.image}
                      sx={{ width: 160, height: 160 }}
                    />
                  ) : (
                    <Avatar sx={{ width: 160, height: 160 }}>
                      <h1>
                        {userData && userData.name
                          ? userData.name.charAt(0)
                          : ""}
                      </h1>
                    </Avatar>
                  )}
                  <div className="w-full flex justify-between mt-4">
                    <div className="mb-2">
                      <label
                        htmlFor="name"
                        className="text-lg text-[#555770] font-semibold">
                        Name
                      </label>
                      <p id="name" className="text-lg text-[#8F90A6]">
                        {userData?.name}
                      </p>
                    </div>
                    <div className="">
                      <label
                        htmlFor="email"
                        className="text-lg text-[#555770]  font-semibold">
                        Email
                      </label>
                      <p id="email" className="text-lg text-[#8F90A6]">
                        {userData?.email}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Profile;
