// route for homepage
export const home = "/dashbord";

//route for sign in
export const signIn = "/login";

// route for sign Up
export const signUp = "/sign-up";

// route for change password
export const changePassword = "/change-password";

// route for events
export const events = "/events";

export const addCategory = "/add-category";

// route for creating event
export const createEvent = "/create-event";

// route for viewing event details
export const eventsDetails = "/event/:id";

// route for updating event
export const updateEvent = "update-event/:id";

// route for viewing all organization
export const organization = "/organizations";

// route for viewing all organizers
export const organizers = "/organizers";

// route for viewing all unverifiedOrganizers
export const unverifiedOrganizations = "/unverified-organizers";

// route for adding organizer
export const organizersForm = "/organizers-form";

// route for viewing organization detail
export const organizersDetail = "/organizers-detail/:id";

// route for viewing organizer detail
export const organizationDetail = "/organization-detail/:id";

// route for editing organizer
export const editOrganizersProfile = "/organizers-profile/:id";
//LandingPage
export const landingPage = "/";

//Contact Us
export const contactUs = "/contact";

// User profile
export const profile = "/profile";

//Terms Of Use
export const terms = "/terms-of-use";

export const awaiting = "/awaiting-approval"

// route for forgot password

export const forgotPassword = "/forgot-password";



// route for reset password

export const resetPassword = "/reset-password/:token";
