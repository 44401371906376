export const primary = "#1C4E80";
export const grey = "#7E909A";
export const lightGrey = "#F1F1F1";
export const black = "#111111";
export const appGreen = "#178351";
export const appLightGreen = "#189e60";
export const orange = "#eb4d26";
export const lightOrange = "#EA6A47";
export const transparent = "#1C4E80";
export const white = "#ffffff";
export const white1 = "#F6F6F6";
export const green = "#3E7BFA";
export const lightGreen = "#1be341";
export const searchBarBlue = "#E5F0FF";
export const darkGrey3 = "#C7C9D9";
export const searchButtonBlue = "#3E7BFA";
export const DarkGray1 = "#555770";
export const drawerItemsBlue = "#28293D";
