const GET_ALL_EVENT = "get_all_event";
const GET_ALL_LANDINGPAGE_EVENT = "get_all_landing_event";
const GET_ALL_ORGANIZER_EVENT = "get_all_organizer_event";
const CREATE_EVENT = "create_event";
const UPDATE_EVENT = "update_event";
const DELETE_EVENT = "delete_event";
const SEARCH_EVENT = "search_event";

const SEARCH_ORGANIZER_EVENT = "search_event"

export {
  GET_ALL_EVENT,
  GET_ALL_LANDINGPAGE_EVENT,
  GET_ALL_ORGANIZER_EVENT,
  CREATE_EVENT,
  SEARCH_ORGANIZER_EVENT,
  DELETE_EVENT,
  SEARCH_EVENT,
  UPDATE_EVENT,
};
