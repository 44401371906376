import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as events from "./index";

const eventSlice = createSlice({
  name: "event",
  initialState: {
    data: [],
    organizerEvent: [],
    landingPageEvent: [],
    searchEvent: [],
    searchOrganizerEvent: [],
    loaded: false,
    status: null || "",
    message: null,
    LoginHidden: false,
    error: null,
    value: null,
    successDeleteStatus: 0,
    openDialog: false,
    selectedCategoryIds: [],
  },
  reducers: {
    getAllEvent(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    handleOpenDialoge: (state) => {
      state.openDialog = true;
    },
    getEventValue: (state, action) => {
      state.value = action.payload;
    },
  },
  extraReducers: {
    //TOOLKIT FOR FETCHING EVENT
    [events.fetchEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchEvent.fulfilled]: (state, action) => {
      state.data = action.payload.events;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.fetchEvent.rejected]: (state, action) => {
      state.data = [];
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    //TOOLKIT FOR CREATING EVENT
    [events.fetchEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchEvent.fulfilled]: (state, action) => {
      state.data = action.payload?.events;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.fetchEvent.rejected]: (state, action) => {
      state.data = [];
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    //TOOLKIT FOR DELETING EVENT
    [events.deletingEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.deletingEvent.fulfilled]: (state, action) => {
      // state.successDeleteStatus = action.payload;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.deletingEvent.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    [events.fetchOrganizersEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchOrganizersEvent.fulfilled]: (state, action) => {
      state.organizerEvent = action.payload.events;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.fetchOrganizersEvent.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    [events.fetchLandingPageEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchLandingPageEvent.fulfilled]: (state, action) => {
      state.landingPageEvent = action.payload;

      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.fetchLandingPageEvent.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    [events.searchAdminEvents.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.searchAdminEvents.fulfilled]: (state, action) => {
      state.searchEvent = action.payload.events;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.searchAdminEvents.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    [events.SearchAllOrganizerEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.SearchAllOrganizerEvent.fulfilled]: (state, action) => {
      state.searchEvent = action.payload.events;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.SearchAllOrganizerEvent.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },
  },
});

export const { getAllEvent,handleOpenDialoge,getEventValue } = eventSlice.actions;

export default eventSlice.reducer;
