import LandingPageFooter from "../../components/landingpage/LandingPageFooter";
import { HiChevronDown } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import * as routes from "../../constants/routes/routes";

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full bg-white">
      <div className="flex items-center justify-between p-4 shadow-lg">
        <div
          onClick={() => {
            navigate(routes.landingPage);
          }}>
          <img src="/Frame 984.png" alt="" />
        </div>
        <div className="flex items-center">
          <div className="relative">
            <button className="flex items-center">
              Help <HiChevronDown className="ml-2" />
            </button>
          </div>
          <button
            className="mx-4 py-2 px-4 rounded"
            onClick={() => {
              navigate(routes.signIn);
            }}>
            Login
          </button>
          <button
            className="mx-4 py-2 px-4 rounded"
            onClick={() => {
              navigate(routes.signUp);
            }}>
            Sign up
          </button>
        </div>
      </div>
      <div className="h-[20.5rem] bg-[#E5F0FF]">
        <div className="w-[100%] flex items-center justify-center">
          <div className="max-w-[960px] w-full mx-auto px-4">
            <div className="flex flex-col items-center md:flex-row md:justify-between mt-10">
              <div className="text-center md:text-left">
                <h1 className="text-4xl font-bold mb-4">Contact Us</h1>
                <p className="text-lg mb-8">We are here to hear from you.</p>
              </div>
              <div className="flex justify-center">
                <img
                  src="/cuate.png"
                  alt="example"
                  className="object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="flex flex-col items-center py-12">
        <h2 className="text-2xl font-bold mb-8">Contact Us</h2>
        <form className="w-full max-w-lg">
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block  tracking-wide text-[#555770] text-xs font-bold mb-2"
                htmlFor="full-name">
                Full Name
              </label>
              <input
                className="appearance-none block w-full text-[#555770] border border-[#E6E7EE]  rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="full-name"
                type="text"
                placeholder="John Doe"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block  tracking-wide text-[#555770] text-xs font-bold mb-2"
                htmlFor="email">
                Email Address
              </label>
              <input
                className="appearance-none block w-full  text-[#555770] border border-[#E6E7EE]  rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="email"
                type="email"
                placeholder="johndoe@example.com"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block  tracking-wide text-[#555770] text-xs font-bold mb-2"
                htmlFor="phone">
                Phone Number
              </label>
              <input
                className="appearance-none block w-full  text-[#555770] border border-[#E6E7EE] rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="phone"
                type="tel"
                placeholder="123-456-7890"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block  tracking-wide text-[#555770] text-xs font-bold mb-2"
                htmlFor="message">
                Message
              </label>
              <textarea
                className="appearance-none block w-full  text-[#555770] border border-[#E6E7EE] rounded-lg py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
                id="message"
                placeholder="Enter your message here"></textarea>
            </div>
          </div>
          <div className="flex justify-end">
            <button className="bg-blue-500 bg-[#3568D4] text-white font-bold py-2 px-4 rounded-lg">
              Submit
            </button>
          </div>
        </form>
      </div>

      {/*  */}
      <div className="w-full h-[20rem] bg-white">
        <LandingPageFooter />
      </div>
    </div>
  );
};

export default ContactUs;
