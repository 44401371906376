import { useMutation, useQueryClient } from "@tanstack/react-query";
import Api from "../services/api";
import { Category } from "../types/categoryTypes";


export const useAdCategory = () => {
  const queryClient = useQueryClient();

  const addCategory = async (name: string) => {
    const response = await Api.post("/categories", { name });
    const newCategory = response?.data;

    // Update the categories list in the query cache
    queryClient.setQueryData<Category[]>(["categories"], (oldData) => {
      if (oldData) {
        return [...oldData, newCategory];
      }
      return [newCategory];
    });
  };

  const mutation = useMutation(addCategory);

  return mutation;
};
