import React, { useState, useEffect, FC } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ScreenLoader from "../../components/Indicators/ScreenLoader";
import Api from "../../services/api";
import * as endpoints from "../../services/endpoints";
import { getUserDetails } from "../../services/redux/user/userSlice";
import * as routes from "../../constants/routes/routes";
import jwt_decode from "jwt-decode";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import login from "../../assets/images/Login.png";
import * as auth from "../../services/auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import logo from "../../assets/images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { AlertState } from "../../types/alertTypes";
import {
  getCurrentTabId,
  getLocationDetails,
  getLocationLogLtd,
} from "../../services/redux/global/globalSlice";
import { CategoryDataPropsTypes } from "../../types/store/category";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 3,
  boxShadow: 24,
};


const theme = createTheme();

const SignUp: FC = () => {
  const openModal = useSelector((state: any) => state.organizer.openModal);
  const Showform = useSelector((state: any) => state.organizer.Showform);

  const onHandleOpen = () => {
    dispatch(OrganizersAction.handleOpen());
  };

  const HandleClose = () => {
    dispatch(OrganizersAction.handleClose());
  };

  const navigate = useNavigate();
  const { locationsDetails } = useSelector(
    (state: CategoryDataPropsTypes) => state.appGlobal
  );
  const [locationResult, setLocationResult] = useState<any>(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alert, setAlert] = useState<AlertState>({
    open: false,
    message: "",
    severity: "",
  });

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const [signUpInfo, setSignUpInfo] = useState({
    name: "",
    contact: "",
    email: "",
    phone: "",
    // password: "",
    location: "",
  });

  useEffect(() => {
    geocodeByAddress(locationResult?.label ?? "Takoradi, Ghana")
      .then((results) => {
        dispatch(
          getLocationDetails({
            placeID: results[0]?.place_id,
            placeName: results[0]?.formatted_address,
            city: results[0]?.address_components[0].long_name,
          })
        );
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        dispatch(
          getLocationLogLtd({
            lat,
            lng,
          })
        );
      });
  }, [locationResult]);

  const handleSignUpInfoChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSignUpInfo({ ...signUpInfo, [event.target.name]: event.target.value });
    setOpenSnackbar(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      signUpInfo.name === "" ||
      signUpInfo.email === "" ||
      signUpInfo.contact === "" ||
      signUpInfo.phone === "" ||
      locationsDetails.lat === 0
    ) {
      setOpenSnackbar(true);
      setAlert({
        open: true,
        message: "All fields are required",
        severity: "error",
      });
    } else {
      try {
        setLoading(true);
        const res = await Api.post(endpoints.organizationSignUp, {
          name: signUpInfo.name,
          email: signUpInfo.email,
          contactName: signUpInfo.contact,
          phone: signUpInfo.phone,
          location: locationsDetails,
        });
        if (res.data) {
          //  const userToken = res.data?.accessToken;
          //  console.log("res", res);
          //  let decoded: any = jwt_decode(userToken);
          //  const verificationStatus = decoded?.user?.verificationStatus;
          //  dispatch(getUserDetails(decoded));
          //  auth.setVerificationStatus(verificationStatus);
          //  auth.setCurrentEventUser(userToken);
          //  auth.setAuthToken(userToken);
          //  const userRole = decoded?.user?.role;
          //  auth.setUserRole(userRole);
          setSignUpInfo({
            name: "",
            email: "",
            contact: "",
            phone: "",
            location: "",
          });

          dispatch(getCurrentTabId(4));

          setTimeout(() => {
            onHandleOpen();
          }, 5000);
          setTimeout(() => {
            setOpenSnackbar(true);
            setAlert({
              open: true,
              message:
                "Your account has been successfully created. Check your email for admin approval and login credentials",
              severity: "success",
            });
          }, 2000); // 2 seconds delay

          setTimeout(() => {
            window.location.href = routes.signIn;
          }, 10000); // 3 seconds delay
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="flex items-center justify-center">
        <Modal
          open={openModal}
          onClose={HandleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description">
          <Box sx={style}>
            <div className="bg-white rounded-lg shadow-md p-6 max-w-md">
              <h2 id="child-modal-title" className="text-xl font-bold mb-4">
                Wait for Admin Approval
              </h2>
              <p id="child-modal-description" className="text-gray-600">
                Your registration is pending approval from the admin. You will
                receive an email confirmation once your account has been
                approved. Thank you.
              </p>
            </div>
          </Box>
        </Modal>
      </div>

      <ThemeProvider theme={theme}>
        <Grid
          container
          component="main"
          sx={{
            height: "100vh",
            [theme.breakpoints.down("sm")]: {
              marginBottom: 0,
            },
          }}>
          <CssBaseline />

          <Grid
            item
            xs={12}
            sm={4}
            md={8}
            sx={{
              backgroundImage: `url(${login})`,
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
              [theme.breakpoints.down("sm")]: {
                backgroundImage: "none",
              },
            }}
          />

          <Grid
            item
            xs={12}
            sm={8}
            md={4}
            component={Paper}
            elevation={6}
            square
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Box
              sx={{
                maxWidth: "360px",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0px",
                width: "100%",
                [theme.breakpoints.down("sm")]: {
                  height: "100vh",
                  marginTop: "2rem",
                  marginBottom: 20,
                },
              }}>
              <img src={logo} alt="logo" />

              <Typography
                component="h1"
                variant="h5"
                className="text-[#28293D]">
                Register Organization for free
              </Typography>
              <form className="w-full mt-5" onSubmit={handleSubmit}>
                <label htmlFor="name" className="text-primary mb-3">
                  Organization Name
                </label>
                <TextField
                  className="login-input"
                  disabled={loading}
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  name="name"
                  autoFocus
                  type="text"
                  placeholder="Enter organization name"
                  value={signUpInfo.name}
                  onChange={handleSignUpInfoChange}
                  sx={{
                    border: "1px solid #C7C9D9",
                    borderRadius: "8px",
                    color: "white",
                  }}
                />

                <label htmlFor="contact" className="text-primary">
                  Contact Name
                </label>
                <TextField
                  className="login-input"
                  disabled={loading}
                  margin="normal"
                  required
                  fullWidth
                  id="contact"
                  name="contact"
                  autoFocus
                  type="text"
                  placeholder="Enter your contact name"
                  value={signUpInfo.contact}
                  onChange={handleSignUpInfoChange}
                  sx={{
                    border: "1px solid #C7C9D9",
                    borderRadius: "8px",
                    color: "white",
                  }}
                />
                <label htmlFor="name" className="text-primary">
                  Contact Phone
                </label>
                <TextField
                  sx={{
                    border: "1px solid #C7C9D9",
                    borderRadius: "8px",
                    color: "white",
                  }}
                  className="login-input"
                  disabled={loading}
                  margin="normal"
                  required
                  fullWidth
                  id="phone"
                  name="phone"
                  autoFocus
                  type="tel"
                  placeholder="Enter your contact number"
                  value={signUpInfo.phone}
                  onChange={handleSignUpInfoChange}
                />
                <label htmlFor="email" className="text-primary">
                  Email*
                </label>
                <TextField
                  className="login-input"
                  disabled={loading}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                  value={signUpInfo.email}
                  onChange={handleSignUpInfoChange}
                  sx={{
                    border: "1px solid #C7C9D9",
                    borderRadius: "8px",
                    color: "white",
                  }}
                />

                <div className="mt-4">
                  <label htmlFor="Location" className="text-primary">
                    Location
                  </label>
                  <GooglePlacesAutocomplete
                    apiOptions={{ language: "en", region: "gh" }}
                    selectProps={{
                      locationResult,
                      onChange: setLocationResult,
                    }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country: "gh",
                      },
                    }}
                  />
                </div>

                {loading && <ScreenLoader />}
                {!loading && (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      textTransform: "none",
                      background: "#3E7BFA ",
                      borderRadius: "8px",
                    }}>
                    {" "}
                    Register
                  </Button>
                )}

                <Grid
                  container
                  sx={{
                    mt: 3,
                    display: "flex",
                    justifyContent: "center",
                    justifyItems: "center",
                  }}>
                  <Grid item>
                    <span className="text-grey">Already have an account?</span>
                    <span
                      className="ml-2 text-blue cursor-pointer"
                      onClick={() => {
                        navigate(routes.signIn);
                      }}>
                      Sign in
                    </span>
                  </Grid>
                </Grid>

                <div className="">
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    open={openSnackbar}
                    autoHideDuration={4000}
                    onClose={handleCloseSnackbar}>
                    <Alert
                      severity={alert?.severity ?? ""}
                      action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                            setAlert({
                              open: false,
                              message: "",
                              severity: "",
                            });
                          }}>
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }>
                      {alert.message}
                    </Alert>
                  </Snackbar>
                </div>
              </form>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default SignUp;
