import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as events from "./index";
import {SearchAllOrganizerEvent} from "../event/index";

const eventOrganizerSlice = createSlice({
  name: "eventOrganizers",
  initialState: {
    allOrganizersData: [],
    searchOrganizerEvent:[],
    loaded: false,
    status: null || "",
    message: null,
    LoginHidden: false,
    error: null,
    successDeleteStatus: 0,
    id: null,
  },
  reducers: {
    getAllEvent(state, action: PayloadAction<any>) {
      state.allOrganizersData = action.payload;
    },
    getItemEvent(state, action: PayloadAction<any>) {
      state.id = action.payload;
    },
  },
  extraReducers: {
    //TOOLKIT FOR FETCHING EVENT ORGANIZERS
    [events.fetchAllEEventOrganizers.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.fetchAllEEventOrganizers.fulfilled]: (state, { payload }) => {
   
      
      let loadedData: any = [];
      for (const key in payload) {
        
        loadedData.push({
          _id: payload[key]._id,
          email: payload[key].email,
          image: payload[key].image,
          organization: payload[key].organization,
          name: payload[key].name,
          role: payload[key].role,
          createdEvents: payload[key].createdEvents,
        });
      }
      state.allOrganizersData = loadedData;

      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.fetchAllEEventOrganizers.rejected]: (state, action) => {
      state.allOrganizersData = [];
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },

    [SearchAllOrganizerEvent.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [SearchAllOrganizerEvent.fulfilled]: (state, action) => {
      state.searchOrganizerEvent = action.payload;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [SearchAllOrganizerEvent.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },
  },
});

export const { getAllEvent, getItemEvent } = eventOrganizerSlice.actions;

export default eventOrganizerSlice.reducer;
