import { SnackbarProvider } from "notistack";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import store from "./services/redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";
let persistor = persistStore(store);

const queryClient = new QueryClient();
const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <GoogleOAuthProvider clientId="">
    <React.StrictMode>
      <SnackbarProvider maxSnack={1} autoHideDuration={2000}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </SnackbarProvider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

reportWebVitals();
