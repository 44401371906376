import React, { FC, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ScreenLoader from "../../components/Indicators/ScreenLoader";
import * as routes from "../../constants/routes/routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import login from "../../assets/images/Login.png";
import logo from "../../assets/images/logo.png";
import { useSnackbar } from "notistack";
import { useForgotPassword } from "../../hooks/useForgotPassword";

const theme = createTheme();

const ForgotPassword: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const mutation = useForgotPassword();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = event.currentTarget.email.value;

    setLoading(true);

    mutation.mutate(
      { email },
      {
        onSuccess: () => {
          setLoading(false);
          enqueueSnackbar(
            "An email has been sent with instructions to reset your password.",
            { variant: "success" }
          );
        },
        onError: (error) => {
          setLoading(false);
          enqueueSnackbar(` An error occured Password not reset`, {
            variant: "error",
          });
        },
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          [theme.breakpoints.down("sm")]: {
            marginBottom: 0,
          },
        }}>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${login})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            [theme.breakpoints.down("sm")]: {
              backgroundImage: "none",
            },
          }}
        />

        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              maxWidth: "360px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              width: "100%",
              [theme.breakpoints.down("sm")]: {
                height: "100vh",
                marginTop: "2rem",
                marginBottom: 0,
              },
            }}>
            <img src={logo} alt="logo" />

            <Typography component="h1" variant="h5" className="text-[#28293D]">
              Forgot Password
            </Typography>
            <form className="w-full" onSubmit={handleSubmit}>
              <label htmlFor="email" className="text-primary">
                Email
              </label>
              <TextField
                className="login-input"
                disabled={loading}
                margin="normal"
                required
                fullWidth
                id="email"
                name="email"
                autoFocus
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  border: "1px solid #C7C9D9",
                  borderRadius: "8px",
                  color: "white",
                }}
              />

              {loading && <ScreenLoader />}
              {!loading && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    textTransform: "none",
                    background: "#3E7BFA ",
                    borderRadius: "8px",
                  }}>
                  
                  Send Reset Link
                </Button>
              )}

              <Grid
                container
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                }}>
                <Grid item>
                  <span
                    className="ml-2 text-blue cursor-pointer"
                    onClick={() => {
                      window.location.href = routes.organizersForm;
                    }}>
                    Go back to Login
                  </span>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export default ForgotPassword;
