import { createAsyncThunk } from "@reduxjs/toolkit";
import * as auth from "../../../services/auth";

import {
  VERIFY_ORGANIZATION,
  ALL_VERIFY_ORGANIZATION,
  ALL_UN_VERIFY_ORGANIZATION,
  GET_ALL_ORGANIZATION,
  GET_ALL_ORGANIZATION_EVENT,
  GET_ALL_ORGANIZER_EVENT,
  GET_ALL_EVENT_ORGANIZATION,
  SEARCH_ORGANIZERS,
  SEARCH_ADMIN_ORGANIZTION

} from "./actions";
import {
  verifyOrganization,
  unVerifyOrganization,
  verifiedOrganization,
  createGetAllOrganizers,
  fetchAllOrganizationsEvent,
  fetchOrganizerEVent,
  fetchOrganizationEvent,
  searchAdminOrganizers,
  searchAdminOrganization
} from "../../endpoints";
import Api from "../../api";
import { log } from "console";

export const fetchAllOrganizationEvent: any = createAsyncThunk(
  GET_ALL_ORGANIZATION_EVENT,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url:
          auth.getUserRole() === "organization-admin"
            ? fetchOrganizationEvent
            : fetchOrganizerEVent,
      });
      return response?.data?.data?.events;
    } catch (error) {
      console.dir(error);
    }
  }
);
export const fetchAllOrganizations: any = createAsyncThunk(
  GET_ALL_ORGANIZATION,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url: createGetAllOrganizers,
      });
      return response?.data?.data?.organizations;
    } catch (error) {
      console.dir(error);
    }
  }
);
export const fetchUnverifiedOrganizations: any = createAsyncThunk(
  ALL_UN_VERIFY_ORGANIZATION,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url: unVerifyOrganization,
      });
      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const fetchVerifiedOrganizations: any = createAsyncThunk(
  ALL_VERIFY_ORGANIZATION,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url: verifiedOrganization,
      });

      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const verifyingOrganization: any = createAsyncThunk(
  VERIFY_ORGANIZATION,
  async (id) => {
    try {
      const response = await Api({
        method: "PATCH",
        url: verifyOrganization + "/" + id,
      });
    
      return response?.status;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const fetchAllOrganizerEvent: any = createAsyncThunk(
  GET_ALL_ORGANIZER_EVENT,
  async () => {
    try {
      const response = await Api({
        method: "GET",
        url: fetchOrganizationEvent,
      });

      return response?.data?.data?.events;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const fetchAllAdminOrganizationEvent: any = createAsyncThunk(
  GET_ALL_EVENT_ORGANIZATION,
  async (id) => {
    

    try {
      const response = await Api({
        method: "GET",
        url: `/organizations/${id}/events`,
      });
     
    
      return response?.data?.data?.events;
    } catch (error) {
      console.dir(error);
    }
  }
);

export const searchOrganizerAdmin: any = createAsyncThunk(
  SEARCH_ORGANIZERS,
  async (value) => {

    try {
      const response = await Api({
        method: "GET",
        url:searchAdminOrganizers+`=${value}`,
         
        headers: {
          "Content-Type": "application/json",
        },
      })
      return response?.data?.data;
    } catch (error) {
      console.dir(error);
    }
  }
)

export const searchOrganizationAdmin: any = createAsyncThunk(
  SEARCH_ADMIN_ORGANIZTION,
    async (value) => {
      // console.log('value',value)
      try {
        const response = await Api({
          method: "GET",
          url:auth.getUserRole() === 'admin'?`/organizations?name`+`=${value}`:`/organizations/members?name`+`=${value}`,
          headers: {
            "Content-Type": "application/json",
          },
        })
        return response?.data?.data;
      } catch (error) {
        console.dir(error);
      }
    }
);