
import { useState, useEffect } from "react";
import * as auth from "../../../services/auth";
import OrganizationForm from "./OrganizationForm";
import AddMemberForm from "./AddMemberForm";
import { Link } from "react-router-dom";
const OrganizersForm = () => {
  const [showform, setshowform] = useState(false);
  const [showMemberform, setshowMemberform] = useState(true);
  useEffect(() => {
    if (auth?.getUserRole() === "admin") {
      setshowform(true);
      setshowMemberform(false)
    } else {
      setshowform(false);
      setshowMemberform(true)
    }
  }, []);
 

  return (
  
      <div className="w-full flex p-5 justify-center items-center  md:h-[36rem] ">
          {showform && <OrganizationForm />}

          {showMemberform && <AddMemberForm />}
      </div>

  );
};

export default OrganizersForm;
