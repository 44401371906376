import React, { FC, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ScreenLoader from "../../components/Indicators/ScreenLoader";
import * as routes from "../../constants/routes/routes";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import login from "../../assets/images/Login.png";
import logo from "../../assets/images/logo.png";
import { useSnackbar } from "notistack";
import { useResetPassword } from "../../hooks/useResetPassword";

const theme = createTheme();

const ResetPassword: FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [token, setToken] = useState("");

  const mutation = useResetPassword();
  const location = useLocation();

  useEffect(() => {
    //    const params = new URLSearchParams(location.pathname);
    const token = location.pathname.split("/")[2];
    if (token) {
      setToken(token);
    }
  }, [location.pathname]);
  

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    setLoading(true);
    try {
      if (!token) {
        throw new Error("Token not found");
      }
      if (confirmPassword !== password) {
        enqueueSnackbar("Password do not match", {
          variant: "error",
        });
        setLoading(false);
        return;
      }
      await mutation.mutateAsync({ password, confirmPassword, token });
      enqueueSnackbar("Password successfully reset!", { variant: "success" });
      setLoading(false);
      setPassword("");
      setConfirmPassword("");
      window.location.href = routes.signIn;
    } catch (error) {
      enqueueSnackbar("An Error occured Password reset not successful", {
        variant: "error",
      });
      setLoading(false);
    }
    
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
          [theme.breakpoints.down("sm")]: {
            marginBottom: 0,
          },
        }}>
        <CssBaseline />

        <Grid
          item
          xs={12}
          sm={4}
          md={8}
          sx={{
            backgroundImage: `url(${login})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            [theme.breakpoints.down("sm")]: {
              backgroundImage: "none",
            },
          }}
        />

        <Grid
          item
          xs={12}
          sm={8}
          md={4}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Box
            sx={{
              maxWidth: "360px",
              margin: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "0px",
              width: "100%",
              [theme.breakpoints.down("sm")]: {
                height: "100vh",
                marginTop: "2rem",
                marginBottom: 0,
              },
            }}>
            <img src={logo} alt="logo" />

            <Typography component="h1" variant="h5" className="text-[#28293D]">
              Reset Password
            </Typography>
            <form className="w-full" onSubmit={handleSubmit}>
              <label htmlFor="email" className="text-primary">
                Password
              </label>
              <div className="flex w-full mb-5">
                <div className="flex absolute w-[360px]">
                  <TextField
                    disabled={loading}
                    className="login-input"
                    margin="normal"
                    required
                    fullWidth
                    placeholder="**********"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    sx={{
                      border: "1px solid #C7C9D9",
                      borderRadius: "8px",
                      color: "white",
                    }}
                  />
                </div>

                <div className="flex relative top-4 left-[85%]">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </div>

              <label htmlFor="firstName" className="text-primary">
                Confirm Password
              </label>
              <div className="flex w-full mb-5">
                <div className="flex absolute w-[360px]">
                  <TextField
                    disabled={loading}
                    className="login-input"
                    margin="normal"
                    required
                    fullWidth
                    placeholder="**********"
                    name="confirm_password"
                    type={showConfirmPassword ? "text" : "password"}
                    id="confirm_password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{
                      border: "1px solid #C7C9D9",
                      borderRadius: "8px",
                      color: "white",
                    }}
                  />
                </div>
                <div className="flex relative top-4 left-[85%]">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </div>

              {loading && <ScreenLoader />}
              {!loading && (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, textTransform: "none", background: "#3E7BFA ", borderRadius: "8px"}}
                  >
                  
                  Reset Password
                </Button>
              )}

              <Grid
                container
                sx={{
                  mt: 3,
                  display: "flex",
                  justifyContent: "center",
                  justifyItems: "center",
                }}>
                <Grid item>
                  <span
                    className="ml-2 text-blue cursor-pointer"
                    onClick={() => {
                      window.location.href = routes.organizersForm;
                    }}>
                    Go back to Login
                  </span>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
export default ResetPassword;
