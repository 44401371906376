import MoreVertIcon from "@mui/icons-material/MoreVert";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useState } from "react";
import AlertDialog from "../../components/notification/Dialog";
import CardAction from "../menu/card/CardAction";
import { useNavigate } from "react-router-dom";
import {useDispatch } from "react-redux";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import { getItemEvent } from "../../services/redux/eventOrganizers/eventOrganizerSlice";
import { getItemId } from "../../services/redux/global/globalSlice";
import {fetchOrganizersEvent}  from '../../services/redux/event/index'
import { Link } from "react-router-dom";
import * as auth from "../../services/auth";

interface MembersProp {
  index?: number;
  id?: any;
  image?: string;
  name?: string;
  email?: string;
  role?: string;
  Organization?: string;
  togglerfunction?: () => void;
  Navigatefunction?: () => void;
  EditOrganizerfunction?: () => void;
  toggleClass?: string;
  DeleteOrganizer?: () => void;
  showAction?: boolean;
  status?: string;
  handleClickOpen?: () => void;
  handleClose?: () => void;
  handleSubmitAction?: () => void;
  ViewAction?: () => void;
  eventItem?: any;
  open?: boolean;
}
const MembersCard = (props: MembersProp) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  return (
    <div className="w-full flex h-[55px] mt-[0.125rem] hover:bg-[#E5F0FF] pl-[8px]  bg-[#FFFFFF]">
      <Link
        to={`/organization-detail/${props.id}`}
        onClick={() => {
          dispatch(fetchOrganizersEvent(props.id));
        }}
        className="w-full h-full flex">
        <div className="w-[12%] h-full   flex pl-3 items-center">
          {" "}
          <img
            src={props.image}
            alt=""
            className="w-[2.5rem] h-[2.5rem]  rounded-full object-cover object-top"
          />
        </div>
        <div className="w-[30%] h-full flex text-[15px] pr-[22rem] items-center truncate text-ellipsis">
          {props.name}
        </div>
        <div className="w-[30%] h-full flex text-[15px] pr-[22rem] justify-between items-center truncate text-ellipsis">
          {props.email}
        </div>
        {auth.getUserRole() === "admin" ? (
          <div className="w-[28%] h-full flex  text-[15px] pl-3 justify-between items-center text-ellipsis">
            {props.Organization}
          </div>
        ) : (
          <div className="w-[28%] h-full flex  text-[15px] pl-3 justify-between items-center text-ellipsis">
            {props.role}
          </div>
        )}
      </Link>
      <div className="h-full  mr-2 flex items-center ">
        <CardAction
          View="View"
          Activate="Edit"
          Remove="Remove"
          onClickView={() => {
            navigate(`/organization-detail/${props.id}`);
            dispatch(fetchOrganizersEvent(props.id));
          }}
          onClickActivate={() => {
            dispatch(getItemEvent(props.id));
            dispatch(OrganizersAction.handEditForm());
            dispatch(OrganizersAction.EditOrganizer(props.id));
          }}
          onClickRemove={() => {
            dispatch(getItemId(props.id));
            dispatch(OrganizersAction.handleDialogeOpen());
          }}
        />
      </div>
    </div>
  );
};

export default MembersCard;
