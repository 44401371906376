import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { EVentDataProps } from "../../types/store/event";
import { CategoryDataPropsTypes } from "../../types/store/category";
import { fetchEvent } from "../../services/redux/event/index";
import { fetchCategory } from "../../services/redux/category/index";
import { fetchOrganizer } from "../../services/redux/Organizer/index";
import MainLayout from "../../components/Layout/MainLayout";
import ItemDetails from "../../components/dashboard/ItemDetails";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CategoryIcon from "@mui/icons-material/Category";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import HomeIcon from "@mui/icons-material/Home";
import * as colors from "../.././constants/colors/colors";
import CustomDateInputs from "../../components/date/DateRangePicker";
import { fetchAllEEventOrganizers } from "../../services/redux/eventOrganizers";
import DashBoradCard from "../../components/card/DashbordCard";
import {handleHideOrganizersList} from '../../services/redux/admin/adminVerificationSlice'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ProgressBar from "../progressbar/progressBar";
const OrganizersDashbord = () => {
  const dispatch = useDispatch();
  const { data: eventData } = useSelector(
    (state: EVentDataProps) => state.event
  );
  const { data: categoryData } = useSelector(
    (state: CategoryDataPropsTypes) => state.category
  );
  const { allOrganizersData } = useSelector(
    (state: CategoryDataPropsTypes) => state.eventOrganizers
  );

  const { data: organizationList } = useSelector(
    (state: CategoryDataPropsTypes) => state.organizer
  );

  useEffect(() => {
    dispatch(fetchEvent());
    dispatch(fetchCategory());
    dispatch(fetchOrganizer());
    dispatch(fetchAllEEventOrganizers());
  }, [dispatch]);
  const Title = {
    Image: "Image",
    Name: "Name",
    organization: "Organization",
    city: "City",
    event: "Number of Event",
  };
  return (
    <div>
      <div className="w-full parent p-6 h-[88vh] ">
        <div className="flex w-full  mb-4 items-end">
          <ArrowBackIosIcon onClick={()=>{
            dispatch(handleHideOrganizersList())
          }}
  
          />
          <p className="font-semibold ">Organizers</p>
   
        </div>

        <div className="w-full h-[95%] ">
      
            <div className="w-full h-[68px] flex   text-[#FFFFFF] bg-[#3E7BFA] pl-5">
              <div className="w-[25%]   text-[15px] h-full  flex  items-center">
                {Title.Name}
              </div>
              <div className="w-[25%]  pr-4 h-full  text-[15px]  flex justify-center items-center">
                {Title.organization}
              </div>
              <div className="w-[25%] h-full pr-7  text-[15px]  flex justify-center items-center">
                {Title.city}
              </div>
            
              <div className="w-[25%] h-full pr-12  text-[15px]  flex justify-center items-center">
                {Title.event}
              </div>
            </div>
            <div className="w-full parent h-[88%] ">
              {allOrganizersData?.map((item: any) => {
            
                return (
                  <DashBoradCard
                    image={item.image}
                    name={item.name}
                    organization={item.organization.name}
                    city={item.organization.location.city}
                    Status={"Active"}
                    id={item._id}
                    createdEvents={item.createdEvents}
                  />
                );
              })}
            </div>
          
        </div>
        {/* <button  style={{ backgroundColor: colors.searchButtonBlue }} className="h-11 mt-4 text-white  md:w-[30px] lg:w-[100px] w-[30%] 
               rounded-xl px-4 flex items-center justify-center shadow" >Back</button> */}
      </div>
    </div>
  );
};

export default OrganizersDashbord;
