import { useNavigate } from "react-router-dom";
import { OrganizerCardProps } from "../../types/components/organizerCard";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CardAction from "../menu/card/CardAction";
import { useDispatch } from "react-redux";
import UnverifiedOrganizationCardAction from "../menu/card/OrganizationCard/VerifiedOrganizationCard";
import {
  fetchAllOrganizations,
  verifyingOrganization,
} from "../../services/redux/admin";
// import UnverifiedOrganizationCardAction from "../../components/menu/card/OrganizationCard/UnverifiedOrganizationCard";
const UnverifiedOrganizationCard = (props: OrganizerCardProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activateOrganization = async (id: string | undefined) => {
    dispatch(verifyingOrganization(id)).then(() => {
      dispatch(fetchAllOrganizations());
    });
  };

  return (
    <div className="w-full flex text-[#555770] h-[60px]  pl-[8px]  bg-[#FFFFFF]  mt-[0.125rem]">
      <div className="w-[20%] ml-1 flex items-center h-full">
        <div className="w-[28%] flex items-center h-full">
          <img
            src={props.image}
            alt=""
            className="w-[2.5rem] h-[2.5rem]  bg-light-grey rounded-full object-cover object-top"
          />
        </div>
        <div className="w-[72%] overflow-hidden flex flex-col justify-center pl-1 h-full">
          <p className="text-[15px] truncate">{props.organization}</p>
          <p className="text-[12px] truncate text-grey">{props.email}</p>
        </div>
      </div>
      <div className="w-[20%] text-[15px] h-full flex overflow-hidden text-ellipsis pl-4 items-center ">
        {props.name}
      </div>
      <div className="w-[20%] text-[15px] flex pl-4 items-center h-full">
        {props.phone}
      </div>
      <div className="w-[20%] flex pl-4 items-center h-full text-[15px] ">
        {" "}
        organization
      </div>

      <div className="w-[20%] text-[15px] h-full flex overflow-hidden text-ellipsis pl-4 items-center ">
        {props.location}
      </div>
      <div className="w-[20%] h-full flex pl-4 justify-between items-center  ">
        <p
          className="w-[5.8rem] h-[2rem] flex items-center justify-center font-['Inter']
       rounded-full text-[#555770] font-normal text-[15px] bg-[#FFE5E5] border border-[#FF3B3B]">
          Unverified
        </p>

        <UnverifiedOrganizationCardAction
          Activate="Approve"
          Remove="Remove"
          onClickRemove={props.deleteHandler}
          onClickActivate={() => {
            activateOrganization(props._id);
          }}
        />
      </div>
    </div>

    // <div
    //   // onClick={() => navigate(`/event/${props._id}`)}
    //   className="flex  w-full md:w-full lg:w-full
    //   h-full md:h-[16rem] p-[18px] rounded-xl scale-95 cursor-pointer
    //   bg-white shadow-xl"
    // >
    //   <div
    //     className="flex flex-col justify-between p-3
    //   bg-white w-[100%] rounded-xl"
    //   >
    //     <div className="flex justify-between">
    //       <div className="">
    //         <img
    //           src={props.image}
    //           alt="img"
    //           style={{ width: 40, height: 40, borderRadius: 20 }}
    //         />
    //       </div>
    //       <div className="w-[60%]">
    //         <div className="truncate font-bold mb-2">{props.organization}</div>
    //         <div className="truncate font-medium">{props.name}</div>
    //       </div>
    //       <div>
    //         <UnverifiedOrganizationCardAction
    //           Activate="Activate"
    //           Remove="Reject"
    //           onClickActivate={() => {
    //             activateOrganization(props._id);
    //           }}
    //            onClickRemove={() => {
    //             activateOrganization(props._id);
    //           }}
    //         />
    //       </div>
    //     </div>
    //     <hr className="text-grey" />
    //     <div className="flex justify-between">
    //       <div className=" w-1/2 flex flex-col">
    //         <div className="text-DarkGray5">
    //           <EmailIcon style={{ fontSize: 30 }} />
    //           <span className="font-bold ml-4">Email</span>
    //         </div>
    //         <div className="truncate font-bold mt-3 w-full ">{props.email}</div>
    //       </div>
    //       <div className="ml-6 flex flex-col  w-1/2">
    //         <div className="text-DarkGray5">
    //           <LocalPhoneIcon style={{ fontSize: 30 }} />
    //         </div>
    //         <div className="truncate mt-3 w-full font-bold">{props.phone}</div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default UnverifiedOrganizationCard;
