import { useNavigate } from "react-router-dom";

import IconLabel from "../labels/IconLabel";
import { EventCardProps } from "../../types/components/eventCard";
import moment from "moment";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import CardAction from "../menu/card/CardAction";
const DetailsCard = (props: EventCardProps) => {
  const navigate = useNavigate();
    return ( <div onClick={()=>{  navigate(`/event/${props._id}`);}} className="flex min-w-[18rem]
    h-full  md:h-[288px] lg:h-[285px] rounded-xl shadow-md scale-95 cursor-pointer
    hover:scale-100 ease-in duration-300">
        <div className="bg-green  min-w-[100%]  rounded-xl">
        <div
          style={{ backgroundImage: `url(${props.image})` }}
          className={`bg-cover h-[55vh] md:h-[148px] lg:h-[175px] w-full bg-grey rounded-t-xl`}
        ></div>
        <div className="p-3 flex flex-col">
          <div className="text-DarkGray1">
            {moment(props.date).format("LL")}
          
          </div>
          <div className="font-bold my-2 truncate">{props.title}</div>
          <div className="flex w-full justify-between items-center">
            <div className="truncate">
              <IconLabel
                label={props?.city}
                icon={<IoLocationOutline className="text-searchButtonBlue" />}
              />
            </div>
            <div className="flex items-center text-searchButtonBlue font-medium">
              <MdOutlineFavoriteBorder />
              <span className="ml-2">{props.totalLikes}</span>
            </div>
          </div>
        </div>
      </div>
    </div> );
}
 
export default DetailsCard;