import { useMutation } from "@tanstack/react-query";
import Api from "../services/api";

interface ChangePasswordData {
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export const useChangePassword = () => {
  return useMutation((newPassword: ChangePasswordData) =>
    Api.post("organizers/change-password", newPassword)
  );
};

 
