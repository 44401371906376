import React from "react";
interface CreateActionButtonPropTypes {
  label: string;
  backgroundColor: string;
  onClick: () => void;
  type?:string
  icon?: React.ReactElement;
  width?:string,
  height?:string,
}
function ActionButton({
  label,
  type,
  icon,
  onClick,
  backgroundColor,
}: CreateActionButtonPropTypes) {
  return (
    <button
      onClick={() => {
        onClick();
      }}
      style={{ backgroundColor: backgroundColor }}
      className="h-12 text-white md:w-[200px] lg:w-[200px] w-full  
      rounded-xl px-4 flex items-center justify-center shadow"
    >
     
      <p className="">{label}</p>
    </button>
  );
}

export default ActionButton;
