import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import eventReducer from "./event/eventSlice";
import categoryReducer from "./category/categorySlice";
import userReducer from "./user/userSlice";
import organizerReducer from "./Organizer/organizerSlice";
import globalReducer from "./global/globalSlice";
import adminVerificationReducer from "./admin/adminVerificationSlice";
import eventOrganizersReducer from "./eventOrganizers/eventOrganizerSlice";
import eventOrganizationReducer from "./eventOrganization/eventOrganizationsSlice"
const reducers = combineReducers({
  event: eventReducer,
  eventOrganization:eventOrganizersReducer,
  user: userReducer,
  category: categoryReducer,
  organizer: organizerReducer,
  appGlobal: globalReducer,
  eventOrganizers: eventOrganizersReducer,
  adminVerifications: adminVerificationReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
setupListeners(store.dispatch);
export default store;
