import { Title } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {useState} from 'react'
const LandingPageFAQ = () => {

  const FAQ = [
    {
      Title: "What types of events are on the Abonten App ?",
      Icon: <KeyboardArrowDownIcon />,
      IconUp: <KeyboardArrowUpIcon />,
      description:
        "There is a wide range of events on the Abonten App and these include conferences, trade shows, festivals, sporting events, and corporate meetings",
    },
    {
      Title: "How can I find events in my area using the app ?",
      Icon: <KeyboardArrowDownIcon />,
      IconUp: <KeyboardArrowUpIcon />,
      description:'You can find events in your area by using the search feature, browsing through categories or using the map feature to see events near your current location'
      
    },
    {
      Title: "Does the app provide directions to events ?",
      Icon: <KeyboardArrowDownIcon />,
      IconUp: <KeyboardArrowUpIcon />,
      description:'Yes, the app includes a map feature that provides directions to events. You can get directions to an event from your current location or enter a different address'
    },
    {
      Title: "Can I share events with my friends on social media ?",
      Icon: <KeyboardArrowDownIcon />,
      IconUp: <KeyboardArrowUpIcon />,
      description:'Yes, you can share event with your friends on social media by using the share feature within the app. You can share event details, maps, and directions'
    },
    {
      Title: "Is there a cost for using the app ?",
      Icon: <KeyboardArrowDownIcon />,
      IconUp: <KeyboardArrowUpIcon />,
      description:'The app may be free to download and use'
    },
  ];
const [questions,setquestions]=useState(null)

const toggler = (index:any)=>{
  if(questions === index){
    return setquestions(null)
  }else{
    setquestions(index)
  }

}
const togglerStyle = (index:any)=>{

  if(questions === index){
    return 'contentShow'
  }else{
    return 'content'
  }
}

  return (
    <div className="w-full flex-col flex items-center h-full ">
      <div className="w-[60%] flex flex-col items-center h-[25%] ">
        <p className="text-[36px] font-semibold text-center font-family">FAQ</p>
        <p className=" mt-1 text-[13px] font-family text-[#555770] text-center">
        This section is a compilation of common inquiries and their answers about Abonten.
        This section aims to address any doubts concerns that customers might have 
        </p>
      </div>
      <div className="w-full h-[75%] flex-col flex items-center  ">
        {FAQ.map((item: any,index:any) => {
            let icons = questions === index ?  <KeyboardArrowUpIcon /> :  <KeyboardArrowDownIcon />
          return (
           <>
            <div className="w-[80%] mt-4 flex flex-col  border-b border-b-[#C7C9D9]   ">
              <div className="w-full items-center h-full mb-[5px] py-[10px]  flex justify-between ">
              <p className="font-semibold text-[#28293D] font-family">{item.Title}</p>
              <div className="text-[#555770]" onClick={()=>{toggler(index)}}>{icons}</div>
              </div>
              <div className={togglerStyle(index)}>
                {item.description}
              </div>
            </div>
           
           </>
          );
        })}
      </div>
    </div>
  );
};

export default LandingPageFAQ;
