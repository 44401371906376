import SearchIcon from '@mui/icons-material/Search';

const Search = (props:any) => {
    return (  
     
        <form onSubmit={props.handleSubmit} className=" w-[30%] h-[2.5rem] items-center  flex rounded-lg px-2 bg-[#E5F0FF]">
       <div className="w-[10%] h-full flex items-center justify-center">
       <SearchIcon
       />
       </div>
        <input type="search" 
        className='w-[70%] h-fill outline-none bg-transparent' 
        placeholder='Search'
        id="title"
        name="title"
        onChange={props.handleChange}
        value={props.values}
        />
        {/* <button type='submit' className='w-[20%] flex items-center justify-center h-[80%] text-white bg-blue rounded-md'>Search</button> */}
      </form>

    );
}
 
export default Search;