import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as events from "../admin/index";
import { current } from "@reduxjs/toolkit";
const globalSlice :any= createSlice({
  name: "appGlobal",
  initialState: {
    currentTabId: 0,
    itemId: "",
    openDrawer: false,
    adminSearch: [],
    locationsDetails: {},
    editedItem: {},
    loaded: false,
    status: null || "",
    error: null,
    unVerifiedOrganization: false,
    VerifiedOrganization:true
  },
  reducers: {
    getItemId: (state, action) => {
      state.itemId = action.payload;

      
    },
    getCurrentTabId(state, action: PayloadAction<number>) {
      state.currentTabId = action.payload;
    },
    getEditedItems(state, action: PayloadAction<any>) {
      state.editedItem = action.payload;
    
    },
    getDrawerStatus(state, action: PayloadAction<boolean>) {
      state.openDrawer = action.payload;
    },
    getLocationDetails(state, action: PayloadAction<any>) {
      state.locationsDetails = action.payload;
    },
    getLocationLogLtd(state, action: PayloadAction<any>) {
      let logLat = {
        ...state.locationsDetails,
        lng: action.payload.lng,
        lat: action.payload.lat,
      };

      state.locationsDetails = logLat;
    },
  },
  extraReducers:{
    [events.searchOrganizerAdmin.pending]: (state) => {
      state.status = "Loading";
      state.loaded = false;
    },
    [events.searchOrganizerAdmin.fulfilled]: (state, action) => {
      state.adminSearch = action.payload;
      state.loaded = true;
      state.status = "Loaded successfully";
    },
    [events.searchOrganizerAdmin.rejected]: (state, action) => {
      state.loaded = false;
      state.status = "Failed";
      state.error = action;
    },
  }
});

export const {
  getCurrentTabId,
  getDrawerStatus,
  getLocationDetails,
  getLocationLogLtd,
  getEditedItems,
  getItemId,
} = globalSlice.actions;
export default globalSlice.reducer;
