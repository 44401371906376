import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { RRule, RRuleSet, rrulestr } from "rrule";
import { CreateEventSchema } from "../../services/validationSchema";
import Api from "../../services/api";
import * as endpoints from "../../services/endpoints";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { MoonLoader } from "react-spinners";
import { useSnackbar } from "notistack";
import * as colors from "../../constants/colors/colors";
import * as auth from "../../services/auth";
import { Theme, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { fetchEvent } from "../../services/redux/event/index";
import {
  CategoryTypes,
  CategoryDataPropsTypes,
} from "../../types/store/category";
import { useSelector, useDispatch } from "react-redux";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { OrganizersAction } from "../../services/redux/Organizer/organizerSlice";
import { OrganizerTypes } from "../../types/organizersTypes";
import { FormControl, OutlinedInput } from "@mui/material";
import { fetchCategory } from "../../services/redux/category";
import {
  getLocationDetails,
  getLocationLogLtd,
} from "../../services/redux/global/globalSlice";
import * as routes from "../../constants/routes/routes";
import { useNavigate } from "react-router-dom";
import { fetchAllOrganizationEvent } from "../../services/redux/admin/index";
const ITEM_HEIGHT = 40;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 250,
    },
  },
};


function getStyles(name: string, categoryName: string[], theme: Theme) {
  return {
    fontWeight:
      categoryName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type FrequencyOption = keyof typeof frequencyMapping;

const frequencyMapping = {
  DAILY: RRule.DAILY,
  WEEKLY: RRule.WEEKLY,
  MONTHLY: RRule.MONTHLY,
  YEARLY: RRule.YEARLY,
};


const EventForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector(
    (state: CategoryDataPropsTypes) => state.user
  );

  const OrganizersList = useSelector((state: any) => state.organizer.data);

  const { enqueueSnackbar } = useSnackbar();
  const { data: categoryData } = useSelector(
    (state: CategoryDataPropsTypes) => state.category
  );

  const { allOrganizersData } = useSelector(
    (state: CategoryDataPropsTypes) => state.eventOrganizers
  );
  const { locationsDetails } = useSelector(
    (state: CategoryDataPropsTypes) => state.appGlobal
  );
  const HandleClose = () => {
    dispatch(OrganizersAction.handleClose());
  };
  const [categoryName, setCategoryName] = useState<string[]>([]);
  const [organizerName, setOrganizerCategoryName] = useState<string>("");

  const handleChange = (event: SelectChangeEvent<typeof categoryName>) => {
    const {
      target: { value },
    } = event;
    setCategoryName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOrganizersChange = (
    event: SelectChangeEvent<typeof eventOrganizers>
  ) => {
    const {
      target: { value },
    } = event;
    setOrganizerCategoryName(
      typeof value === "string" ? value.split(",") : value
    );
  };

 const [isRecurringChecked, setIsRecurringChecked] = useState(false);
  const [recurrenceFrequency, setRecurrenceFrequency] = useState<FrequencyOption>('DAILY');
  const [recurrenceInterval, setRecurrenceInterval] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleIsRecurringChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setIsRecurringChecked(event.target.checked);
    toggleModal();
  };

 const handleRecurrenceFrequencyChange = (
   event: React.ChangeEvent<HTMLSelectElement>
 ) => {
   const newFrequency = event.target.value as FrequencyOption;
   setRecurrenceFrequency(newFrequency);
   updateRRule(newFrequency);
 };


  const [eventCategory, setEventCategory] = useState<any>([]);
  const [eventOrganizers, setEventOrganizers] = useState<any>([]);
  const [locationResult, setLocationResult] = useState<any>(null);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [error, setError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [loading, setLoading] = useState(false);

  let selectedOrganizers =
    userData?.role === "organization-admin"
      ? OrganizersList
      : allOrganizersData;

  const [createEventFormData, setCreateEventFormData] = useState({
    title: "",
    description: "",
    category: [],
    date: "",
    startTime: "",
    duration: "",
    organizers: "",
    image: "",
  });

  const theme = useTheme();

  const handleCreateEventFormDataChange = (event: any) => {
    setCreateEventFormData({
      ...createEventFormData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    dispatch(fetchCategory());
    dispatch(fetchEvent());
  }, [dispatch]);
  useEffect(() => {
    if (selectedImage?.size > 1000000) {
      setImageError(true);
    } else {
      setImageError(false);
    }
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    geocodeByAddress(locationResult?.label ?? "Takoradi, Ghana")
      .then((results) => {
        dispatch(
          getLocationDetails({
            placeID: results[0]?.place_id,
            placeName: results[0]?.formatted_address,
            city: results[0]?.address_components[0].long_name,
          })
        );
        return getLatLng(results[0]);
      })
      .then(({ lat, lng }) => {
        dispatch(
          getLocationLogLtd({
            lat,
            lng,
          })
        );
      });
  }, [locationResult]);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      category: [],
      date: "",
      endDate: "",
      startTime: "",
      duration: "",
      organizers: "",
      image: "",
      featured: false,
      isRecurring: false,
      rrule: ""
    },
    validationSchema: CreateEventSchema,

    onSubmit: async (values) => {
      setLoading(true);
      const formData: any = new FormData();
      
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("category", categoryName);
      formData.append("date", values.date);
      formData.append("endDate", values.endDate);
      formData.append("location", JSON.stringify(locationsDetails));
      formData.append("startTime", values.startTime);
      formData.append("duration", values.duration);
      formData.append(
        "organizer",
        userData?.role === "organizer" ? userData._id : organizerName[0]
      );
      formData.append("image", selectedImage);
      formData.append("featured", values.featured);
      formData.append("isRecurring", values.isRecurring);
      formData.append("rrule", values.rrule.toString()); 

      if (isRecurringChecked) {
        const rule = new RRule({
          freq: frequencyMapping[recurrenceFrequency],
          interval: recurrenceInterval,
          dtstart: new Date(values.date),
          until: new Date(values.endDate),
        });

        formik.setFieldValue("rrule", rule.toString());
        // console.log("Generated RRule:", rule.toString());
        
      }    
     

      if (!imageUrl || locationsDetails.lat === 0) {
        setError(true);
      } else if (imageError) {
        setImageError(true);
      } else {
        try {
          setLoading(true);
          const res = await Api({
            method: "POST",
            url: endpoints.fetchCreateUpdateDeleteGetEvent,
            headers: {
              "Content-Type": "multipart/form-data;",
            },
            data: formData,
          });
          await dispatch(fetchEvent());
          if (res.data) {
            setLoading(false);
            enqueueSnackbar("Event Updated successfully", {
              variant: "success",
            });
            HandleClose();
            setTimeout(() => {
              dispatch(fetchEvent());
              dispatch(fetchAllOrganizationEvent());
              window.location.reload();
            }, 2000);
            setCreateEventFormData({
              title: "",
              description: "",
              category: [],
              date: "",
              startTime: "",
              duration: "",
              organizers: "",
              image: "",
            });
            setEventOrganizers([]);
            setEventCategory([]);
            setImageUrl(null);
            setLoading(false);
            enqueueSnackbar("Event created successfully", {
              variant: "success",
            });
            setTimeout(() => {
              navigate(routes.events);
            }, 2000);
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          dispatch(OrganizersAction.handleClose());
        }
      }
    },


    
  });

  const updateRRule = (newFrequency?: FrequencyOption) => {
    const rule = new RRule({
      freq: frequencyMapping[newFrequency || recurrenceFrequency],
      interval: recurrenceInterval,
      dtstart: formik.values.date ? new Date(formik.values.date) : undefined,
      until: formik.values.endDate
        ? new Date(formik.values.endDate)
        : undefined,
    });

    const rruleString = rule.toString();
    // console.log("Generated RRule:", rruleString);
    formik.setFieldValue("rrule", rruleString);
  };
  const today = new Date();
  const currentDate = `${today.getFullYear()}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;



  return (
    <div className="w-full flex justify-center  items-center h-full ">
      <form
        className="w-full  flex flex-col p-6 items-center h-[110%] bg-white rounded-xl"
        onSubmit={formik.handleSubmit}>
        <div className="w-full  h-full ">
          <div className="w-full h-[6%] text-[16px] font-semibold ">
            Create Event
          </div>
          <div className="w-full flex justify-between h-[93%] ">
            <div className="w-[47%]  h-full">
              <div className="my-3 ">
                <div className="flex justify-between">
                  <label htmlFor="Title " className="text-[14px] pl-3 ">
                    Event
                  </label>
                  <p className="text-red text-[14px] text-orange">
                    {formik.errors.title}
                  </p>
                </div>
                <div className="w-full rounded-lg text-[15px] outline-none h-[60%] border-darkGrey3  border  flex">
                  <input
                    autoFocus
                    required
                    placeholder=" Event title"
                    id="title"
                    name="title"
                    autoComplete="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full rounded-lg p-[14.2px]  outline-none bg-transparent h-[100%]"></input>
                </div>
                <div className="w-full md:w-[100%] lg:w-[100%] my-3 md:my-3 lg:my-3  ">
                  <label htmlFor="Category" className="text-[14px] pl-3 ">
                    Category
                  </label>
                  <FormControl
                    sx={{
                      width: "100%",
                      borderRadius: "10px",
                      padding: 0,
                    }}
                    variant="outlined">
                    <Select
                      required
                      className="eventCategory-select"
                      sx={{
                        width: "100%",
                        ".MuiOutlinedInput-notchedOutline": {
                          border: 0,
                          color: "#8F90A6",
                        },
                        "& .MuiInputBase-root": {},
                      }}
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      // multiple
                      value={categoryName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Name" />}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            maxHeight: {
                              xs: 150,
                            },
                            width: 250,
                          },
                        },
                      }}>
                      {categoryData?.map((category: CategoryTypes) => (
                        <MenuItem
                          sx={{ height: 40 }}
                          key={category?._id}
                          value={category?._id}
                          style={getStyles(
                            category?.name,
                            eventCategory,
                            theme
                          )}>
                          {category?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="flex justify-between">
                  <div
                    className={`w-full md:w-[100%] lg:w-[100%] my-2 md:my-4 lg:my-3 ${
                      formik.values.isRecurring
                        ? `w-full md:w-[49%] lg:w-[49%] my-2 md:my-0 lg:my-0`
                        : `w-full md:w-[100%] lg:w-[100%] my-2 md:my-4 lg:my-3 `
                    }`}>
                    <div className="flex justify-between ">
                      <label htmlFor="date" className="text-[14px] pl-3">
                        Date
                      </label>
                      <p className="text-red text-[14px] text-orange">
                        {formik.errors.date}
                      </p>
                    </div>
                    <div className="w-full rounded-lg outline-none border-darkGrey3 border flex">
                      <input
                        type="date"
                        required
                        placeholder="Date"
                        id="date"
                        name="date"
                        autoComplete="date"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        min={currentDate}
                        className="w-full rounded-lg mt-2 p-[13px] outline-none bg-transparent h-[2.45rem]"
                      />
                    </div>
                    <div className="flex items-center mt-2">
                      <input
                        type="checkbox"
                        id="isRecurring"
                        name="isRecurring"
                        checked={formik.values.isRecurring}
                        onChange={(event) => {
                          handleIsRecurringChange(event);
                          formik.handleChange(event); // call the formik handleChange function to update the form values
                        }}
                        onBlur={formik.handleBlur}
                        className="mr-2"
                      />
                      <label htmlFor="isRecurring" className="text-[14px]">
                        Is Recurring
                      </label>
                    </div>
                  </div>

                  {formik.values.isRecurring && (
                    <div className="w-full md:w-[49%] lg:w-[49%] my-2 md:my-0 lg:my-0">
                      <div className="flex justify-between ">
                        <label htmlFor="endDate" className="text-[14px] pl-3">
                          End Date
                        </label>
                        <p className="text-red text-[14px] text-orange">
                          {formik.errors.endDate}
                        </p>
                      </div>

                      <div className="w-full rounded-lg outline-none border-darkGrey3 border flex">
                        <input
                          type="date"
                          required
                          placeholder="Date"
                          id="endDate"
                          name="endDate"
                          autoComplete="endDate"
                          value={formik.values.endDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          min={currentDate}
                          className="w-full rounded-lg mt-2 p-[13px] outline-none bg-transparent h-[2.45rem]"
                        />
                      </div>
                      {isRecurringChecked && isModalOpen && (
                        <div
                          className="fixed z-10 inset-0 overflow-y-auto"
                          aria-labelledby="modal-title"
                          role="dialog"
                          aria-modal="true"
                          onClick={(e) => {
                            if (e.target === e.currentTarget) {
                              setIsModalOpen(true);
                            }
                          }}>
                          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div
                              className="fixed inset-0 bg-[#3636384b] bg-opacity-75 transition-opacity"
                              aria-hidden="true"></div>

                            <span
                              className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true">
                              &#8203;
                            </span>

                            <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <label
                                  htmlFor="recurrenceFrequency"
                                  className="block text-2xl font-medium text-gray-700">
                                  Repeat Events:
                                </label>
                                <select
                                  id="recurrenceFrequency"
                                  value={recurrenceFrequency}
                                  onChange={handleRecurrenceFrequencyChange}
                                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:outline-none focus:border-indigo-500 sm:text-sm rounded-md border-gray-300 shadow-sm">
                                  <option value="" disabled>
                                    Select Frequency
                                  </option>
                                  <option value="DAILY">Daily</option>
                                  <option value="WEEKLY">Weekly</option>
                                  <option value="MONTHLY">Monthly</option>
                                  <option value="YEARLY">Yearly</option>
                                </select>

                                <label
                                  htmlFor="recurrenceInterval"
                                  className="mt-3 block text-sm font-medium text-[#616161]">
                                  Interval
                                </label>
                                <input
                                  type="number"
                                  id="recurrenceInterval"
                                  value={recurrenceInterval}
                                  onChange={(event) => {
                                    setRecurrenceInterval(
                                      parseInt(event.target.value, 10)
                                    );
                                    updateRRule();
                                  }}
                                  className="mt-1 focus:ring-[#E5E7EB] focus:border-[#E5E7EB] block w-full pl-7 pr-12 sm:text-sm border-[#E5E7EB] rounded-md shadow-sm"
                                />

                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                  <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    onClick={() => setIsModalOpen(false)}>
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="w-full md:w-[100%] lg:w-[100%] my-2 md:my-4 lg:my-3 ">
                  <div className="flex justify-between">
                    <label htmlFor="Time" className="text-[14px] pl-3 ">
                      Start Time
                    </label>
                    <p className="text-red text-[14px]  text-orange">
                      {formik.errors.startTime}
                    </p>
                  </div>
                  <div className="w-full rounded-lg text-[14px] outline-none border-darkGrey3 border  ">
                    <input
                      type="time"
                      required
                      placeholder="Start Time"
                      id="startTime"
                      name="startTime"
                      autoComplete="startTime"
                      value={formik.values.startTime}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-full rounded-lg mt-2 p-[13px]   outline-none bg-transparent h-[2.45rem]"></input>
                  </div>
                </div>

                <div>
                  <div className="mt-3 ">
                    <div className=" flex justify-between">
                      <label htmlFor="Time" className="text-[14px] pl-3 ">
                        Event Image
                      </label>
                      {selectedImage === null && (
                        <p
                          style={{ color: "red", margin: 0, fontSize: "14px" }}>
                          Required
                        </p>
                      )}
                    </div>
                    <div>
                      <input
                        accept="image/*"
                        type="file"
                        id="select-image"
                        style={{ display: "none" }}
                        onChange={(e: any) =>
                          setSelectedImage(e.target.files[0])
                        }
                      />
                      <div
                        style={{
                          backgroundImage:
                            imageUrl && selectedImage && `url(${imageUrl})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                        className="flex object-contain items-center justify-center
                       rounded-xl w-full h-[150px] border-darkGrey3 border ">
                        <label htmlFor="select-image">
                          <div
                            className="flex items-center justify-center w-12
                         h-1 rounded-full  scale-95 cursor-pointer
                         hover:scale-100 ease-in duration-300">
                            <CameraAltIcon
                              fontSize="large"
                              className="text-grey"
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  {imageError && (
                    <p style={{ color: "red", margin: 0 }}>
                      Image size can not exceed 1MB
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[47%] h-full ">
              {auth.getUserRole() === "organizer" ? (
                ""
              ) : (
                <div className="w-full md:w-[100%] lg:w-[100%] my-2 md:my-2 lg:my-3 ">
                  <label htmlFor="Category" className="text-[14px] pl-3 ">
                    Organizer
                  </label>
                  <Select
                    disabled={auth.getUserRole() === "organizer"}
                    required
                    className="eventCategory-select"
                    sx={{
                      width: "100%",
                      boxShadow: "none",
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "& .MuiInputBase-root": {},
                    }}
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    onChange={handleOrganizersChange}
                    value={organizerName}
                    MenuProps={MenuProps}>
                    {selectedOrganizers?.map((organizer: OrganizerTypes) => (
                      <MenuItem
                        key={organizer._id}
                        value={
                          userData?.role === "organization-admin"
                            ? organizer.id
                            : organizer._id
                        }
                        style={getStyles(
                          organizer.name,
                          eventOrganizers,
                          theme
                        )}>
                        {organizer?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}

              <div className="w-full md:w-[100%]  lg:w-[100%] my-2 md:my-4 lg:my-0 ">
                <label htmlFor="Location" className="text-[14px] mt-4 pl-3 ">
                  Location
                </label>
                <GooglePlacesAutocomplete
                  apiOptions={{ language: "en", region: "gh" }}
                  selectProps={{
                    locationResult,
                    onChange: setLocationResult,
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: "gh",
                    },
                  }}
                />
              </div>
              <div className="w-full md:w-[100%] lg:w-[100%] my-2 md:my-3 lg:my-3 mt-10 ">
                <div className="flex justify-between">
                  <label htmlFor="Price" className="text-[14px] pl-3 ">
                    Duration/hours
                  </label>
                  <p className="text-red w-[50%] flex text-[14px] justify-end h-full text-orange">
                    {formik.errors.duration}
                  </p>
                </div>
                <div className="w-full rounded-lg outline-none border-darkGrey3 border flex">
                  <input
                    type="number"
                    required
                    placeholder="Duration eg. 7"
                    id="duration"
                    name="duration"
                    autoComplete="duration"
                    value={formik.values.duration}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full rounded-lg p-3 outline-none bg-transparent h-[100%]"></input>
                </div>
              </div>

              <div className="w-full md:w-[100%]  lg:w-[100%]  ">
                <div className="flex justify-between"></div>
                <div className="w-full  flex">
                  <input
                    type="checkbox"
                    id="featured"
                    name="featured"
                    checked={formik.values.featured}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="mr-2 mt"
                  />
                  <label htmlFor="featured" className="text-[14px]">
                    Featured?
                  </label>
                </div>
              </div>

              <div className="my-3">
                <div className="flex justify-between">
                  <label htmlFor="Description" className="text-[14px] pl-3 ">
                    Description
                  </label>
                  <p className="text-red text-[14px] text-orange">
                    {formik.errors.description}
                  </p>
                </div>
                <div className="w-full rounded-lg outline-none border-darkGrey3 border flex">
                  <textarea
                    rows={4}
                    required
                    placeholder="Description"
                    id="description"
                    name="description"
                    autoComplete="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="w-full rounded-lg pr-3 pl-3 outline-none bg-transparent h-[100%] lg:h-[8rem] md:h-[6rem]"></textarea>
                </div>

                <div className="justify-end mt-5 w-full h-14 flex items-end">
                  <button
                    onClick={() => {
                      dispatch(OrganizersAction.handleClose());
                    }}
                    className="w-[25%] rounded-md  h-[55%] border-darkGrey3 border mr-4 bg-[#F2F2F5]">
                    Cancel
                  </button>
                  {loading ? (
                    <MoonLoader color={colors.appLightGreen} size={25} />
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="w-[25%] rounded-md text-white h-[55%] bg-searchButtonBlue">
                        Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EventForm;
